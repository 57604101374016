import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Redirect} from 'react-router-dom'; 
import {  MDBDataTable, Row, Col, Card, CardBody  } from 'mdbreact';
import Axios from 'axios';

class Customerbooking extends Component {
 constructor(props) {
    super(props);
    this.state= {
      posts: [],
      isLoading:true,
      tableRows: [],
    };
  }

  //Fucntion to Display Order of Patient
  componentWillMount=async() => {
    if(sessionStorage.length == 0){
    }else{
    // await Axios.get(url)
     Axios.post('https://react.vitruvio.ca/phpreact/patientorder.php',{
        id:sessionStorage.getItem('userData'),
        accesstoken:sessionStorage.getItem('accesstoken'),
      })
      .then(response => response.data)
      .then(data => {
        console.log(data.data);
        if (data.success == 0) 
        {
          alert(data.data);
          this.logout();
        }
        // alert(data.data);
        // if (err) throw err;
        this.setState({ posts: data.data })
      })
      .then(async() => {
        this.setState({ tableRows:this.assemblePosts(), isLoading:false })
        // console.log(this.state.tableRows);
      });
    }
  }

  //Pass Data to Function ComponentWillMount
  assemblePosts= () => {
    let posts =this.state.posts.map((post) => {
      return (
        {
          bid: post.bid,
          upload: post.upload,
          description: post.description,
          name: post.name,
          address: post.address,
          date: post.date,
          time: post.time,
          reason: post.reason,
          status: post.status,
        }
      )
    });
    return posts;
  }

  //Render Components
  render() {
    //Check if User is Logged in eles redirect out
    if (sessionStorage.getItem('userData') >= 0 && sessionStorage.getItem('usertype') == 1  ){

    }else {
      return (<Redirect to={'/'}/>)
    }
    const data = {
      columns: [
        {
          label:'OrderID',
          field:'bid',
        },
        {
          label: 'Prescription',
          field: 'upload',
        },
        {
          label: 'Description',
          field: 'description',
        }, 
        {
          label: 'Provider Name',
          field: 'name',
        }, 
        {
          label: 'Address',
          field: 'address',
        }, 
        {
          label: 'Date',
          field: 'date',
        }, 
        {
          label: 'Time',
          field: 'time',
        }, 
        {
          label: 'Notes',
          field: 'reason',
        },
        {
          label: 'Status',
          field: 'status',
        },
      ],
      rows:this.state.tableRows,
    }

    return (
      <Card>
        <h1>Customer Orders</h1>
        <div class="row">
          <a href="#" class="btn btn-primary btn-lg" >ALL</a>    
          <a href="#" class="btn btn-primary btn-lg" >PLACED</a>    
          <a href="#" class="btn btn-primary btn-lg" >ACCEPTED</a>    
          <a href="#" class="btn btn-primary btn-lg" >REJECTED</a>
          <a href="#" class="btn btn-primary btn-lg" >DELIVERED</a>
        </div>
        <CardBody>
          <MDBDataTable striped bordered hover data={data} />
        </CardBody>
      </Card>
    )
  }
}
 
export default Customerbooking;