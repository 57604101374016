import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Segment } from "semantic-ui-react";
import { Helmet } from "react-helmet";
import { Button } from "react-bootstrap";
import "font-awesome/css/font-awesome.min.css";
import "@fortawesome/fontawesome-free/css/fontawesome.min.css";
import "../Style.css";

import { Redirect } from "react-router-dom";

const home1 = () => {
  if (
    sessionStorage.getItem("userData") >= 0 &&
    sessionStorage.getItem("usertype") == 1
  ) {
    return <Redirect to={"/PatientsDashboard"} />;
  } else {
  }
 return (
 	 <Segment id="segmentstyle">
      <Helmet>
        <title>Vitruvio - Changing Healthcare in Canada</title>
        <link rel="icon" type="image/png" href="logo.png" sizes="16x16" />
      </Helmet>
      <section class="elementor-element home1">
       <div className="container-fluid container-sm ">
          <div className="headbackimg"></div>
          <div class="row">
            
            <div class="col-xs-6 col-md-6">
              <img
                src={require("../images/bannar-img-2.png")}
                width="auto"
                height="auto"
                className="img-thumbnail homeimg"
                alt="React Bootstrap logo"
                id="img1"
              />
            </div>
            <div
              class="committed col-xs-6 col-md-6">
              <h1>
                COMMITTED TO ACCESSIBLE HEALTH
              </h1>
              <p id="parastyle">
                A new way for patients to choose healthcare providers enabling
                people without benefits to get services for free
              </p>

              <Button className="mr-2" id="b1" href="/learn-more">LEARN MORE ></Button>
            </div>
          </div>
        </div>
      </section>
         <section className="Home-health">
        <div className="container">
          <p id="parastyle2"
          >
            VITRUVIO IS THE SOLUTION TO{" "}
          </p>
          <div class="row">
            <div class="col-xs-6 col-md-6">
              <h1 class="wow fadeInUp" id="h1color">
                THE HEALTHCARE AFFORDABILITY CRISIS.
              </h1>
            
              <Button className="mr-2" id="b4" href="/learn-more">LEARN MORE ></Button>
            </div>
            <div class=" col-xs-6 col-md-6">
              <p id="parastyle3" >
                The fact is that many honest and hard working Canadians don’t
                have medical benefits. Vitruvio allows people with benefits to
                help those without at no cost.
              </p>
            </div>
          </div>
        </div>
      </section>
         <section>
        <div id="container works">
          <h1 id="h1style">
            {" "}
            HOW IT WORKS{" "}
          </h1>
          <p id="parastyle4" >
            We all have a role to play in making our healthcare system work for
            everyone
          </p>
        </div>
      </section>
      <section className="container-fluid">
      <div class="row">
        <div class="col-xs-6 col-sm-6" id="pic1">
          <div className="row">
            <div class="col-sm-1">
              {" "}
              <i
                class="fa fa-angle-double-right fa-3x"
                aria-hidden="true"
            id="iconstyle"
              ></i>
            </div>
            <div class=" col-sm-10">
              {" "}
              <p
                id="text1"
              
              >
                One of the pharmacies in your community commits to accessible
                health by registering with Vitruvio. They have set a goal to
                reach in order to be able to give a prescription away for free.
              </p>
            </div>
          </div>
        </div>
        <div class="col-sm-6 div1">
          <img
            src={require("../images/img3.jpg")}
            className="d-inline-block align-top"
            alt="React Bootstrap logo"
            id="img2"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6 div1">
          <img
            src={require("../images/img2.jpg")}
            id="img2"
            className="d-inline-block align-top"
            alt="React Bootstrap logo"
          />
        </div>
        <div class="col-sm-6" id="pic1">
          <div className="row">
            <div class="col-sm-1">
              {" "}
              <i
                class="fa fa-angle-double-right fa-3x"
                aria-hidden="true"
                  id="iconstyle"
              ></i>
            </div>
            <div class="col-sm-10">
              {" "}
              <p id="text1">
                You and your family choose this pharmacy because you want to
                help make healthcare accessible. You submit your prescription to
                this pharmacy to help them reach their target.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6" id="pic1">
          <div className="row">
            <div class="col-sm-1">
              {" "}
              <i
                class="fa fa-angle-double-right fa-3x"
                aria-hidden="true"
                 id="iconstyle"
              ></i>
            </div>
            <div class="col-sm-10">
              {" "}
              <p id="text1">
                Your neighbour needs to fill a prescription but doesn't have any
                benefits and can't afford it. They see that the pharmacy has
                achieved its target and has a free prescription available for
                them. They submit their prescription and get their medicine for
                free.
              </p>
            </div>
          </div>
        </div>
        <div class="col-sm-6 div1">
          <img
            src={require("../images/img4.jpg")}
            id="img2"
            className="d-inline-block align-top"
            alt="React Bootstrap logo"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6 div1">
          <img
            src={require("../images/img5.jpg")}
            id="img2"
            className="d-inline-block align-top"
            alt="React Bootstrap logo"
          />
        </div>
        <div class="col-sm-6" id="pic1">
          <div className="row">
            <div class="col-sm-1">
              {" "}
              <i
                class="fa fa-angle-double-right fa-3x"
                aria-hidden="true"
                id="iconstyle"
              ></i>
            </div>
            <div class="col-sm-10">
              {" "}
              <p id="text1">
                Your choice to use this pharmacy has supported them and allowed
                them to make their service more accessible to your neighbour who
                can't afford their medicines.
              </p>
            </div>
          </div>
        </div>
      </div>
      </section>
      <section className="container-fluid">
        <div class="row maincard">
        <div class="card col-md-5 carddiv">
          <div class="card-body">
            <h5 class="card-title" id="h5">
             <center> <div className="col-md-4" id="cardstyle">
                 <i class="fa fa-user-md fa-3x"
                aria-hidden="true" id="iconstyle1"></i>
            </div></center>
        
        
              Healthcare Providers
            </h5>
            <hr></hr>
            <p class="card-text" id="ct1">
              We know you care about your patients and do everything you can to
              make your products and services available to those who struggle to
              afford it. Sliding scale payment, free medications, pro bono
              services – we know you do all of it.
            </p>
            <p id="ct1">
              We believe you should be recognized for the efforts you make to
              provide your services to those marginalized people.
            </p>{" "}
            <p id="ct1"> Vitruvio was made for you</p>
            <a href="#" class="btn" id="cb1">
              Sign up here
            </a>
          </div>
        </div>
        <div class="card col-md-5 carddiv1">
          <div class="card-body">
            <h5 class="card-title" id="h5">
             <center> <div className="col-md-4" id="cardstyle">
                 <i class="fa fa-user-plus fa-3x"
                aria-hidden="true" id="iconstyle1"></i>
            </div></center>
              Patients
            </h5>
            <hr></hr>
            <p class="card-text" id="ct1">
              Patients with benefits{" "}
            </p>

            <p id="ct1">
              Did you know you could help someone get their medicine for free
              just by taking yours? If you knew you could help them get access
              to free physiotherapy by using your physiotherapy benefits on
              yourself, would you help?
            </p>
            <p id="ct1">Patients without benefits</p>
            <p id="ct1">
              Are you in a job without benefits, an entrepreneur, a contract
              worker? Do you need help?
            </p>

            <p id="ct1"></p>

            <a href="#" class="btn" id="cb1">
              Sign up here
            </a>
          </div>
        </div>
      </div>

      </section>
      <section className="container-fluid">
<div class="row chatdiv">
        <div class="col-md-6">
          <h2 id="h2chat">
            Do You Believe Healthcare Services Should Be More Accessible?
          </h2>
          <h3 id="chath3">Sign up below and spread the word.</h3>
          <div>
            <a href="#" class="btn fa fa-facebook">
              &nbsp;&nbsp;Facebook
            </a>
            &nbsp;&nbsp;&nbsp;
            <a href="#" class="btn fa fa-twitter">
              &nbsp;&nbsp;&nbsp;Twitter
            </a>
            &nbsp;&nbsp;&nbsp;
            <a href="#" class="btn fa fa-linkedin">
              &nbsp;&nbsp;Linkedin
            </a>
          </div>
        </div>
        <div class="col-md-6" id="chat">
          <img
            src={require("../images/chat.png")}
            width="auto"
            height="auto"
            className="img-fluid"
            alt="React Bootstrap logo"
          />
        </div>
      </div>
      </section>
<section className="container-fluid">
  <div class="row signup">
        <div class="col-md-6">
          <h1 id="h1style1">SIGN UP NOW!</h1>

          <p id="parastyle5">
            Join our mailing list. Show healthcare providers how committed we
            are to making services accessible to people who cannot afford it and
            encourage them to join us.
          </p>
          <p id="parastyle6">
            *Remember to click on the verification link in your email after you
            sign up. (Check your “junk/spam” folder).
          </p>
        </div>
        <div class="col-md-6">
          <form class="subform">
            <div className="form-group ">
              <label>Email</label>
              <input
                type="email"
                class="form-control subfom_input"
                placeholder="Please enter your email address"
              />
            </div>
            <div className="form-group ">
              <label>Password</label>
              <input
                type="password"
                class="form-control subfom_input"
                placeholder="Please enter your first name"
              />
            </div>
            <div>
              {" "}
              We Take your Privacy Seriously{" "}
              <span id="spanstyle">
                <strong>Terms of Use</strong>
              </span>{" "}
              and <span id="spanstyle">Privacy Policy.</span>{" "}
            </div>
            <button
              type="submit"
              className="btn"
              id="b5"
            >
              Send
            </button>
            &nbsp;
          </form>
        </div>
      </div>
</section>
<section className="container-fluid">
<div id="divalign">
        <footer id="footerstyle"
        >
          <div class="row">
            <div class="col-md-10">
              &copy; COPYRIGHT 2020 VITRUVIO.ALL RIGHTS RESERVED.
            </div>
            <div class="col-md-2">
              <a
                href="/Adminlogin"
                class="btn"
                id="b6"
              >
                {" "}
                Admin Login
              </a>
              &nbsp;&nbsp;&nbsp;&nbsp;
            </div>
          </div>
        </footer>
      </div>
</section>
      </Segment>
 	);
}
export default home1;