import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Redirect} from 'react-router-dom';
import Axios from 'axios';
import { data } from 'jquery';

 
class Providerspaymentmethod extends Component {

  constructor(props) {

    super(props);

    this.state= {

      posts: [],

    check: false,
      isLoading:true,
      address:false,



    };
    

  }
state={
  persons:[],
  post:"",
  cardnumber:"",
  paymentid:"",
}

  /*handlecheck=()=>{
    let address=this.state;
    if(address==true){
      alert("good");
    }
    else{
      alert("bad");
      window.location.href = "";
    }
  }*/

  // After componentWillMount
  componentDidMount() {
    Axios.get(`https://react.vitruvio.ca/phpreact/checkcard.php`)
      .then(res => {
        const persons = res.data;
        this.setState({ persons });
        console.log("getdata",persons);
      })
  }
  planSilver=()=>{
    Axios.post('https://react.vitruvio.ca/phpreact/pay.php',{
      id:sessionStorage.getItem('userData'),
      // name:response.data.data.name,
      sub_amount:28.25,
     
  })
  .then(({data}) => {
      if(data.success === 1){
        
         const amount = data.data;
         console.log("Ampodcwcwc checkdcecec",amount);
        //  alert("Amount to be paid for Silver Upgrade is "+amount);
        if (window.confirm(`Are you sure you would like to upgrade your subscription to the Silver plan? It will cost ${amount} per month.`)== true) {
          this.setState({ amount: amount });
         window.location.href=`https://web.na.bambora.com/scripts/payment/payment.asp?merchant_id=300207178&hashValue=7b52d51be12038d98ee5e4979e0751ca4dab8272&trnAmount=${amount}&trnOrderNumber=2`;
         
         } else {
           alert("You pressed Cancel!");
         }
         // window.location.reload(false);
      }
      else{
          alert(data.msg);

      }
  })
  .catch(error => {
      console.log(error);
  });
  }
  planPlatinum=()=>{
    Axios.post('https://react.vitruvio.ca/phpreact/pay.php',{
      id:sessionStorage.getItem('userData'),
      // name:response.data.data.name,
      sub_amount:56.5,
     
  })
  .then(({data}) => {
      if(data.success === 1){

         const amount1 = data.data;
      
         if (window.confirm(`Are you sure you would like to upgrade your subscription to the Platinum plan? It will cost ${amount1} per month.`)== true) {
          this.setState({ amount1:amount1 });
          window.location.href=`https://web.na.bambora.com/scripts/payment/payment.asp?merchant_id=300207178&hashValue=7b52d51be12038d98ee5e4979e0751ca4dab8272&trnAmount=${amount1}&trnOrderNumber=2`;
    
         } else {
           alert("You pressed Cancel!");
         }
        //  alert("Amount to be paid for Platinum Upgrade is "+amount1);
           }
      else{
          alert(data.msg);

      }
  })
  .catch(error => {
      console.log(error);
  });
  }
  
   handlecard = () => {
       
    // alert(this.addcardnumber.value);
    // alert(this.addexpiry.value);
    // alert(this.addcvv.value);

        Axios.post('https://react.vitruvio.ca/phpreact/provideraddcard.php',{
            id:sessionStorage.getItem('userData'),
            addcardnumber:this.addcardnumber.value,
            addexpirymonth:this.addexpirymonth.value,
            addexpiryyear:this.addexpiryyear.value,
            addcvc:this.addcvc.value,
           
        })
        .then(({data}) => {
            if(data.success === 1){
               alert(data.msg);
                window.location.reload(false);
            }
            else{
                alert(data.msg);
            }
        })
        .catch(error => {
            console.log(error);
        });
    }
handledown1=()=>{
  Axios.post('https://react.vitruvio.ca/phpreact/plandowngrade.php',{
    id:sessionStorage.getItem('userData'),
    
  })
}

// This gets done first
 componentWillMount=async() => {
  
    // await Axios.get(url)
    console.log("userData",sessionStorage.getItem('userData'));

     Axios.post('https://react.vitruvio.ca/phpreact/checkcard.php',{
            id:sessionStorage.getItem('userData'),
           
         })

        .then(response => {
        // alert(response.data.success);

        console.log("check1",response);
        
        // alert(response.data.success);
    //     alert(response.data.data.totalc);
    //  console.log("console response check",response.data.data) 
     

if(response.data.data.status == 'Inactive' ){
        this.setState({

          custpayid:response.data.data.custpayid,
          cardnumber: response.data.cardnumber,
          paymentid: response.data.paymentid,
          isLoading: false,
          check:false
        });


}else {
        this.setState({

          custpayid:response.data.data.custpayid,
          cardnumber:response.data.data.cardnumber,
          paymentid: response.data.paymentid,
          isLoading: false,
          check:true
          
        });


}

      })
 

  }

    render() {


       if (sessionStorage.getItem('userData') >= 0 && sessionStorage.getItem('usertype') == 2 ){


        }else {

        return (<Redirect to={'/'}/>)

        }



  const {check, cardnumber, custpayid, paymentid} = this.state;
 
// Check if Credit Card is connected

if(check == false){

  // Add Card Option for Credit Card
 
    return (
        <div class="container-fluid conspadd">
        
        <h1 style={{marginTop:"120px"}}>Provider Payment Method</h1>
        <p>No Credit Card Connected.</p>
       
<div class="row">
<div class="col-md-4">
<div class="card shadow1" >
<div class="card-header"> 
<p >Add Card</p>
</div>
<div class="card-body"> 
<th>Card Number</th>
<p><input type="number" className="form-control " placeholder="Card Number" ref={(val)=>this.addcardnumber=val} /></p>
</div>
<div class="card-body"> 
<th>Expiry Month</th>
<p><input type="number" className="form-control " placeholder="Expiry Month" ref={(val)=>this.addexpirymonth=val} /></p>
</div>
<div class="card-body"> 
<th>Expiry Year</th>
<p><input type="number" className="form-control " placeholder="Expiry Year" ref={(val)=>this.addexpiryyear=val} /></p>
</div>
<div class="card-body"> 
<th>CVC</th>
<p><input type="number" className="form-control " placeholder="CVC" ref={(val)=>this.addcvc=val} /></p>
</div>
<div class="card-footer"> 
 <p> <input type="button" onClick={() => this.handlecard()} className="btn btn-primary" value="Add Card" /></p>
 </div>
 </div>
 </div>
 </div>
     
  </div>
       
       
     

    );
  }
  else {

    // Modify Credit Card
    // Adding Payment Methods to the screen directly.

    return (
        <div class="container-fluid conspadd">
            <h1 style={{marginTop:"120px"}}>Provider Payment Method</h1>
            <p>Your Payment Method: Credit Card ending with {cardnumber} is Connected to Your Account. Request Delete Card.</p>   
        </div>
    );


  }

 

}
}
 
export default Providerspaymentmethod;