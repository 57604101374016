import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Redirect} from 'react-router-dom';

import {  MDBDataTable ,CardBody,Card } from 'mdbreact';
import Axios from 'axios';
import Table from 'react-bootstrap/Table'
 
class Admincustview extends Component {

   constructor(props) {
    super(props);
    this.state= {
      posts: [],
      isLoading:true,
      tableRows: [],
    };
  }

  logout  = () => {
    sessionStorage.clear();
    window.location.reload(false);
    this.props.history.push('/');
  }

  // Function to List all Customers
  componentWillMount = async() => {
    if(sessionStorage.length == 0){
    }else{
    // await Axios.get(url)
      Axios.post('https://react.vitruvio.ca/phpreact/allcustomerlist.php',{
        id:sessionStorage.getItem('userData'),
        accesstoken:sessionStorage.getItem('accesstoken'),    
      })

      .then(response => response.data)
      .then(data => {
         console.log(data.data);
         if (data.success == 0) 
          {
            alert(data.data);
            this.logout();
          }
         // alert(data.data);
         // if (err) throw err;
         this.setState({ posts: data.data })
      })

      .then(async() => {
         this.setState({ tableRows:this.assemblePosts(), isLoading:false })
         // console.log(this.state.tableRows);
      });
    }
  }
  // Function to Update Providers Status
  handleUpdate = (id,action) => {   
      Axios.post('https://react.vitruvio.ca/phpreact/customerservice_update.php',{
          id:id,
          action:action,
      })
      .then(({data}) => {
          if(data.success === 1){
              alert(data.msg);
              window.location.reload(false);
          }
          else{
              alert(data.msg);
          }
      })
      .catch(error => {
          console.log(error);
      });
  }

  // Function  to pass Acivte or Inactive status to handleUpdate
  assemblePosts= () => {
    let posts =this.state.posts.map((post) => {  
  if(post.status == 'InActive'){
        var arra = 'Active';
      }else if (post.status == 'Active'){
        var arra = 'InActive';
      }
      return (
        {
          cid: post.cid,
          name: post.name,
          email: post.email,
          address: post.address,
          city: post.city,  
          state: post.state,
          doctor: post.doctor,
          status: post.status,
          action:( <a onClick={() => this.handleUpdate(post.cid,post.status)} className="btn btn-primary">{arra}</a> 
            ),

        }
      )
    });
    return posts;
  }

  // Render TableRows
  render() {
    const data = {
      columns: [
          {
            label: 'Customer ID',
            field: 'cid',
          },
          {
            label: 'Name',
            field: 'name',
          }, 
          {
            label: 'Email',
            field: 'email',
          }, 
          {
            label: 'Address',
            field: 'address',
          }, 
          {
            label: 'city',
            field: 'city',
          }, 
          {
            label: 'State',
            field: 'state',
          },
          {
            label: 'Status',
            field: 'status',
          },
          {
            label: 'action',
            field: 'action',
          }, 
        ],
      rows:this.state.tableRows,
    }
    //Check if User is Logged in eles redirect out
    if (sessionStorage.getItem('userData') >= 0 && sessionStorage.getItem('usertype') == 3){
    }else {
      return (<Redirect to={'/'}/>)
    }

    //Return Table and Footer
    return (
      <div class="container-fluid cardclass">
        <h1>Customer  List</h1>
        <MDBDataTable responsiveSm responsive striped bordered hover data={data}/>   
      </div>
    )
  }
}
 
export default Admincustview;