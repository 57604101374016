import React, { Component, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Redirect} from 'react-router-dom';
import Modal from "react-bootstrap/Modal";
import {  MDBDataTable ,CardBody,Card,Button} from 'mdbreact';
import Axios from 'axios';
import queryString from 'query-string';
import {AppContext} from './Context';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import styles from './mystyle.module.css';
import $ from "jquery";

class Providerorder extends Component {
  constructor(props) {
    super(props);
    this.state= {
      modalpost:'',
      posts: [],
      isLoading:true,
      tableRows: [],
      show: false,
      disabled: true,
      disabled1: true,
    };

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  
  setSessionStorage(){
    if(localStorage.getItem('function') == null){
      localStorage.setItem('refresh', 1);
      localStorage.setItem('function',1);
    }
  }
 /*componentDidMount(){
   $(document).ready(function(){
$('.table').DataTable();
$(".btn-warning").ready(() =>{
      
      // Handler for .load() called.
      $("tbody tr").css('background-color','yellow');
      console.log("tr changed");
    });
});

   
  } */
  componentDidUpdate(){
    $(".btn-warning").ready(() =>{
      $('.btn-warning').parent('td').parent('tr').css('background-color','#73b1a3');
    });
    
  }

  refresh = async()=> {
    // await sleep(5000);
    if(localStorage.getItem('refresh')==0){
      // alert("Refresh every 30 Sec");
      setTimeout(function() { //Start the timer
        window.location.reload();
      }, 30000)
    }else{

    }
    // localStorage.setItem('refresh', 1);
    // window.setInterval(refresh(), 10000);
    // Call a function every 10000 milliseconds.
    // (OR 10 seconds).
    // Refresh or reload page.
  }

  autocall(){
    localStorage.setItem('refresh',1);
    // alert("AutoRefresh Off");
    window.location.reload(true);
  }
  autocallOn(){
    localStorage.setItem('refresh',0);
    // alert("AutoRefresh On. ");
    window.location.reload(true);
  }

  handleClose() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }

  logout  = () => {
    sessionStorage.clear();
    window.location.reload(false);
    this.props.history.push('/');
  }
  componentWillMount=async() => {
    if(sessionStorage.length == 0){
    }else{
    if(localStorage.getItem('function') == null){
      localStorage.setItem('refresh', 1);
      localStorage.setItem('function',1);
    }else{
      // alert("nothing");
    }
    // alert('why here');
    // return false;
    // await Axios.get(url)
    Axios.post('https://react.vitruvio.ca/phpreact/pharmacyorder.php',{
      id:sessionStorage.getItem('userData'),
      accesstoken:sessionStorage.getItem('accesstoken'),
    })
    .then(response => response.data)
    .then(data => {
      console.log(data.data);
      if(data.success == 3){
        this.props.history.push('/');
      }else if (data.success == 0) 
      {
        alert(data.data);
        this.logout();
      }
      // alert(data.data);
      // if (err) throw err;
      this.setState({ posts: data.data })
    })
    .then(async() => {
      this.setState({ tableRows:this.assemblePosts(), isLoading:false });
      // console.log(this.state.tableRows);
    });
    // localStorage.setItem('refresh', 0);
    if(localStorage.getItem('refresh')==0){
      // alert("Refresh Call here");
    this.refresh();
    }
  }
  }

  
  // Event Handlers for Order Processing
  handleUpdate = (id,action,type) => {
    if(type==0)
    {
      if(action=='Requested')
      {  
        var result = window.prompt("Enter Number of Medications that will be accepted ",1);
        if(result == null || result == ""){
          alert("You have cancelled");
          return false;
        }
        else{
          Axios.post('https://react.vitruvio.ca/phpreact/order_accept.php',{
            id:sessionStorage.getItem('userData'),
            result:result,
            oid:id,
            accesstoken:sessionStorage.getItem('accesstoken'),
          })
          .then(({data}) => {
            if (data.success == 3) 
            {
              alert(data.data);
              this.logout();
            }
            else if(data.success == 1){
              alert(data.data);
              window.location.reload(false);
            }
            else{
              alert("Order Accepted");
              window.location.reload(true);
            }
          })
          .catch(error => {
            console.log(error);
          });
          alert("You Accepted medication for "+result);
        }
      }
      
      else if(action=='Accepted')
      {
        if (window.confirm("Are you Sure Want to go for Ready for Pickup?")){
          Axios.post('https://react.vitruvio.ca/phpreact/order_ready.php',{
            // pid:sessionStorage.getItem('pid'),
            id:sessionStorage.getItem('userData'),
            deliver:deliver,
            oid:id,
            accesstoken:sessionStorage.getItem('accesstoken'),
          })
          .then(({data}) => {
            if (data.success == 3) 
            {
              alert(data.data);
              this.logout();
            }
            else if(data.success == 1){
              alert(data.data);
              window.location.reload(false);
            }
            else{
              alert("Order Ready For Pickup");
              window.location.reload(true);
            }
          })
          .catch(error => {
            console.log(error);
          });
        }else{
          return false;
        }
      }
      else if(action=='Ready For Pick Up')
      {
        var deliver = window.prompt("Enter Number of Medications that is delivered ",1);
        if(deliver == null || deliver == ""){
          alert("You have cancelled");
          return false;
        }
        else{
          Axios.post('https://react.vitruvio.ca/phpreact/order_deliver.php',{
            // pid:sessionStorage.getItem('pid'),
            id:sessionStorage.getItem('userData'),
            deliver:deliver,
            oid:id,
            accesstoken:sessionStorage.getItem('accesstoken'),
          })
          .then(({data}) => {
            if (data.success == 3) 
            {
              alert(data.data);
              this.logout();
            }
            else if(data.success == 1){
              alert(data.data);
              window.location.reload(false);
            }
            else{
              alert("Order Ready For Delivery");
              window.location.reload(true);
            }
          })
          .catch(error => {
            console.log(error);
          });
          alert("You Accepted medication for "+deliver);
        } 
      }
      else if(action=='Delivered')
      {
        if(window.confirm("Are you Sure Want to go for complete delivery")){
        }else{
          return false;
        }
      }
    }else{
      if(action=='Requested')
      {
        var result=1; 
        if (window.confirm("Are you Sure Want to go for Free medication?")){
          Axios.post('https://react.vitruvio.ca/phpreact/order_accept.php',{
            id:sessionStorage.getItem('userData'),
            result:result,
            oid:id,
            accesstoken:sessionStorage.getItem('accesstoken'),
          })
          .then(({data}) => {
            if (data.success == 3) 
            {
              alert(data.data);
              this.logout();
            }
            else if(data.success == 1){
              alert(data.data);
              window.location.reload(false);
            }
            else{
              window.location.reload(true);
            }
          })
          .catch(error => {
            console.log(error);
          });
        }else{
          return false;
        }
      }
      else if(action=='Accepted')
      {
        if (window.confirm("Are you Sure Want to go for Ready for Pickup?")){
          Axios.post('https://react.vitruvio.ca/phpreact/order_ready.php',{
            // pid:sessionStorage.getItem('pid'),
            id:sessionStorage.getItem('userData'),
            deliver:deliver,
            oid:id,
            accesstoken:sessionStorage.getItem('accesstoken'),
          })
          .then(({data}) => {
            if (data.success == 3) 
            {
              alert(data.data);
              this.logout();
            }
            else if(data.success == 1){
              alert(data.data);
              window.location.reload(false);
            }
            else{
              alert("Order Ready For Pickup");
              window.location.reload(true);
            }
          })
          .catch(error => {
            console.log(error);
          });
        }else{
          return false;
        }        
      }
      else if(action=='Ready For Pick Up')
      {
        if (window.confirm("The Number of free Medications for delivery is 1")){
          Axios.post('https://react.vitruvio.ca/phpreact/order_deliver.php',{
            // pid:sessionStorage.getItem('pid'),
            id:sessionStorage.getItem('userData'),
            deliver:deliver,
            oid:id,
            accesstoken:sessionStorage.getItem('accesstoken'),
          })
          .then(({data}) => {
            if (data.success == 3) 
            {
              alert(data.data);
              this.logout();
            }
            else if(data.success == 1){
              alert(data.data);
              window.location.reload(false);
            }
            else{
              alert("Order Ready For Delivery");
              window.location.reload(true);
            }
          })
          .catch(error => {
            console.log(error);
          });
        }else{
          return false;
        }
        var deliver=1; 
      }
      else if(action=='Delivered')
      {  
        if(window.confirm("Are you Sure Want to go for complete delivery")){
        }else{
          return false;
        }
      }
    }
  }


  handlereject1 = (id,action) => {
    this.setState({
      modalpost: id,
    });
    this.setState({ show: true });
  }  
  // alert(this.Reason.value);
  // alert('herer');

  // return false;

  // Order REject Function
  handlereject=(id,action)=>{
    if(this.Reason.value == ''){
      alert('Please provide reason before cancellation ');
      return false;
    }else{}
    if (window.confirm("Are you Sure You Want to Reject?")){
      Axios.post('https://react.vitruvio.ca/phpreact/order_reject.php',{
        // pid:sessionStorage.getItem('pid'),
        id:sessionStorage.getItem('userData'),
        oid:id,
        Reason:this.Reason.value,
        accesstoken:sessionStorage.getItem('accesstoken')
      })
      .then(({data}) => {
        if (data.success == 3) 
        {
          alert(data.data);
          this.logout();
        }
        else if(data.success == 1){
          // alert("Success!");
          alert(data.data);
          window.location.reload(false);
        }
        else{
          alert("Order Rejected");
          window.location.reload(true);
        }
      })
      .catch(error => {
        console.log(error);
      });
    }else{
      return false;
    }
  }
  
  // Image Download
  downloadurl = (url) => {
    var element = document.createElement("a");
    var file = new Blob(
      [
        "url"
      ],
      { type: "image/*" }
    );
    element.href = URL.createObjectURL(file);
    element.download = "image.jpg";
    element.click();
  };

  // Order Display
  handleUpdate1 = (status) => {
    // await Axios.get(url)
    Axios.post('https://react.vitruvio.ca/phpreact/pharmacyorder.php',{
      id:sessionStorage.getItem('userData'),
      status:status,     
    })
    .then(response => response.data)
    .then(data => {
      console.log(data.data);
      // alert(data.data);
      // if (err) throw err;
      this.setState({ posts: data.data })
    })
    .then(async() => {
      this.setState({ tableRows:this.assemblePosts(), isLoading:false })
      console.log(this.state.tableRows);
    });
  }

  // Concern Create Function
  handlealert=()=>{
    let ans=prompt("Submit to vitruvio");
    Axios.post('https://react.vitruvio.ca/phpreact/concern.php',{
      // id:sessionStorage.getItem('userData'),
      pid:queryString.parse(this.props.location.search).pval,
      concern:ans
    })
   .then(response => {
      //alert(response.data.msg)
    })
  }

  assemblePosts = () => {
    // await sleep(5000);
    // alert("sleeping here");
    let posts =this.state.posts.map((post) => {
      if(post.o_type == 1){
        if(post.status == 'Requested'){
          var arra = 'Accept';
          // var arra1 = "this.props.shouldHide ? 'hidden' : ''";
          var arra1 = "btn btn-warning";
          var arra2 = "btn btn-danger";
          this.setState({ disabled: false });
          // disabled: false;
        }else if (post.status == 'Accepted'){
          var arra1 = "btn btn-warning";
          var arra2 = "btn btn-danger";
          var arra = 'Ready For Pick Up';
          this.setState({ disabled: false });
        }else if(post.status == 'Ready For Pick Up'){
          var arra1 = "btn btn-warning";
          var arra2 = "btn btn-danger";
          var arra = 'Delivered';
          this.setState({ disabled: false });
        }else if(post.status.includes("Delivered")){
          var arra1 = "btn btn-warning  disabled";
          var arra2 = "btn btn-warning  d-none";
          var arra = 'Done';
          this.setState({ disabled: true });
        }else if(post.status == 'Rejected'){
          var arra = 'Rejected';
          var arra1 = "btn btn-danger d-none";
          var arra2 = "btn btn-danger ";     
          this.setState({ disabled: true });
        }
      }else{
        if(post.status == 'Requested'){
          var arra = 'Accept';
          // var arra1 = "this.props.shouldHide ? 'hidden' : ''";
          var arra1 = "btn btn-primary";
          var arra2 = "btn btn-danger";
          this.setState({ disabled: false });
          // disabled: false;
        }else if (post.status == 'Accepted'){
          var arra1 = "btn btn-primary";
          var arra2 = "btn btn-danger";
          var arra = 'Ready For Pick Up';
          this.setState({ disabled: false });
        }else if(post.status == 'Ready For Pick Up'){
          var arra1 = "btn btn-primary";
          var arra2 = "btn btn-danger";
          var arra = 'Delivered';
          this.setState({ disabled: false });
        }else if(post.status.includes("Delivered")){
          var arra1 = "btn btn-success  disabled";
          var arra2 = "btn btn-success  d-none";
          var arra = 'Done';        
          this.setState({ disabled: true });
        }else if(post.status == 'Rejected'){
          var arra = 'Rejected';
          var arra1 = "btn btn-danger d-none";
          var arra2 = "btn btn-danger ";     
          this.setState({ disabled: true });
        }
      }
      return (
        {
          bid: post.bid,
          upload: (<a href={post.upload} target="_blank" download  >View File</a>),
          description: post.description,
          name: post.name,
          email: post.email,
          phone: post.phone,
          date: post.date,
          time: post.time,  
          status: post.status,
          reason: post.reason,
          note: post.note,
          action:[( <Button style={{marginBottom:'20px'}}onClick={() => this.handleUpdate(post.bid,post.status,post.o_type)}   className={arra1}    disabled={this.state.disabled} >{arra}
          </Button>  ),
          (<Button  className={arra2} onClick={() => this.handlereject1(post.bid,post.status)}   disabled={this.state.disabled}  >
            Cancel
          </Button>
          )],
        }
      )
    });
    return posts;
  }

  render() {
    const data = {
      columns: [
        // {
        //   label:'#',
        //   field:'number',
        // },
        {
          label: 'OrderID',
          field: 'bid',
          sort: "asc",
        },
        {
          label: 'Action',
          field: 'action',
          sort: "asc",
        },
        {
          label: 'Status',
          field: 'status',
          sort: "asc",
        },
        {
          label: 'Reason',
          field: 'reason',
          sort: "asc",
        },       
        {
          label: 'Prescription',
          field: 'upload',
          sort: "asc",
        },
        {
          label: 'Description',
          field: 'description',
          sort: "asc",
        },
        {
          label: 'Note',
          field: 'note',
          sort: "asc",
        },
        {
          label: 'Date',
          field: 'date',
          sort: "asc",
        }, 
        {
          label: 'Time',
          field: 'time',
          sort: "asc",
        },
        {
          label: 'Patient Name',
          field: 'name',
          sort: "asc",
        },
        {
          label: 'Email',
          field: 'email',
          sort: "asc",
        }, 
        {
          label: 'Phone',
          field: 'phone',
          sort: "asc",
        }, 
      ],
      rows:this.state.tableRows,
    }
  if (sessionStorage.getItem('userData') >= 0 && sessionStorage.getItem('usertype') == 2  ){
  }else {
    return (<Redirect to={'/'}/>)
  }

  const { modalpost } = this.state;
    return (
      // <RowclassName="mb-4">
      //   <Col md="12">
      <div class="container-fluid cardclass conspadd"> 
        <h1>Provider  Orders</h1>
        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Proper Reason For Cancellation </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group" >
              <label>Reason</label>
                <textarea class="form-control" rows="5" id="Reason"   ref={(val)=>this.Reason=val}  />
                <input type="hidden" class="form-control" id="mbid" defaultValue={modalpost}   ref={(val)=>this.mbid=val}  />            
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
            Close
            </Button>
            <button  className="btn" id="btncolor" onClick={() => this.handlereject(this.mbid.value,this.Reason.value)}    >Reject</button>
          </Modal.Footer>
        </Modal>
        <div class="row providerbtn">
          <div className="col-md-8">
            <Button onClick={() => this.handleUpdate1('')} className="btn btn-size" id="btncolor">ALL </Button>  &nbsp;  &nbsp; &nbsp;
            <Button onClick={() => this.handleUpdate1('Requested')}  className="btn  btn-size" id="btncolor">Placed </Button>
            &nbsp;  &nbsp; &nbsp;  
            <Button onClick={() => this.handleUpdate1('Accepted')}  className="btn  btn-size" id="btncolor">Accept </Button>     &nbsp;  &nbsp; &nbsp;
            <Button onClick={() => this.handleUpdate1('Rejected')}  className="btn  btn-size" id="btncolor">Reject </Button> &nbsp;  &nbsp; &nbsp;
            <Button onClick={() => this.handleUpdate1('Delivered')}  className="btn  btn-size" id="btncolor">Deliver </Button>   &nbsp;  &nbsp; &nbsp;
          </div>
          <div class="col-md-4">
            <a href={'/Supportpharmacy?pval=' + sessionStorage.getItem('userData')} class="btn float-right" id="btncolor1">Tell us your concern or issue</a> 
          </div>
        </div>
        <div>
          <h4><mark> Note : Not to hesitate to call each other for inquiries or request for detail.</mark> </h4>
          {localStorage.getItem('refresh',0) == 0 && (
          <Button onClick={() => this.autocall()} className="btn btn-size" id="btncolor">Switch AutoRefresh Off</Button>
         )}
         {localStorage.getItem('refresh',0) == 1 && (
          <Button onClick={() => this.autocallOn()} className="btn btn-size" id="btncolor">Switch AutoRefresh On</Button>
         )}
        </div>
        <MDBDataTable responsiveSm responsive striped bordered hover data={data} />
      </div>
    )
  }
}
export default Providerorder;