import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Redirect} from 'react-router-dom';
import Axios from 'axios';
import { data } from 'jquery';

 
class Providersubscription extends Component {

  constructor(props) {

    super(props);

    this.state= {

      posts: [],

    check: false,
      isLoading:true,
      address:false,



    };
    

  }
state={
  persons:[],
  post:"",
  amount:"",
  amount1:"",
  cardnumber:""
}
logout  = () => {
  sessionStorage.clear();
  window.location.reload(false);
  this.props.history.push('/');
}

  /*handlecheck=()=>{
    let address=this.state;
    if(address==true){
      alert("good");
    }
    else{
      alert("bad");
      window.location.href = "";
    }
  }*/
  componentDidMount() {
    if(sessionStorage.length == 0){
    }else{
    Axios.get(`https://react.vitruvio.ca/phpreact/checksubscription.php`,{
      accesstoken:sessionStorage.getItem('accesstoken'),
    })
    .then(({data}) => {
      if (data.success == 3) 
      {
        alert(data.data);
        this.logout();
    }})
    .then(res => {
      const persons = res.data;
      this.setState({ persons });
      console.log("getdata",persons);
    })
  }
  }
  planFree=()=>{
    Axios.post('https://react.vitruvio.ca/phpreact/changepharmacyplan.php',{
      id:sessionStorage.getItem('userData'),
      accesstoken:sessionStorage.getItem('accesstoken'),
      // name:response.data.data.name,
      plan:"Free",
     
  })
  .then(({data}) => {
    if (data.success == 2) 
    {
      alert(data.data);
      this.logout();
    }
    else if(data.success === 1){
        
         const amount = data.amount;
         const planid = data.planid;
         console.log("Ampodcwcwc checkdcecec",amount);
        //  alert("Amount to be paid for Silver Upgrade is "+amount);
        if (window.confirm(`Are you sure you would like to upgrade your subscription to the Silver plan? It will cost ${amount} per month.`)== true) {
          this.setState({ amount: amount });

              Axios.post('https://react.vitruvio.ca/phpreact/upgradesubscription.php',{
                id:sessionStorage.getItem('userData'),
                accesstoken:sessionStorage.getItem('accesstoken'),
                planid:planid,
                plan:"Free"           
            })
            .then(({data}) => {
              if (data.success == 2) 
              {
                alert(data.data);
                this.logout();
              }
              else if(data.success === 1){
                  alert(data.msg);
                    window.location.reload(false);
                }
                else{
                    alert(data.msg);

                }
            })
            .catch(error => {
                console.log(error);
            });
        //  window.location.href=`https://web.na.bambora.com/scripts/payment/payment.asp?merchant_id=300207178&hashValue=7b52d51be12038d98ee5e4979e0751ca4dab8272&trnAmount=${amount}&trnOrderNumber=2`;
         
         } else {
           alert("You pressed Cancel!");
         }
         // window.location.reload(false);
      }
      else{
          alert(data.msg);

      }
  })
  .catch(error => {
      console.log(error);
  });
  }
  planSilver=()=>{
    Axios.post('https://react.vitruvio.ca/phpreact/changepharmacyplan.php',{
      id:sessionStorage.getItem('userData'),
      accesstoken:sessionStorage.getItem('accesstoken'),
      // name:response.data.data.name,
      plan:"Silver",
     
  })
  .then(({data}) => {
    if (data.success == 2) 
        {
          alert(data.data);
          this.logout();
        }
      else if(data.success === 1){
        
         const amount = data.amount;
         const planid = data.planid;
         console.log("Ampodcwcwc checkdcecec",amount);
        //  alert("Amount to be paid for Silver Upgrade is "+amount);
        if (window.confirm(`Are you sure you would like to upgrade your subscription to the Silver plan? It will cost ${amount} per month.`)== true) {
          this.setState({ amount: amount });

              Axios.post('https://react.vitruvio.ca/phpreact/upgradesubscription.php',{
                id:sessionStorage.getItem('userData'),
                accesstoken:sessionStorage.getItem('accesstoken'),
                planid:planid,
                plan:"Silver"           
            })
            .then(({data}) => {
              if (data.success == 2) 
              {
                alert(data.data);
                this.logout();
              }
              else if(data.success === 1){
                  alert(data.msg);
                    window.location.reload(false);
                }
                else{
                    alert(data.msg);

                }
            })
            .catch(error => {
                console.log(error);
            });
        //  window.location.href=`https://web.na.bambora.com/scripts/payment/payment.asp?merchant_id=300207178&hashValue=7b52d51be12038d98ee5e4979e0751ca4dab8272&trnAmount=${amount}&trnOrderNumber=2`;
         
         } else {
           alert("You pressed Cancel!");
         }
         // window.location.reload(false);
      }
      else{
          alert(data.msg);

      }
  })
  .catch(error => {
      console.log(error);
  });
  }
  planPlatinum=()=>{
    Axios.post('https://react.vitruvio.ca/phpreact/changepharmacyplan.php',{
      id:sessionStorage.getItem('userData'),
      accesstoken:sessionStorage.getItem('accesstoken'),
      // name:response.data.data.name,
      plan:"Platinum",
     
  })
  .then(({data}) => {
    if (data.success == 2) 
    {
      alert(data.data);
      this.logout();
    }
    else if(data.success === 1){
        
         const amount = data.amount;
         const planid = data.planid;
         console.log("Ampodcwcwc checkdcecec",amount);
        //  alert("Amount to be paid for Silver Upgrade is "+amount);
        if (window.confirm(`Are you sure you would like to upgrade your subscription to the Platinum plan? It will cost ${amount} per month.`)== true) {
          this.setState({ amount: amount });

              Axios.post('https://react.vitruvio.ca/phpreact/upgradesubscription.php',{
                id:sessionStorage.getItem('userData'),
                accesstoken:sessionStorage.getItem('accesstoken'),
                planid:planid,
                plan:"Platinum"           
            })
            .then(({data}) => {
              if (data.success == 2) 
              {
                alert(data.data);
                this.logout();
              }
              else if(data.success === 1){
                  alert(data.msg);
                    window.location.reload(false);
                }
                else{
                    alert(data.msg);

                }
            })
            .catch(error => {
                console.log(error);
            });
        //  window.location.href=`https://web.na.bambora.com/scripts/payment/payment.asp?merchant_id=300207178&hashValue=7b52d51be12038d98ee5e4979e0751ca4dab8272&trnAmount=${amount}&trnOrderNumber=2`;
         
         } else {
           alert("You pressed Cancel!");
         }
         // window.location.reload(false);
      }
      else{
          alert(data.msg);

      }
  })
  .catch(error => {
      console.log(error);
  });
  }
  
   handleremove = () => {
 
       
        Axios.post('https://react.vitruvio.ca/phpreact/provideractive.php',{
            id:sessionStorage.getItem('userData'),
            accesstoken:sessionStorage.getItem('accesstoken'),
            authnum:this.Authentication.value,
           
        })
        .then(({data}) => {
          if (data.success == 3) 
          {
            alert(data.data);
            this.logout();
          }
          else if(data.success === 1){
               alert(data.msg);
                window.location.reload(false);
            }
            else{
                alert(data.msg);

            }
        })
        .catch(error => {
            console.log(error);
        });
    }
handledown1=()=>{
  Axios.post('https://react.vitruvio.ca/phpreact/plandowngrade.php',{
    id:sessionStorage.getItem('userData'),
    accesstoken:sessionStorage.getItem('accesstoken'),
  })
  .then(({data}) => {
    if (data.success == 0) 
    {
      alert(data.data);
      this.logout();
    }
  })
}

 componentWillMount=async() => {
  

    // await Axios.get(url)

     Axios.post('https://react.vitruvio.ca/phpreact/checksubscription.php',{
            id:sessionStorage.getItem('userData'),
            accesstoken:sessionStorage.getItem('accesstoken'),           
         })

        .then(response => {
        // alert(response.data.success);

        console.log("check1",response);
        
        // alert(response.data.success);
    //     alert(response.data.data.totalc);
    //  console.log("console response check",response.data.data) 
     

if(response.data.data.prostatus == 'InActive' ){
        this.setState({

          post:response.data.data.totalc,
          cardnumber:response.data.data.cardnumber,
          posts: response.data.data,
          isLoading: false,
          check:false
        });


}else {
        this.setState({

          post:response.data.data.totalc,
          cardnumber:response.data.data.cardnumber,
          posts: response.data.data,
          isLoading: false,
          check:true
          
        });


}

      })
      .then(({data}) => {
        if (data.success == 0) 
        {
          alert(data.data);
          this.logout();
        }
      })
      Axios.post('https://react.vitruvio.ca/phpreact/all-provider.php',{
        id:sessionStorage.getItem('userData'),
        accesstoken:sessionStorage.getItem('accesstoken'),        
     })

     .then(({data}) => {
      if (data.success == 3) 
      {
        alert(data.data);
        this.logout();
      }
    }) 
   

    .then(response => {
   // alert(response.data.users[0].address);

    console.log('check2',response);
    // alert(response.data.success);
    // alert(response.data.data.prostatus);

if(response.data.users.address != null ){
    this.setState({
     address:true
    });


}else {
    this.setState({
      
address:false
    });


}

  })


 

  }

    render() {


       if (sessionStorage.getItem('userData') >= 0 && sessionStorage.getItem('usertype') == 2 ){


}else {

return (<Redirect to={'/'}/>)

}

  const {check, isLoading, cardnumber, posts,post,amount,amount1} = this.state;
 

if(check == false){

  // Add Card Option for Credit Card
 
    return (
        <div class="container-fluid conspadd">
        
        <h1 style={{marginTop:"120px"}}>Provider Subscription</h1>
        {/* <p>Your Payment Method: No Card Connected. <a href="/Providerpaymentmethod">Add Now.</a></p>
        <p>No Plan Is Active</p> */}
       
   
<div class="row">
<div class="col-md-4">
<div class="card shadow1" >
<div class="card-header"> 
<th>Needs</th>
<p >Activation</p>
</div>
<div class="card-body"> 
<th>Enter Vitruvio Code</th>
<p><input type="text" className="form-control " placeholder="Authentication Number" ref={(val)=>this.Authentication=val} /></p>
</div>
<div class="card-footer"> 
 <p> <input type="button" onClick={() => this.handleremove()} className="btn btn-primary" value="Activate Now" /></p>
 </div>
 </div>
 </div>
 <div class="col-md-4">
 
 </div>
 <div class="col-md-4">
   </div>
   </div>


         <div class="footlog" style={{textAlign:"center"}}>
    <footer style={{color:"#0e4958"}}>&copy; COPYRIGHT 2020 VITRUVIO.ALL RIGHTS RESERVED.</footer>
      </div>
   
  </div>
       
       
     

    );
  }
  else {

    // Adding Payment Methods to the screen directly.

    return (
        <div class="container-fluid conspadd">
        <h1 style={{marginTop:"120px"}}>Provider Subscription</h1>
        {cardnumber === "" ?(
        <p>Your Payment Method: <a href="/Providerspaymentmethod">Add Credit Card Now</a>.</p>

        ) : (
          <p>Your Payment Method: Credit Card Ending with {cardnumber} is connected to the account.</p>
          
          )
        }
        {/* <p>Your current  Subscription is  Active</p> */}
       <p>Your order limit is  <strong>{post} </strong> Once you reach your limit, your pharmacy will be unable to take new orders during the month unless you upgrade your subscription plan</p>

      <div class="row">
<div class="col-md-4">
<div class="card shadow1" style={{cursor:'pointer'}}>
{post ==100?
<div class="card-header" style={{backgroundColor:'#4d8a7c'}}> 
<th style={{color:'white'}}>Plan</th>
<p style={{color:'white'}}>Basic</p>
</div>
: post==1000 || post=='Unlimited'?
<div class="card-header"> 
<th>Plan</th>
<p>Basic</p>
</div>:post=='1'}
<div class="card-body">
<th>Number of Prescription</th>
<td>1-100 prescriptions per month</td>
</div>
<div class="card-body"> 
<th>Price</th>
<p>Free</p>
</div>
{post ==100?
<div class="card-footer" style={{backgroundColor:'#4d8a7c'}} > 

<p style={{color:'white'}}>Free</p>

{cardnumber === "" ?(
 <p>{post ==100? <p style={{color:'white'}}>Active plan</p>: post==1000 || post=='Unlimited' ?<a href='/Providerspaymentmethod' style={{textDecoration:"none",color:"#4d8a7c"}}>Add Card to Activate</a> :<a href='/Providerspaymentmethod' style={{textDecoration:"none",color:"#4d8a7c"}}>Add Card to Activate</a>}</p>

        ) : (
          <p>{post ==100? <p style={{color:'white'}}>Active plan</p>: post==1000 || post=='Unlimited' ?<a href='mailto:clinton@vitruvio.ca' style={{textDecoration:"none",color:"#4d8a7c"}}>Downgrade</a> :<a onClick={()=>this.planSilver()}  style={{textDecoration:"none",color:"#4d8a7c"}}>Upgrade</a>}</p>
          
          )
}
 </div>
: post==1000 || post=='Unlimited'?
<div class="card-footer" > 
<th>Select Plan</th>
<p>Free</p>

{cardnumber === "" ?(
 <p>{post ==100? <p style={{color:'white'}}>Active plan</p>: post==1000 || post=='Unlimited' ?<a href='/Providerspaymentmethod' style={{textDecoration:"none",color:"#4d8a7c"}}>Add Card to Activate</a> :<a href='/Providerspaymentmethod' style={{textDecoration:"none",color:"#4d8a7c"}}>Add Card to Activate</a>}</p>

        ) : (
          <p>{post ==100?'Active plan': post==1000 || post=='Unlimited' ?<a onClick={()=>this.planFree()} style={{textDecoration:"none",color:"#4d8a7c"}}>Downgrade</a> :<a onClick={()=>this.planSilver()}  style={{textDecoration:"none",color:"#4d8a7c"}}>Upgrade</a>}</p>
          
          )
}
 </div>:post=='1'}
 </div> 
 </div>
 <div class="col-md-4">
 {/* <a href="https://web.na.bambora.com/scripts/payment/payment.asp?merchant_id=300207178&amp;hashValue=7b52d51be12038d98ee5e4979e0751ca4dab8272&amp;trnAmount=28.25&amp;trnOrderNumber=2" style={{textDecoration:"none",color:"#4d8a7c"}}> */}

 

 <div class="card shadow1" style={{cursor:'pointer'}}>
 {post ==1000?
 <div class="card-header" style={{backgroundColor:'#4d8a7c'}}> 
 <th style={{color:'white'}}>Plan</th>
 <p style={{color:'white'}}>Silver</p>
 </div>
 :post==100 || post=='Unlimited'?
 <div class="card-header"> 
 <th>Plan</th>
 <p>Silver</p>
 </div>:post=='1'}
 <div class="card-body">
 <th>Number of Prescription</th>
 <td>100-1000 prescriptions per month</td>
 </div>
 <div class="card-body"> 
 <th>Price</th>
 <p>$25 plus HST (13%)</p>
 </div>
 {post ==1000?
 <div class="card-footer" style={{backgroundColor:'#4d8a7c'}}>
  <p style={{color:'white'}}> $28.25 </p>

{cardnumber === "" ?(
 <p>{post ==1000?<p style={{color:'white'}}>Active plan</p>:post=='Unlimited' ?<a href='/Providerspaymentmethod' style={{textDecoration:"none",color:"#4d8a7c"}}>Add Card to Activate</a> :<a href='/Providerspaymentmethod'  style={{textDecoration:"none",color:"#4d8a7c"}}>Add Card to Activate</a>}</p>

        ) : (
          <p>{post ==1000?<p style={{color:'white'}}>Active plan</p>:post=='Unlimited' ?<a  onClick={()=>this.planSilver()} style={{textDecoration:"none",color:"#4d8a7c"}}>Downgrade</a> :<a onClick={()=>this.planPlatinum()}  style={{textDecoration:"none",color:"#4d8a7c"}}>Upgrade</a>}</p>
          
          )
}
 </div>
 :post==100 || post=='Unlimited'?
 <div class="card-footer" >
 <th>Select Plan</th> <p> $28.25 </p>

{cardnumber == "" ?(
 <p>{post ==1000?'Active plan':post=='Unlimited' ?<a href='/Providerspaymentmethod' style={{textDecoration:"none",color:"#4d8a7c"}}>Add Card to Activate</a> :<a onClick={()=>this.planSilver()}  style={{textDecoration:"none",color:"#4d8a7c"}}>Add Card to Activate</a>}</p>

        ) : (
          <p>{post ==1000?'Active plan':post=='Unlimited' ?<a onClick={()=>this.planSilver()} style={{textDecoration:"none",color:"#4d8a7c"}}>Downgrade</a> :<a onClick={()=>this.planSilver()}  style={{textDecoration:"none",color:"#4d8a7c"}}>Upgrade</a>}</p>
          
          )
}
 </div>:post=='1'}
 </div>

 </div>
 <div class="col-md-4">
 {/* <a href="https://web.na.bambora.com/scripts/payment/payment.asp?merchant_id=300207178&amp;hashValue=7b52d51be12038d98ee5e4979e0751ca4dab8272&amp;trnAmount=56.5&amp;trnOrderNumber=2"   style={{textDecoration:"none",color:"#0e4958"}}> */}
 

 <div class="card shadow1" style={{cursor:'pointer' }}>
 {post =='Unlimited' ?
 <div class="card-header" style={{backgroundColor:'#4d8a7c'}}> 
 <th style={{color:'white'}}>Plan</th>
 <p style={{color:'white'}}>Platinum</p>
 </div>
: post==1000 || post==100 ? <div class="card-header"> 
<th>Plan</th>
<p>Platinum</p>
</div>:post=='1'}
 <div class="card-body">
 <th>Number of Prescription</th>
 <td>1000+ prescriptions per month</td>
 </div>
 <div class="card-body"> 
 <th>Price</th>
 <p>$50 plus HST(13%)</p>
 </div>
 {post =='Unlimited' ?
 <div class="card-footer" style={{backgroundColor:'#4d8a7c'}}>

   <p style={{color:'white'}}> $56.50 </p>
   
{cardnumber === "" ?(
   <p>{post =='Unlimited'?<p style={{color:'white'}}>Active plan</p>:<a href='/Providerspaymentmethod'  style={{textDecoration:"none",color:"#4d8a7c"}}>Add Card to Activate</a>}</p>

        ) : (
          <p>{post =='Unlimited'?<p style={{color:'white'}}>Active plan</p>:<a onClick={()=>this.planPlatinum() }  style={{textDecoration:"none",color:"#4d8a7c"}}>Upgrade</a>}</p>
          
          )
}
   <p>
  
   </p>
  
   </div>
   : post==1000 || post==100 ?
   <div class="card-footer" >
   <th>Select Plan</th>
    <p> $56.50 </p>
{cardnumber === "" ?(
    <p>{post =='Unlimited'?'Active plan':<a href='/Providerspaymentmethod' style={{textDecoration:"none",color:"#4d8a7c"}}>Add Card to Activate</a>}</p>

        ) : (
          <p>{post =='Unlimited'?'Active plan':<a onClick={()=>this.planPlatinum() }  style={{textDecoration:"none",color:"#4d8a7c"}}>Upgrade</a>}</p>
          
          )
}
    <p>
   
    </p>
   
    </div>
    : post=='1'}
   </div>
   </div>
      </div>
   
  </div>
       
       
     

    );


  }

 

}
}
 
export default Providersubscription;