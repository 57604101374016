import React ,{Component} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Axios from 'axios';
import {AppContext} from './Context';
import  { Redirect } from 'react-router-dom'
import { Helmet } from 'react-helmet';
class Patientsredirect extends Component{
  constructor(props) {
    super(props);
    this.state= {
      posts: [],
      isLoading:true,
      tableRows: [],
    };
  }
  render() {
    if (sessionStorage.getItem('userData') >= 0 && sessionStorage.getItem('usertype') == 1  ){
    }else {
      return (<Redirect to={'/'}/>)
    }
    return (     
      <div id="segmentstyle">
        <div className="container-fluid aboutclass">
          <div className="row">
            <div className="col-xs-2 col-md-2"></div>
            <div className="col-xs-7 col-md-7 text-center"> 
              <h2 id="learnh2">Thank you for joining Vitruvio and being part of the movement to bring social responsibility to healthcare.</h2>
            </div>
          </div>
          <div class="row learndiv">
            <div className="col-xs-2 col-md-2"></div>
            <div className="col-xs-8 col-md-8 text-center">
              <p id="p5">Please follow the steps below to complete your profile and be part of the solution of affordable healthcare.</p>
              <p id="p5">Step 1: Complete your profile in the Customer Section, necessary to submit your prescription orders.</p>
            </div>
          </div>
        </div>
      </div>  
    );
  }     
}
export default Patientsredirect;