import React ,{Component} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Axios from 'axios';
import {AppContext} from './Context';
import  { Redirect } from 'react-router-dom'
class Register extends Component{
   static contextType=AppContext;

    // Customer Register Function
    insertUser=(event)=>{
       event.preventDefault();
       event.persist();
       // Axios.post('https://react.vitruvio.ca/phpreact/add-user.php',{
        // Axios.post('https://react.vitruvio.ca/twilio/verifycust.php',{
        Axios.post('https://react.vitruvio.ca/phpreact/twilio/verifycust.php',{
                name:this.Name.value,
           email:this.Email.value,
           phone:this.Phone.value,
           Password:this.Password.value,
        })
        .then(function ({data}) {
            if(data.success == 1){
                // this.context.addNewUser(data.id,this.Name.value,
                //  this.Email.value,
                //  this.Password.value,
                //  this.Users.value);
                event.target.reset();
                sessionStorage.setItem('CustomerNumber',data.users);
                this.props.history.push("Otpverifycust");
            }else{
                alert(data.msg);
            }
        }.bind(this))
        .catch(function (error) {
            // console.log(error);
        });
    }

    render(){
        if (sessionStorage.getItem('userData') >= 0 && sessionStorage.getItem('usertype') == 1  ){
            return (<Redirect to={'/PatientsDashboard'}/>)
        }else {}

        return (
            <div className="container-fluid aboutclass">
                <form class="formPatient"  onSubmit={this.insertUser}>
                    <h3 lass="formh2">Patient Register</h3>
                    <div className="row">
                        <div className="col-md-3"></div>
                        <div className="form-group col-md-6" style={{textAlign:"left"}}>
                            <label>Name</label><span class="required">*</span>
                            <input type="text" className="form-control " placeholder="Enter Name" ref={(val)=>this.Name=val} required />
                        </div>
                    </div>
                    <div className="row" >
                        <div className="col-md-3"></div>
                            <label style={{marginLeft:"14px"}}>Phone</label><span class="required">* (Note:you have to verify phone number)</span>
                    </div>
                    <div className="row">
                        <div className="col-md-3"></div>
                        <div className="form-group col-md-6 input-group" style={{textAlign:"left"}}>
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon1">+1</span>
                            </div>
                            <input type="text" pattern='\d{10}' className="form-control" aria-describedby="basic-addon1" placeholder="Enter 10 Digit Phone Number " ref={(val)=>this.Phone=val}  required/>
                        </div>  
                    </div>
                    <div className="row">
                        <div className="col-md-3"></div>
                        <div className="form-group col-md-6" style={{textAlign:"left"}}>
                            <label>Email</label><span class="required">*</span>
                            <input type="email" className="form-control " placeholder="Enter Email ID" ref={(val)=>this.Email=val}  required/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3"></div>
                        <div className="form-group col-md-6 " style={{textAlign:"left"}}>
                            <label>Password</label><span class="required">*</span>
                            <input type="password" className="form-control " placeholder="Enter password" ref={(val)=>this.Password=val} required />
                        </div>
                    </div>
                    <button type="submit" className="btn" id="btncolor">Create new Account</button>&nbsp;   
                </form>
            </div>
        );
    }      
}
export default Register;