import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Redirect} from 'react-router-dom';
import Axios from 'axios';
import queryString from 'query-string';
class Paymentfail extends React.Component{

  // Payment Processing Function 
  componentDidMount() {
    if(sessionStorage.length == 0){
    }else{
    Axios.post('https://react.vitruvio.ca/phpreact/paymentmethod.php',{
      id:sessionStorage.getItem('userData'),
      trans_status:queryString.parse(this.props.location.search).trnApproved,
      transaction_id:queryString.parse(this.props.location.search).trnId,
      transaction_amount:queryString.parse(this.props.location.search).trnAmount,
      transaction_date:queryString.parse(this.props.location.search).trnDate,
      provider_id:queryString.parse(this.props.location.search).trnOrderNumber,
    })
    .then(response => response.data)
  }
  }
  render(){
    if (sessionStorage.getItem('userData') >= 0 && sessionStorage.getItem('usertype') == 2  ){
    }else {
      return (<Redirect to={'/'}/>)
    }
    return (
      <div>
        <div className="container-fluid text-center payclass">
          <h3>Payment UnSuccessful</h3>
          <p>Click try again button to get redirected to Subscription page. </p>
          <a href="/Providersubscription" className="btn btn-success">Try Again </a>
        </div>
      </div>
    );
  }
}
export default Paymentfail;