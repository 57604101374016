import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Redirect} from 'react-router-dom';
import $ from "jquery";
import Axios from 'axios';
class Providerprofile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
      isLoading: true,
      file:null,
      errors: null
    };
    this.onChange = this.onChange.bind(this)
    this.uploadFile = this.uploadFile.bind(this)     
  }

  onChange(e) {
    this.setState({file:e.target.files[0]})
  }

  logout  = () => {
    sessionStorage.clear();
    window.location.reload(false);
    this.props.history.push('/');
  }

  // File Upload Funciton
  async uploadFile(file){
    const formData = new FormData();
    formData.append('avatar',file)
    formData.append('user',sessionStorage.getItem('userData'))
    // formData.append('accesstoken',sessionStorage.getItem('accesstoken'))
    return  await Axios.post('https://react.vitruvio.ca/phpreact/update-providerprofilepicture.php', formData,{
      headers: {
        'content-type': 'multipart/form-data'
      },
    });
  }
 
  // Provider Profile Delete
  handleDelete = (id) => {
      if (window.confirm("Are you Sure  you wish to Unsubscribe this will logout your session and you will not able to login ?")){
      }else{
        return false;
      }
      Axios.post('https://react.vitruvio.ca/phpreact/provid-delete.php',{
        id:id,
        accesstoken:sessionStorage.getItem('accesstoken'),
      })
      .then(({data}) => {
        if (data.success == 0) 
        {
          alert(data.data);
          this.logout();
        }
        else if(data.success === 1){
          alert(data.msg);
          sessionStorage.clear();
          window.location.reload(false);
          this.props.history.push('/');
        }else{
          alert(data.msg);
        }
      })
      .catch(error => {
        console.log(error);
      });
    }
  
  // Update Provider Profile
  updatetProvider=(event)=>{
    event.preventDefault();
    let res =  this.uploadFile(this.state.file);
    console.log(res.data);
    event.persist();
    Axios.post('https://react.vitruvio.ca/phpreact/update-providerprofile.php',{
      name:this.Name.value,
      email:this.Email.value,
      address:this.Address.value,
      city:this.City.value,
      state:this.State.value,
      postcode:this.Postcode.value,
      phone:this.Phone.value,
      type:this.Type.value,
      fax:this.Fax.value,
      about:this.about.value,
      what:this.What.value,
      how1:this.how1.value,
      freelimit:this.freelimit.value,
      image:this.state.file,              
      id:sessionStorage.getItem('userData'),
      accesstoken:sessionStorage.getItem('accesstoken'),
    },{
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
    .then(function ({data}) {
      if (data.success == 3) 
      {
        alert(data.data);
        this.logout();
      }
      else if(data.success == 1){
        event.target.reset();
        alert(data.msg);             
        window.location.reload(false);    
      }else{
        alert(data.msg);
        window.location.reload(false);    
      }
    }.bind(this))
    .catch(function (error) {
      // console.log(error);
    });
  }
  
  // On update event Value Update
  componentDidUpdate(){
    var maxLength = 255;
    $('.abouttext').keyup(function() {
      var textlen = maxLength - $(this).val().length;
      $('#rchars').text(textlen);
    });
    $('.accesstext').keyup(function() {
      var textlen = maxLength - $(this).val().length;
      $('#rchars1').text(textlen);
    });
    $('.committext').keyup(function() {
      var textlen = maxLength - $(this).val().length;
      $('#rchars2').text(textlen);
    });
  }
  
  // Get Provider Data
  getPosts() {
    if(sessionStorage.length == 0){
    }else{
    Axios.post('https://react.vitruvio.ca/phpreact/all-provider.php',{
      id:sessionStorage.getItem('userData'),
      accesstoken:sessionStorage.getItem('accesstoken'),
    })
    .then(response => {
      console.log(response);
      this.setState({
        posts: response.data.users,
        isLoading: false
      });
    })
    .then(data => {
      if (data.success == 0) 
      {
        alert(data.data);
        this.logout();
      }
    })
    .catch(error => this.setState({ error, isLoading: false }));
  }
  }

  // Let's our app know we're ready to render the data
  componentDidMount() {
    this.getPosts();
  }
  
  render() {
    if (sessionStorage.getItem('userData') >= 0 && sessionStorage.getItem('usertype') == 2  ){
    }else {
      return (<Redirect to={'/'}/>)
    }
    const { isLoading, posts } = this.state;
    return (
      <React.Fragment>
        <div class="container-fluid conspadd">
          {!isLoading ? (
            posts.map(post => {
              // const { _id, title, content } = post;
              const { cid, name,authentication,vitruvio_code, email,address,city,state,postcode,phone,proxy,fax ,about,what,how1,description,type,freelimit,logo} = post;         
              return (
                <div class="container-fluid aboutclass conspadd">
                  <a href={'/Providerviewself/' + sessionStorage.getItem('userData')} class="btn float-left" id="btncolor">View My Profile Page</a> 
                  <button type="submit"  className="btn btn-danger float-right"   onClick={() => this.handleDelete(sessionStorage.getItem('userData'))} >Delete your Profile</button>
                  <form class="formPatient" onSubmit={this.updatetProvider} style={{marginTop:"10px"}}>
                    <h1>Provider Profile</h1>
                    <img src={logo} alt="profile image" className="img-fluid" width="220px" height="100px" style={{marginTop:"25px",marginBottom:"25px"}}/>
                    <div className="form-group" style={{textAlign:"left"}}>
                      <input type="file" className="form-control " onChange={this.onChange} />
                    </div>
                    <div className="form-group ">
                      <button type="submit" className="btn" id="btncolor">Add</button>
                    </div> 
                    <div className="form-group" style={{textAlign:"left"}} >
                      <label>Name</label>
                      <input type="text" className="form-control " readOnly defaultValue={name} placeholder="Enter Name"  ref={(val)=>this.Name=val} />
                    </div>
                    <div className="form-group" style={{textAlign:"left"}} >
                      <label>Email</label>
                      <input type="email" className="form-control " readOnly  defaultValue={email} placeholder="Enter Email ID" ref={(val)=>this.Email=val} />
                    </div>
                    <div className="form-group" style={{textAlign:"left"}} >
                      <label>Phone</label>
                      <input type="number" className="form-control " readOnly  placeholder="Enter Phone Number" defaultValue={phone} ref={(val)=>this.Phone=val} />
                    </div>
                    <div className="form-group" style={{textAlign:"left"}} >
                      <label>Fax</label>
                      <input type="number" className="form-control " placeholder="Enter Fax Number" defaultValue={fax} ref={(val)=>this.Fax=val} />
                    </div>
                    <div className="form-group" style={{textAlign:"left"}} >
                      <label for="sel1">Type of provider</label>
                      <select class="form-control" id="sel1"  defaultValue={type} ref={(val)=>this.Type=val}>
                        <option>Pharmacy</option>
                      </select>  
                    </div>  
                    <div className="form-group" style={{textAlign:"left"}} >
                      <label>Address</label>
                      <input type="text" className="form-control " placeholder="Enter Address" defaultValue={address} ref={(val)=>this.Address=val}  required/>
                    </div>
                    <div className="form-group" style={{textAlign:"left"}} >
                        <label>City</label>
                        <input type="text" className="form-control " placeholder="Enter City" defaultValue={city} ref={(val)=>this.City=val} />
                    </div>
                    <div className="form-group" style={{textAlign:"left"}} >
                      <label>Province</label>
                      <input type="text" className="form-control " placeholder="Enter Province " defaultValue={state} ref={(val)=>this.State=val} />
                    </div>
                    <div className="form-group" style={{textAlign:"left"}} >
                      <label>Postcode</label>
                      <input type="text" className="form-control " placeholder="Enter Postcode" defaultValue={postcode} ref={(val)=>this.Postcode=val} />
                    </div>
                    <div className="form-group" style={{textAlign:"left"}} >
                      <label>Accreditation Number</label>
                      <input type="text" className="form-control " readOnly  placeholder="Accreditation Number" defaultValue={authentication} ref={(val)=>this.Authentication=val} />
                    </div>
                    <div className="form-group" style={{textAlign:"left"}} >
                      <label>About Your Pharmacy</label>
                      <textarea class="form-control abouttext" rows="5" id="about" maxlength="255" defaultValue={about} ref={(val)=>this.about=val} placeholder='' /><span id="rchars">255</span> Character(s) Remaining 
                    </div>
                    <div className="form-group" style={{textAlign:"left"}} >
                      <div style={{paddingBottom:"20px"}}>   
                        <label>What does accessible healthcare mean to you?</label>
                        <textarea class="form-control accesstext" rows="5" id="comment" maxlength="255"   defaultValue={what} ref={(val)=>this.What=val}  />
                        <span id="rchars1">255</span> Character(s) Remaining
                      </div>  
                      <label>How will you commit to making your service accessible?</label>
                      <textarea class="form-control committext" rows="5" id="comment1" maxlength="255"  defaultValue={how1} ref={(val)=>this.how1=val}  />
                      <span id="rchars2">255</span> Character(s) Remaining  
                    </div>
                    <div className="form-group" style={{textAlign:"left"}} >
                      <label>We will provide 1 free prescription for every</label>
                      <input type="number" className="form-control "   defaultValue={freelimit} ref={(val)=>this.freelimit=val} />
                      <label>Prescription(s)</label>
                    </div>
                    &nbsp;&nbsp;  
                    <button type="submit" className="btn" id="btncolor1">Update Information</button>&nbsp;
                  </form>
                </div>
              );
            })
          ) : (
            <p>Loading...</p>
          )}
        </div>
      </React.Fragment>
    );
  }
}
export default Providerprofile;