import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Redirect} from 'react-router-dom';

import {  MDBDataTable ,CardBody,Card } from 'mdbreact';
import Axios from 'axios';
import Table from 'react-bootstrap/Table'
 
class Adminview extends Component {
   constructor(props) {
    super(props);
    this.state= {
      posts: [],
      isLoading:true,
      tableRows: [],
    };
  }

  logout  = () => {
    sessionStorage.clear();
    window.location.reload(false);
    this.props.history.push('/');
  }

  // Function Returns Provider Lists
  componentWillMount=async() => {
    // await Axios.get(url)
    if(sessionStorage.length == 0){
    }else{
      Axios.post('https://react.vitruvio.ca/phpreact/allproviderslist.php',{
        id:sessionStorage.getItem('userData'),
        email:sessionStorage.getItem('userData'),
        accesstoken:sessionStorage.getItem('accesstoken'),
      })
      .then(response => response.data)
      .then(data => {
        console.log(data.data);
        if(data.success == 3){
          this.props.history.push('/');
        }else if (data.success == 0) 
        {
          alert(data.data);
          this.logout();
        }
         // alert(data.data);
         // if (err) throw err;
         this.setState({ posts: data.data })
      })
      .then(async() => {
         this.setState({ tableRows:this.assemblePosts(), isLoading:false })
         // console.log(this.state.tableRows);
      });
    }
  }

  //Function to Update Info of Service Provider
  handleUpdate = (id,action) => {
      Axios.post('https://react.vitruvio.ca/phpreact/providerservice_update.php',{
        id:id,
        action:action,
        // accesstoken:sessionStorage.getItem('accesstoken'),
      })
      .then(({data}) => {
          
          if(data.success === 1){
              alert(data.msg);
              window.location.reload(false);
          }
          // else if (data.success == 0) 
          // {
          //   alert(data.data);
          //   this.logout();
          // }
          else{
              alert(data.msg);
          }
      })
      .catch(error => {
          console.log(error);
      });
    }

  //Function to Toggle Provider Active or Inactive and Pass on to Update it in DB
  assemblePosts= () => {
    let posts =this.state.posts.map((post) => {
      if(post.status == 'InActive'){
        var arra = 'Active';
      }else if (post.status == 'Active'){
        var arra = 'InActive';
      }
      return (
        {
          bid: post.pid,
          upload: (<a href={post.logo} target="_blank">View File</a>),
          authentication: post.authentication,
          Vitruvio_num: post.vitruvio_code,
          name: post.name,
          email: post.email,
          address:post.address,
          postcode:post.postcode,
          city:post.city,
          state:post.state,         
          date: post.timestamp,
          expiry: post.renewal,  
          reason: post.reason,
          status: post.status,
          // arra:post.arra,
          action:( <a onClick={() => this.handleUpdate(post.pid,post.status)} className="btn btn-primary">{arra}</a> 
             ),
        }
      )
    });
    return posts;
  }

  //Components Render
  render() {
    const data = {
      columns: [
        {
         label: 'OrderID',
          field: 'bid',
        },
        {
          label: 'Image',
          field: 'upload',
        },
        {
          label: 'Authentication Number',
          field: 'authentication',
        },
        {
          label: 'Vitruvio reg Number',
          field: 'Vitruvio_num',
        },
        {
          label: 'Provider status',
          field: 'name',
        }, 
        {
          label: 'Email',
          field: 'email',
        },
        {
          label:'Address',
          field:'address',
        },
        {
          label:'City',
          field:'city',
        },
        {
          label:'State',
          field:'state',
        },
        {
          label:'Postal Codes',
          field:'postcode',
        },
        {
          label: 'Date',
          field: 'date',
        }, 
        {
          label: 'Expiry',
          field: 'expiry',
        }, 
        {
          label: 'Status',
          field: 'status',
        },
        {
          label: 'Action',
          field: 'action',
        }, 
      ],
      rows:this.state.tableRows,
    }
    //Check if User is Logged in eles redirect out
    if (sessionStorage.getItem('userData') >= 0 && sessionStorage.getItem('usertype') == 3){

    }else{return (<Redirect to={'/'}/>)}
    
    //Datatable Display
    return (
      <div class="container-fluid cardclass">
        <h1>Provider  List</h1>
        <MDBDataTable responsiveSm responsive striped bordered hover data={data}/>
      </div>
    )
  }
}
export default Adminview;