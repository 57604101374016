import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Redirect} from 'react-router-dom';
import Axios from 'axios';
import $ from "jquery";

class Supportpharmacy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
      isLoading: true,
      file:null,
      errors: null
    };     
  }
  // Get Provider Data
  getPosts() {
    if(sessionStorage.length == 0){
    }else{
    Axios.post('https://react.vitruvio.ca/phpreact/all-provider.php',{
      // id:sessionStorage.getItem('userData'),
      id:this.props.match.params.pval,
      accesstoken:sessionStorage.getItem('accesstoken'),
    })
    .then(response => {
      console.log(response);
      this.setState({
        posts: response.data.users,
        isLoading: false
      });
    })
    .then(data => {
      if (data.success == 0) 
      {
        alert(data.data);
        this.logout();
      }
    })
    .catch(error => this.setState({ error, isLoading: false }));
  }
  }
  // Let's our app know we're ready to render the data
  componentDidMount() {
    this.getPosts();
  }
   render(){
    if (sessionStorage.getItem('userData') >= 0 && sessionStorage.getItem('usertype') == 2  ){
    }else {
      return (<Redirect to={'/'}/>)
    }
    const { isLoading, posts } = this.state;
    return (
      <React.Fragment>
        <div class="container-fluid conspadd">
        {!isLoading ? (
          posts.map(post => {
            // const { _id, title, content } = post;
            const { cid, name,authentication,vitruvio_code, email,address,city,state,postcode,phone,proxy,fax ,about,what,how1,description,type,freelimit,logo} = post;         
        return (
          <div class="container-fluid aboutclass">
            <form class="formPatient" onSubmit={this.insertUser}>
              <h2>Pharmacy Support</h2>
              <div className="row">
                <div className="col-md-3"></div>
                <div className="form-group col-md-6 " style={{textAlign:"left"}}>
                  <label>Pharmacy Name</label><span class="required">*</span>
                  <input type="text" className="form-control " defaultValue={name} placeholder="Enter Name" ref={(val)=>this.Name=val} required />
                </div>  
              </div>
              <div className="row">
                <div className="col-md-3"></div>
                <div className="form-group col-md-6 " style={{textAlign:"left"}}>
                  <label>Phone</label><span class="required">*</span>
                  <input type="number" className="form-control " defaultValue={phone} placeholder="Enter Phone " ref={(val)=>this.Phone=val}  requierd/>
                </div>  
              </div>
              <div className="row">
                <div className="col-md-3"></div>
                <div className="form-group col-md-6 " style={{textAlign:"left"}}>
                  <label>Email</label><span class="required">*</span>  
                  <input type="email" className="form-control " defaultValue={email} placeholder="Enter Email ID" ref={(val)=>this.Email=val} requierd/>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3"></div>
                <div className="form-group col-md-6" style={{textAlign:"left"}}>
                  <label>Order Number</label>
                  <input type="text" className="form-control " placeholder="Enter Order Number" ref={(val)=>this.Password=val}  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-3"></div>
                <div className="form-group col-md-6 " style={{textAlign:"left"}}>
                  <label>Concern or Issue</label><span class="required">*</span>
                  <textarea class="form-control" rows="5" id="comment1"  ref={(val)=>this.how1=val}  required/>
                </div>
              </div>
              <button type="submit" className="btn" id="btncolor">Submit to Vitruvio</button>&nbsp;
            </form>
          </div>
          );
        })
      ) : (
        <p>Loading...</p>
      )}
    </div>
      </React.Fragment>
    );
  }
}
 
export default Supportpharmacy;