import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Redirect} from 'react-router-dom';
import $ from "jquery";
import {  MDBDataTable, Row, Col, Card, CardBody  } from 'mdbreact';
import Home from './Home';
import Axios from 'axios';
import {AppContext} from './Context';
class Providerteam extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
      users:[],
      isLoading: true,
      file:null,
      errors: null
    };
    this.onChange = this.onChange.bind(this)
    // this.uploadFile = this.uploadFile.bind(this)  
  }
  logout  = () => {
    sessionStorage.clear();
    window.location.reload(false);
    this.props.history.push('/');
  }

  componentDidMount(){
    $(".dataTables_filter input").attr("placeholder", "Search Team Member");
    //console.log("second here");
  }

  // Update Provider Team
  updatetProvider=(event)=>{
    // window.location.reload(false);
    event.preventDefault();
    // let res =  this.uploadFile(this.state.file);
    // console.log(res.data);
    event.persist();
    const formData = new FormData();
    formData.append('avatar',this.state.file);
    formData.append('user',sessionStorage.getItem('userData'));
    formData.append('name',this.Name.value);
    formData.append('title',this.Title.value);
    formData.append('id',sessionStorage.getItem('userData'));
    formData.append('accesstoken',sessionStorage.getItem('accesstoken'));
    Axios.post('https://react.vitruvio.ca/phpreact/providerteam.php',formData,{
      headers: {
        'content-type': 'multipart/form-data'
      },
      accesstoken:sessionStorage.getItem('accesstoken'),
    })
    .then(function ({data}) {
      if(data.success == 3) 
      {
        alert(data.data);
        this.logout();
      }
      else if(data.success == 1){
        event.target.reset();
        alert(data.msg);
        window.location.reload(false);
        // this.props.history.push("patients");
      }else{
        alert(data.msg);
      }
    }.bind(this))
    .catch(function (error) {
      // console.log(error);
    });
  }

  onChange(e) {
    this.setState({file:e.target.files[0]})
  }

  // File Upload Function
  // async uploadFile(file){
  //   const formData = new FormData();
  //   formData.append('avatar',file)
  //   formData.append('user',sessionStorage.getItem('userData'))
  //   formData.append('name',this.Name.value)
  //   formData.append('title',this.Title.value)
  //   return  await Axios.post('https://react.vitruvio.ca/phpreact/providerteam.php', formData,{
  //     headers: {
  //       'content-type': 'multipart/form-data'
  //     }
  //   });
  // }

  // Pharmacy Team Display Function
  componentWillMount=async() => {
    // await Axios.get(url)
    if(sessionStorage.length == 0){
    }else{
    Axios.post('https://react.vitruvio.ca/phpreact/pharmacyteam.php',{
      id:sessionStorage.getItem('userData'),  
      accesstoken:sessionStorage.getItem('accesstoken'),  
    })
    .then(response => response.data)
    .then(data => {
      if(data.success == 3) 
      {
        alert(data.data);
        this.logout();
      }
      console.log(data.data);
      // if (err) throw err;
      this.setState({ posts: data.data })
    })
    .then(async() => {
      this.setState({ tableRows:this.assemblePosts(), isLoading:false })
      // console.log(this.state.tableRows);
    });
  }
  }

  // Provider Team Delete
  handleDelete = (id) => {
    Axios.post('https://react.vitruvio.ca/phpreact/providerteam-delete.php',{
      id:id,
      pid:sessionStorage.getItem('userData'),  
      accesstoken:sessionStorage.getItem('accesstoken'),
    })
    .then(({data}) => {
      if (data.success == 3) 
        {
          alert(data.data);
          this.logout();
        }
      else if(data.success === 1){
        alert(data.msg);
        window.location.reload(false);
      }else{
        alert(data.msg);
      }
    })
    .catch(error => {
      console.log(error);
    });
  }

  assemblePosts= () => {
    let posts =this.state.posts.map((post) => {
      return (
        {
          name: post.name,
          title: post.title,
          image: (<img src={post.photo} class="img-fluid" width="100px" height="50px"/> ),
          // action:(<a href={'/Providerview?pval=' + post.tid}  defaultvalue={post.pid}>Delete member </a>),
          action:( <button onClick={() => this.handleDelete(post.tid)} className="btn btn-danger">Delete</button>),
        }
      )
    });
    return posts;
  }

  render() {
    const data = {
      columns: [
        // {
        //   label:'#',
        //   field:'number',
        // },
        {
          label: 'Name',
          field: 'name',
        },
        {
          label: 'Title',
          field: 'title',
        },
        {
          label: 'Image',
          field: 'image',
        }, 
        {
          label: 'Action ',
          field: 'action',
        },
      ],
      rows:this.state.tableRows,
    }
    if (sessionStorage.getItem('userData') >= 0 && sessionStorage.getItem('usertype') == 2  ){
    }else {
      return (<Redirect to={'/'}/>)
    }
    return (
      <div class="container-fluid conspadd">
        <form className="formPatient" onSubmit={this.updatetProvider} style={{marginTop:"120px"}} >
          <h1>Provider Team</h1>
          <Card>
            <center><h1> Team Member's </h1></center>
              <CardBody>
                <MDBDataTable responsiveSm striped bordered hover data={data} />
              </CardBody>
          </Card>
          <div className="form-group" style={{textAlign:"left"}}>
            <h3><strong>Add Team Member</strong></h3>
            <label>Name</label>
            <input type="text" className="form-control "  placeholder="Enter Name"  ref={(val)=>this.Name=val} required />
          </div>
          <div className="form-group" style={{textAlign:"left"}}>
            <label>Title</label>
            <input type="text" className="form-control "  placeholder="Enter Title"  ref={(val)=>this.Title=val} required />
          </div>
          <div className="form-group" style={{textAlign:"left"}}>
            <label>Image</label>
            <input type="file" className="form-control " onChange={this.onChange} />
          </div>
          &nbsp;&nbsp;  
          <button type="submit" className="btn" id="btncolor">Add</button> &nbsp;
        </form>  
      </div>
    );
  }
}
export default Providerteam;