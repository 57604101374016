import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Style.css';
import { Helmet } from 'react-helmet';
const About = () => {
    return (
      <div id="segmentstyle">
        <section className="container-fluid bg-overlay">
            <div className="row text-center">
              <h1 id="h1style2">ABOUT US</h1>
            </div>
        </section>
        <div className="container-fluid " >
          <Helmet>
            <title>Vitruvio - About</title>
          </Helmet>
          <div className="row" id="divrow">
            <div className="col-xs-6 col-md-6">
            </div>
            <div className="col-md-6 align"></div>
          </div>  
          <div className="row">
            <div className="col-xs-6 col-md-6" id="clin">
              <img
                src={require('../images/clinton1.jpg')}
                height="500px"
                width="350px"
                className="img-fluid img1 align-top"
                alt="React Bootstrap logo"
              />
              <div className="clinborder">
                <div className="elementor-testimonial-details">
                  <div className="elementor-testimonial-name">Clinton Baretto NP (Ont)</div>
                  <div className="elementor-testimonial-job">Founder</div>
                </div>
              </div>
            </div>    
            <div className="col-xs-6 col-md-6 colpad">
              <h2 id="h2style">How we started</h2>
              <p id="p">Vitruvio was founded on a simple premise: to get medications for my patients who don’t have access to a health benefit program. Entrepreneurs, part time workers, gig economy workers, people forced into early retirement who are too young for the government insurance plans – how could I help my patients?</p>
              <p id="p">I am a Nurse Practitioner in family practice. I also volunteer as the primary care clinician for a mobile clinic serving individuals who are homeless or at risk of homelessness where I also teach medical residents. I have a small network of healthcare providers who are committed to making care available who have helped my patients over the years. But the need is bigger and their numbers are too few.</p>
              <p id="p">I started working on Vitruvio in 2016 and have slowly built it by squirrelling money away from my paycheck each month. I’m not an entrepreneur, just a clinician trying to help the most vulnerable patients in our care.</p>
              <p id="p">Vitruvio is the place where we can all help make care accessible for everyone.</p>
              <p id="p">I hope you will join us.</p>
            </div>
          </div>

          <section>
            <div className="row healthdiv1">
              <div className="col-xs-12 col-md-12 healthdiv">
                <img
                  src={require('../images/medicalstory.png')}
                  height="auto"
                  width="auto"
                  className="img-fluid"
                  alt="React Bootstrap logo"
                />
              </div>
              <div className="col-xs-12 col-md-12 row">
                <div className="col-md-2"></div>
                <div className="col-md-8">
                  <center><h2 id="h2style2">Health services that work for everyone</h2></center>
                    <p id="p1">There was a patient, a young man, who was stuck on a vicious cycle. He struggled with his mental health but when he took his medication, he was well. He could work and he WANTED to work, so he did. But the only jobs he could get were ones where there were no benefits and he would have to pay for his medications.</p>
                    <p id="p1">A large portion of his paycheque went to pay for his medication and he had to decide if he paid for his medications or for his food. He never went out partying, didn&rsquo;t smoke or drink, and didn&rsquo;t have the latest tech. All he could do with his paycheque was pay his rent and buy his medication. So he was stuck on the welfare system where his medications were covered but he couldn&rsquo;t work.</p>
                    <p id="p1">It wasn&rsquo;t until we found a generous pharmacist that would give him his medications for free that he was able to break the cycle and realize his full potential. He was so happy that he told everyone he met to use that pharmacy so that the pharmacist would be rewarded with more business&hellip; and THAT is where the concept of Vitruvio came from.</p>
                </div>
              </div>         
            </div>
          </section>

          <section>
            <div className="row" id="divrow2">
              <div className="divrow3 col-xs-7 col-md-7">
                <h2 id="h2chat">Do You Believe Healthcare
                Services Should Be More Accessible?</h2>
                <h3 id="chath3" >
                Sign up below and spread the word.
                </h3>
                <div className="tabclass">
                  <a href="#" className="btn fa fa-facebook" id="cb">&nbsp;&nbsp;Facebook</a>&nbsp;&nbsp;&nbsp;
                  <a href="#" className="btn fa fa-twitter" id="cb">&nbsp;&nbsp;&nbsp;Twitter</a>&nbsp;&nbsp;&nbsp;
                  <a href="#" className="btn fa fa-linkedin" id="cb">&nbsp;&nbsp;Linkedin</a>
                </div>
              </div>
              <div className="col-xs-1 col-md-1"></div>
              <div className="col-xs-4 col-md-4" id="chat">
                <img
                  src={require('../images/chat.png')}
                  width="800"
                  height="639"
                  sizes="(max-width:800px) 100vw,800px"
                  className="img-fluid"
                  alt="React Bootstrap logo"
                />
              </div>
            </div>
          </section>
      
      
          
          <div className="row signup">
            <div className="col-md-7">
              <h1 id="h1style1">SIGN UP NOW!</h1>
              <p id="parastyle5">
                Join our mailing list. Show healthcare providers how committed we
                are to making services accessible to people who cannot afford it and
                encourage them to join us.
              </p>
              <p id="parastyle6">
                *Remember to click on the verification link in your email after you
                sign up. (Check your “junk/spam” folder).
              </p>
            </div>
            <div className="col-md-5">
              <form className="subform">
                <div className="form-group" id="colorfield">
                  <label>Email</label>
                  <input
                    type="email"
                    className="form-control subfom_input"
                    placeholder="Please enter your email address"
                  />
                </div>
                <br />
                <div className="form-group " id="colorfield">
                  <label>Name</label>
                  <input
                    type="password"
                    className="form-control subfom_input"
                    placeholder="Please enter your first name"
                  />
                </div>
                <div id="textcolor">
                  {" "}
                  We Take your Privacy Seriously{" "}
                  <span id="spanstyle">
                    <strong>Terms of Use</strong>
                  </span>{" "}
                  and <span id="spanstyle">Privacy Policy.</span>{" "}
                </div>
                <center>
                  <button
                    type="submit"
                    className="btn btn-lg"
                    id="b5"
                  >
                    Send
                  </button>
                </center>
                &nbsp;
              </form>
            </div>
          </div>
        </div>
      </div>
     
    );
}
 
export default About;