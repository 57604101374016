import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
 
import Home from './components/Home';
import About from './components/About';
import learn from './components/learn-more';
import Error from './components/Error';
import Navigation from './components/Navigation';
import Patient from './components/Patients';
import Providers from './components/Providers';
import Adminlogin from './components/Adminlogin';
import Adminview from './components/Adminview';
import Admincustview from './components/Admincustview';
import Register from './components/Register';
import PatientsDashboard from './components/PatientsDashboard';
import Patientsprofile from './components/Patientsprofile';
import 'bootstrap/dist/css/bootstrap.min.css';
import Customerorders from './components/Customerorders';
import Customerbooking from './components/Customerbooking';
import Customerbookservice from './components/Customerbookservice';
import Bookappointment from './components/Bookappointment';
import Providerdashboard from './components/Providerdashboard';
import Providerprofile from './components/Providerprofile';
import Providerteam from './components/Providerteam';
import Providerorder from './components/Providerorders';
import ProviderRegister from './components/ProviderRegister';
import Providersubscription from './components/Providersubscription';
import Providerview from './components/Providerview';
import PaymentSuccess from './components/paymentsuccess';
import Paymentfail from './components/paymentfail';
import forgetpass from './components/forgetpass';
import forgetpassp from './components/forgetpassp';
import Passupdate from './components/Passupdate';
import Passupdatepro from './components/Passupdatepro';
import Home1 from './components/Home1';
import Contact from './components/Contact';
import Profileview from './components/Profileview';
import Providerviewself from './components/Providerviewself';
import Freeprespharmacy from './components/Freeprespharmacy';
import Favourite from './components/favourite';
import Otpverify from './components/Otpverify';
import Otpverifycust from './components/Otpverifycust';
import Feedback from './components/Feedback';
import PaymentPage from './components/PaymentPage';
import Pharmacyredirect from './components/Pharmacyredirect';
import Providerspaymentmethod from './components/Providerspaymentmethod';
import Patientsredirect from './components/Patientsredirect';
import Supportpharmacy from './components/Supportpharmacy';
import Supportpatient from './components/Supportpatient';
import Footer from './components/footer';
class App extends Component {
  render() {
    return (      
      <BrowserRouter>
        <div>
          <Navigation />
          <Switch>
            <Route path="/" component={Home} exact/>
            <Route path="/about" component={About}/>
            <Route path="/learn-more" component={learn}/>
            <Route path="/patients" component={Patient}/>
            <Route path="/providers" component={Providers}/>
            <Route path="/admin@123" component={Adminlogin}/>
            <Route path="/Adminview" component={Adminview}/>
            <Route path="/Admincustview" component={Admincustview}/>
            <Route path="/register" component={Register}/>
            <Route path="/PatientsDashboard" component={PatientsDashboard}/>
            <Route path="/Patientsprofile" component={Patientsprofile}/>
            <Route path="/Customerorders" component={Customerorders}/>
            <Route path="/Customerbooking" component={Customerbooking}/>
            <Route path="/all-pharmacies" component={Customerbookservice}/>
            <Route path="/Booksappointment" component={Bookappointment}/>
            <Route path="/Providerdashboard" component={Providerdashboard}/>
            <Route path="/Providerprofile" component={Providerprofile}/>
            <Route path="/Providerteam" component={Providerteam}/>
            <Route path="/Providerorders" component={Providerorder}/>
            <Route path="/Providersubscription" component={Providersubscription}/>
            <Route path="/ProviderRegister" component={ProviderRegister}/>
            <Route path="/Providerview/:pval" component={Providerview}/>
            <Route path="/paymentsuccess" component={PaymentSuccess}/>
            <Route path="/paymentfail" component={Paymentfail}/>
            <Route path="/forgetpass" component={forgetpass} />
            <Route path="/forgetpassp" component={forgetpassp} />
            <Route path="/Passupdate" component={Passupdate} />
            <Route path="/Passupdatepro" component={Passupdatepro} />
            <Route path="/contact" component={Contact} />
            <Route path="/home1" component={Home1} />
            <Route path="/Profileview" component={Profileview} />
            <Route path="/Providerviewself/:pval" component={Providerviewself} />
            <Route path="/Providerspaymentmethod" component={Providerspaymentmethod}/>
            <Route path="/Freeprespharmacy" component={Freeprespharmacy} />
            <Route path="/pharmacy-favourites" component={Favourite} />
            <Route path="/Otpverify" component={Otpverify} />
            <Route path="/Otpverifycust" component={Otpverifycust} />
            <Route path="/Feedback" component={Feedback} />
            <Route path="/PaymentPage" component={PaymentPage} />
            <Route path="/Pharmacyredirect" component={Pharmacyredirect} />
            <Route path="/Patientsredirect" component={Patientsredirect} />
            <Route path="/Supportpharmacy/:pval" component={Supportpharmacy} />
            <Route path="/Supportpatient" component={Supportpatient} />
            <Route component={Error}/>
          </Switch>
          <Footer />
        </div>
      </BrowserRouter>
    );
  }
}
 
export default App;