import React, { Component } from 'react';
import  {Doughnut ,Line}from "react-chartjs-2";
import 'bootstrap/dist/css/bootstrap.min.css';
import {Redirect} from 'react-router-dom';
import Axios from 'axios';
import { Button ,Navbar ,Form ,Nav ,FormControl ,} from 'react-bootstrap';
import {BarChart,ResponsiveContainer,Bar,CartesianGrid,XAxis,YAxis,Tooltip,Legend,Pie,PieChart,Cell} from 'recharts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
class Providerdashboard extends Component{
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
      isLoading: true,
      errors: null,    
    };  
  }
  
  // Logout User
  logout  =() => {
    sessionStorage.clear();
    this.props.history.push("/");
  }

  // Get Dashboard Data
  getPosts() {
    if(sessionStorage.length == 0){
    }else{
    Axios.post('https://react.vitruvio.ca/phpreact/providerdashboarddata.php',{
      id:sessionStorage.getItem('userData'),
      accesstoken:sessionStorage.getItem('accesstoken'),
    })

    .then(data => {
      if (data.success == 0) 
        {
          alert(data.data);
          this.logout();
        }
    })
    .then(response => {
      console.log(response);
      this.setState({
        posts: response.data.msg,
        isLoading: false
      });
    })
    .catch(error => this.setState({ error, isLoading: false }));
  }
  }

  // Let's our app know we're ready to render the data
  componentDidMount() {
    this.getPosts();
  }

  COLORS = ['#0e4958', '#4d8a7c'];

  CustomTooltip = ({ active, payload, label }) => {
    if (active) {
      return (
        <div className="custom-tooltip" style={{ backgroundColor: '#ffff', padding: '5px', border: '1px solid #cccc' }}>
          {/* <label>{`${payload[0].name} : ${payload[0].value}`}</label> */}
          <label>{`${payload[0].name}`}</label>
        </div>
      );
    }
    return null;
  };
 
  render(){
    if (sessionStorage.getItem('userData') >= 0 && sessionStorage.getItem('usertype') == 2  ){
    }else {
      return (<Redirect to={'/'}/>)
    }
    const { isLoading, posts } = this.state;
      return (
      <React.Fragment>

        <div>
          {!isLoading ? (
            posts.map(post => {
              const { name, currentOrderMonth, totalDelivered,currentPlan, currentTotalOrderMonth,previousMonth, totalFreeDelivered, inProgress,twoMonthPrevious,totalMedsDelivered, currentMonth,requestedoverall ,threeMonthPrevious, current, previous, next, threemonth, maxProviderOrder, currentMonthOrder, medicationCount, freeFulfilledOrder, freeLimitOrder } = post;
              // const {freeorders,totalc,count ,currentmonthplan,freefullfil,completedorderforlifetime1,freelimit1,totalpres,calfreeorder4month,requestedoverall,pendingfreeorderall,totalorder,pendingorderoverall1,totalprescription,totalprescriptionall,name, remainorders,totalprescriptionalbeforepreviousmonth,totalprescrippreviousmonth,current,previous,next,totalprescriptionalbefore3month,threemonth,currentmonthordercomp} = post;
              var month3 = parseInt(threeMonthPrevious, 10)
              var month2 = parseInt(twoMonthPrevious, 10)
              var month1 = parseInt(previousMonth, 10)
              var currmonth = parseInt(currentMonthOrder, 10)
              // currentMonthOrder = parseInt(currentMonthOrder, 10)
              var inProgressOrders = parseInt(inProgress,10)
              // const add1=num1+num2+num3+num4;
              var totalMedCount = parseInt(medicationCount,10)
              var freeLimit = parseInt(freeLimitOrder,10)
              var totalDeliveredMeds = parseInt(totalDelivered,10)
              var maxNumberOrder = parseInt(maxProviderOrder,10)
              var freeProvidedOrder = parseInt(freeFulfilledOrder,10);
              var pendingOrders = parseInt(requestedoverall,10);
              var currentPlanValue = parseInt(currentPlan,10);
              var currentTotalOrderMonthValue = parseInt(currentTotalOrderMonth,10);
              const remainingOrderMonth = currentPlanValue- currentTotalOrderMonthValue;
              // console.log("completed orders:",completed1);
              //const res=((num5%num6)+num6)%num6;
              // const res3=num5 % num6;
              // const res1=num6-res3;
              const remaingOrder = maxNumberOrder - currentMonthOrder;
              const freeOrdersLeft = freeLimit - freeProvidedOrder;
              const freeMedsProgress = medicationCount % freeLimit;
              const freeCompletedMeds = freeLimit - freeMedsProgress;
              const freeCounter = totalDeliveredMeds / freeLimit;
              let remainingFreeOrders = totalMedsDelivered-(freeProvidedOrder*freeLimit);
              if(remainingFreeOrders >= freeLimit){
                remainingFreeOrders = freeLimit;
              }
              const remainigmeds = freeLimit - remainingFreeOrders;
              let freeMedsAvailable = Math.floor((totalMedsDelivered) / freeLimit);
              if(freeMedsAvailable < 0){
                freeMedsAvailable = 0;
              }
                const  pieData = [
                  {
                    "name": "Medication Completed: " + remainingFreeOrders,
                    "value": remainingFreeOrders,
                  },
                  {
                    "name": "Remaining Medication: " + remainigmeds,
                    "value": remainigmeds,
                  },
                  
              ];
              const pieData1 = [
                {
                  "name": "Orders Completed: " + currentTotalOrderMonthValue,
                  "value": currentTotalOrderMonthValue,
                },
                {
                  "name": "Remaining Orders: " + remainingOrderMonth,
                  "value": remainingOrderMonth,
                },
              ];
              const data = [
                {
                  "name": threemonth,
                  "Totalorder": month3,
                  "fill":"#0e4958"
                },
                {
                  "name": next,
                  "Totalorder": month2,
                  "fill":"#0e4958"
                },
                {
                  "name": previous,
                  "Totalorder": month1,
                  "fill":"#0e4958"
                },
                {
                  "name": current,
                  "Totalorder": currentOrderMonth,
                  "fill":"#0e4958"
                }
              ]
              const data01 = [
                {
                  "name": "Group A",
                  "value": 400,
                  "fill":"#0e4958"
                },
                {
                  "name": "Group B",
                  "value": 300,
                  "fill":"#4d8a7c"
                }
              ];  

              return (
                <div class="container-fluid aboutclass conspadd" style={{overflow:"hidden"}} id="dashpad">  
                  <h2><strong>Welcome  {name}</strong></h2>
                  <div className="row pt-3 pb-3">
                    <div class="col-md-3 ">
                      <div class="card p-3">
                        <h2 class="text-center">{currentOrderMonth}</h2>
                        <h4 class="text-center">Completed <br/> Orders</h4>      
                      </div>
                    </div>
                    <div class="col-md-3 ">
                      <div class="card p-3">
                        <h2 class="text-center">{inProgressOrders}</h2>
                        <h4 class="text-center">Orders in <br/>Progress</h4>
                      </div>
                    </div>
                    <div class="col-md-3 ">
                      <div class="card p-3">
                        <h2 class="text-center">{pendingOrders}</h2>
                        <h4 class="text-center">Pending <br/>Orders</h4>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="card p-2">
                        <h2 class="text-center" style={{marginTop:"10px",marginBottom:"10px"}}>{freeMedsAvailable}</h2>
                        <h4 class="text-center">Free Medication <br/> Available</h4>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div class="col-md-6">
                      <div class="card p-6" style={{paddingBottom:"84px",paddingRight:"40px"}}> 
                        <h4 class="text-center" style={{paddingBottom:"10px",paddingTop:"10px"}}>Completed Orders Summary</h4>
                        <ResponsiveContainer width="100%" height={300}>
                          <BarChart width={500} height={300} data={data}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="Totalorder" fill="#0e4958" />
                          </BarChart>
                        </ResponsiveContainer>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="card p-6 " >
                        <h4 className="text-center" style={{paddingTop:"10px"}}> Free Medication Progress</h4>
                        <ResponsiveContainer width="100%" height={300}>
                          <PieChart width={500} height={300}>
                            <Pie data={pieData} color="#000000" dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={120} fill="#8884d8" >
                                {
                                    pieData.map((entry, index) => <Cell key={`cell-${index}`} fill={this.COLORS[index % this.COLORS.length]} />)
                                }
                            </Pie>
                            <Tooltip content={<this.CustomTooltip />} />
                            <Legend />
                          </PieChart>
                        </ResponsiveContainer>
                        <p class="text-center"><strong>1 Free Medication for Every {freeLimitOrder} Medication</strong></p>
                      </div>
                    </div>

                    <Bar dataKey="Remaining Orders" fill="#4d8a7c" />
                  </div>
                  <div className="row mt-2 pt-3 pb-3">
                    <div className="col-md-5  col-xs-5 p-6">
                      <div className="card p-6 mb-3">
                        <h5 className="text-center" style={{paddingTop:"10px",paddingBottom:"10px",fontWeight:"bold"}}>Subscription Orders Limit</h5>
                        <ResponsiveContainer width="100%" height={320}>
                          <PieChart width={500} height={300}>
                            <Pie data={pieData1} color="#000000" dataKey="value" nameKey="name" cx="45%" cy="45%" outerRadius={120} fill="#8884d8" >
                              {
                                  pieData1.map((entry, index) => <Cell key={`cell-${index}`} fill={this.COLORS[index % this.COLORS.length]} />)
                              }
                            </Pie>
                            <Tooltip content={<this.CustomTooltip />} />
                            <Legend />
                          </PieChart>
                        </ResponsiveContainer>
                      </div>
                    </div>
                    <div className="col-md-3 col-xs-3">
                      <div className="card p-4">
                        <h5 style={{paddingTop:"5px",paddingBottom:"10px"}}><strong>Lifetime Statistics</strong></h5>
                        <div className="row">
                          <div className="col-md-4 col-xs-12">
                            <i class="fas fa-file-prescription fa-4x" style={{color:"#0e4958"}}></i>
                          </div> 
                          <div className="col-md-8 col-xs-12 tabtext1">
                            <h3  className="textsize" style={{color:"#0e4958"}}>{totalDeliveredMeds}</h3>
                            <h5 className="textsize">Completed Orders</h5>
                          </div>
                        </div>
                        <br/>
                        <div className="row">
                          <div className="col-md-4 col-xs-8">
                            <i class="fas fa-prescription-bottle fa-4x" style={{color:"#0e4958"}}> </i>
                          </div> 
                          <div className="col-md-8 col-xs-12 tabtext1">
                            <h3 className="textsize" style={{color:"#0e4958"}}>{freeFulfilledOrder}</h3>
                            <h5 className="textsize">Free Medication Given</h5>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-4 col-xs-8">
                            <i class="fas fa-capsules fa-4x" style={{color:"#0e4958"}}> </i>
                          </div> 
                          <div className="col-md-8 col-xs-12 tabtext1">
                            <h3 className="textsize" style={{color:"#0e4958"}}>{totalMedsDelivered}</h3>
                            <h5 className="textsize">Number of Medications given</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4  col-xs-4 text-center" >
                      <div className="card p-4">
                        <h5><strong>View Public Profile</strong> <br/>
                          <a href={'/Providerviewself/' + sessionStorage.getItem('userData')} class="btn" id="btncolor">View</a> 
                        </h5>
                      </div>
                      <div className="card p-4 mt-2 text-center">
                        <h5> <strong>Upgrade your Subscription to increase Your Order Limit</strong></h5>
                        <h5> <strong>Current Limit:{maxProviderOrder}<br/><a href={'/Providersubscription'} class="btn" id="btncolor">Upgrade</a> 
                        </strong> </h5>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <p>Loading...</p>
          )}
        </div>
      </React.Fragment>
    );
  }
}
export default Providerdashboard;