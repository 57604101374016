import React ,{Component} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Axios from 'axios';
import {AppContext} from './Context';
import  { Redirect } from 'react-router-dom'

class Forgetpass extends Component{
    static contextType=AppContext;
    
    //Password Reset Function
    passwordUser=(event)=>{
        event.preventDefault();
        event.persist();
        Axios.post('https://react.vitruvio.ca/phpreact/forgetpass.php',{
            email:this.Email.value,
        })
       .then(function ({data}) {
            if(data.success == 1){ 
               event.target.reset();
                this.props.history.push({
                    pathname: '/Passupdate',
                    search: '?query=abc',
                    customNameData:  data.msg
                })
            }
            else{
               alert("Invalid Credential!");
            }
        }.bind(this))
        .catch(function (error) {
        // console.log(error);
        });
    }

    //Render Components
    render(){
        return (
            <div className="container-fluid aboutclass">
                <form class="formPatient"  onSubmit={this.passwordUser}>
                    <h3 lass="formh2">Forget Password </h3>
                
                    <div className="form-group " style={{textAlign:"left"}}>
                        <label>Email</label><span class="required">*</span>
                        <input type="email" className="form-control " placeholder="Enter Email ID" ref={(val)=>this.Email=val}  required/>
                    </div>
                    <button type="submit" className="btn btn-success"> Reset </button>&nbsp;
                </form>
            </div>
        );
    }
}

export default Forgetpass;