import React ,{Component} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Axios from 'axios';
import {AppContext} from './Context';
import { Helmet } from 'react-helmet';
import {Redirect} from 'react-router-dom';
class Patients extends Component{
  static contextType=AppContext;

  // Login Function
  validateUser=(event)=>{
    event.preventDefault();
    event.persist();
    Axios.post('https://react.vitruvio.ca/phpreact/login.php',{
      email:this.Email.value,
      Password:this.Password.value,
    })
    .then(function ({data}) {
      if(data.success == 1){
        event.target.reset();
        // alert(data.msg);
        sessionStorage.setItem('userData',data.id);
        sessionStorage.setItem('usertype',data.type);
        window.location.reload(false);
        sessionStorage.setItem('accesstoken',data.accesstoken);
        this.props.history.push("PatientsDashboard");
        // alert(data.accesstoken);
      }else{
        alert(data.msg);
      }
    }.bind(this))
    .catch(function (error) {
       // console.log(error); 
    });
  }
  render(){
    // Check if User is Logged in and User Type 
    if (sessionStorage.getItem('userData') >= 0 && sessionStorage.getItem('usertype') == 1  ){
      return (<Redirect to={'/PatientsDashboard'}/>)
    }else {}
    if (sessionStorage.getItem('userData') >= 0 && sessionStorage.getItem('usertype') ==2  ){
      return (<Redirect to={'/PatientsDashboard'}/>)
    }

    return (
      <div id="segmentstyle">
        <section className="container-fluid bg-overlay3">
          <div className="row text-center">
            <h1 id="h1style2">PATIENT LOGIN</h1>
          </div>
        </section>
        <Helmet>
          <title>Vitruvio - Patients Login</title>
        </Helmet>
        <form className="formPatient" onSubmit={this.validateUser} >
          <h2 className="formh2">Login into Your Patient Account</h2>
          <div className="row">
            <div className="col-md-3"></div>
            <div className="forminput col-md-6" style={{textAlign:"left"}}>
              <label>Username</label><span className="required">*</span>
              <input type="email" className="form-control " placeholder="Username or Email Address"  ref={(val)=>this.Email=val} required />
            </div>
          </div>
          <div className="row">
            <div className="col-md-3"></div>
            <div className="forminput col-md-6" style={{textAlign:"left"}}>
              <label>Password</label><span className="required">*</span>
              <input type="password" className="form-control" placeholder="Password"  ref={(val)=>this.Password=val} required />
            </div>
          </div>
          <button type="submit" className="btn" id="btncolor">Login</button>&nbsp;
          <h6><a href="/forgetpass" className="btn">Forgot your password?</a> |  <a href="/register" className="btn" >Create New Account</a></h6>
        </form>

        <section>
          <div className="row" id="divrow2">
            <div className="divrow3 col-xs-7 col-md-7">
              <h2 id="h2chat">Do You Believe Healthcare
              Services Should Be More Accessible?</h2>
              <h3 id="chath3" >
              Sign up below and spread the word.
              </h3>
              <div className="tabclass">
                <a href="#" className="btn fa fa-facebook" id="cb">&nbsp;&nbsp;Facebook</a>&nbsp;&nbsp;&nbsp;
                <a href="#" className="btn fa fa-twitter" id="cb">&nbsp;&nbsp;&nbsp;Twitter</a>&nbsp;&nbsp;&nbsp;
                <a href="#" className="btn fa fa-linkedin" id="cb">&nbsp;&nbsp;Linkedin</a>
              </div>
            </div>
            <div className="col-xs-1 col-md-1"></div>
            <div className="col-xs-4 col-md-4" id="chat">
              <img
                src={require('../images/chat.png')}
                width="800"
                height="639"
                sizes="(max-width:800px) 100vw,800px"
                className="img-fluid"
                alt="React Bootstrap logo"

              />
            </div>
          </div>
        </section>
      </div>      
    );
  }
}
export default Patients;