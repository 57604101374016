import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Segment } from "semantic-ui-react";
import { Helmet } from "react-helmet";
import { Button } from "react-bootstrap";
import "font-awesome/css/font-awesome.min.css";
import "@fortawesome/fontawesome-free/css/fontawesome.min.css";
import "../Style.css";


import { Redirect } from "react-router-dom";
  // Check for User Type and Login
  const home = () => {
  //   if (
  //     sessionStorage.getItem("userData") >= 0 &&
  //     sessionStorage.getItem("usertype") == 1
  //   ){
  //     return <Redirect to={"/PatientsDashboard"} />;
  //   } else {}

  return (
    <Segment id="segmentstyle">
      <Helmet>
        <title>Vitruvio - Changing Healthcare in Canada</title>
        <link rel="icon" type="image/png" href="logo.png" sizes="16x16" />
      </Helmet>

      <section className="elementor-element home1">
       <div className="container-fluid container-sm ">
          <div className="headbackimg"></div>
          <div className="row" id="homestyle">
            <div className="col-xs-6 col-md-6  order-md-2 ">
              <img
                src={require("../images/bannar-img-2.png")}
                width="auto"
                height="450px"
                className="img-thumbnail homeimg "
                alt="React Bootstrap logo"
                id="img1"
              />
            </div>
            <div className="committed col-xs-6 col-md-6 order-md-1">
              <h1 className="headerclass">
                COMMITTED TO ACCESSIBLE HEALTH
              </h1>
              <p id="parastyle">
                A new way for patients to choose healthcare providers enabling
                people without benefits to get services for free
              </p>
              <Button className="mr-2" id="b1" href="/learn-more">LEARN MORE ></Button>
            </div>
          </div>
        </div>
      </section>

      <section className="Home-health">
        <div className="container">
          <p id="parastyle2">
            VITRUVIO IS THE SOLUTION TO{" "}
          </p>
          <div className="row">
            <div className="col-xs-6 col-md-6">
              <h1 className="wow fadeInUp" id="h1color">
                THE HEALTHCARE AFFORDABILITY CRISIS
              </h1>
              <Button className="mr-2" id="b4" href="/learn-more">LEARN MORE ></Button>
            </div>
            <div className=" col-xs-6 col-md-6">
              <p id="parastyle3" >
                The fact is that many honest and hard working Canadians don’t
                have medical benefits. Vitruvio allows people with benefits to
                help those without at no cost.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div id="works">
          <h1 id="h1style">
            {" "}
            HOW IT WORKS{" "}
          </h1>
          <p id="parastyle4" >
            We all have a role to play in making our healthcare system work for
            everyone
          </p>
        </div>
      </section>
      <div className="desktopview">
        <div className="row">
          <div className="col-sm-6 order-md-2 div1">
            <img
              src={require("../images/img3.jpg")}
              className="d-inline-block align-top"
              alt="React Bootstrap logo"
              id="img2"
            />
          </div>

          <div className="col-sm-6  order-md-1" id="pic1">
            <div className="row">
              <div className="col-sm-2">
                {" "}
                <i
                  className="fa fa-angle-double-right fa-4x"
                  aria-hidden="true"
                  id="iconstyle"
                  style={{padding:"18px",paddingLeft:"38px"}}
                ></i>
              </div>
              <div className="col-sm-10">
                {" "}
                <p id="text1" className="center">
                  One of the pharmacies in your community commits to accessible
                  health by registering with Vitruvio. They have set a goal to
                  reach in order to be able to give a prescription away for free.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 div1">
            <img
              src={require("../images/img2.jpg")}
              id="img2"
              className="d-inline-block align-top"
              alt="React Bootstrap logo"
            />
          </div>
          <div className="col-sm-6" id="pic1">
            <div className="row">
              <div className="col-sm-2">
                {" "}
                <i
                  className="fa fa-angle-double-right fa-4x"
                  aria-hidden="true"
                    id="iconstyle"
                    style={{padding:"18px",paddingLeft:"38px"}}
                ></i>
              </div>
              <div className="col-sm-10">
                {" "}
                <p id="text1" className="center">
                  You and your family choose this pharmacy because you want to
                  help make healthcare accessible. You submit your prescription to
                  this pharmacy to help them reach their target.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-6 order-md-2 div1">
            <img
              src={require("../images/img4.jpg")}
              id="img2"
              className="d-inline-block align-top"
              alt="React Bootstrap logo"
              
            />
          </div>

          <div className="col-sm-6  order-md-1" id="pic1">
            <div className="row">
              <div className="col-sm-2">
                {" "}
                <i
                  className="fa fa-angle-double-right fa-4x"
                  aria-hidden="true"
                  id="iconstyle"
                  style={{padding:"18px",paddingLeft:"38px"}}
                ></i>
              </div>
              <div className="col-sm-10">
                {" "}
                <p id="text1" className="center">
                  Your neighbour needs to fill a prescription but doesn't have any
                  benefits and can't afford it. They see that the pharmacy has
                  achieved its target and has a free prescription available for
                  them. They submit their prescription and get their medicine for
                  free.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-6 div1">
            <img
              src={require("../images/img5.jpg")}
              id="img2"
              className="d-inline-block align-top"
              alt="React Bootstrap logo"
            />
          </div>
          <div className="col-sm-6" id="pic1">
            <div className="row">
              <div className="col-sm-2">
                {" "}
                <i
                  className="fa fa-angle-double-right fa-4x"
                  aria-hidden="true"
                  id="iconstyle"
                  style={{padding:"18px",paddingLeft:"38px"}}
                ></i>
              </div>
              <div className="col-sm-10">
                {" "}
                <p id="text1" className="center">
                  Your choice to use this pharmacy has supported them and allowed
                  them to make their service more accessible to your neighbour who
                  can't afford their medicines.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="mobiletab">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="wrimagecard wrimagecard-topimage">
                <a href="#">
                  <div className="wrimagecard-topimage_header" >
                    <center><i className = "fas fa-user-md" ></i></center>
                  </div>
                  <p id="mobtext">
                  One of the pharmacies in your community commits to accessible
                  health by registering with Vitruvio. They have set a goal to
                  reach in order to be able to give a prescription away for free.
                  </p>  
                </a>
              </div>
            </div>

            <div className="col-md-6 col-sm-6">
              <div className="wrimagecard wrimagecard-topimage">
                <a href="#">
                  <div className="wrimagecard-topimage_header" >
                    <center>
                      <i className = "fas fa-users" ></i>
                    </center>
                  </div>
          
                  <p id="mobtext">
                  You and your family choose this pharmacy because you want to
                  help make healthcare accessible. You submit your prescription to
                  this pharmacy to help them reach their target.
                  </p>
                </a>
              </div>
            </div>

            <div className="col-md-6 col-sm-6">
              <div className="wrimagecard wrimagecard-topimage">
                <a href="#">
                  <div className="wrimagecard-topimage_header" >
                    <center>
                      <i className = "fas fa-users" ></i>
                    </center>
                  </div>
          
                  <p id="mobtext">
                    Your neighbour needs to fill a prescription but doesn't have any
                    benefits and can't afford it. They see that the pharmacy has
                    achieved its target and has a free prescription available for
                    them. They submit their prescription and get their medicine for
                    free.
                  </p>
   
                </a>
              </div>
            </div>

            <div className="col-md-6 col-sm-6">
              <div className="wrimagecard wrimagecard-topimage" style={{padding:"30px"}}>
                <a href="#">
                  <div className="wrimagecard-topimage_header" >
                    <center>
                      <i className = "fas fa-users" ></i>
                    </center>
                  </div>
          
                  <p id="mobtext" >
                    Your choice to use this pharmacy has supported them and allowed
                    them to make their service more accessible to your neighbour who
                    can't afford their medicines.
                  </p>
                </a>
              </div>
            </div>
          </div>     
        </div>
      </section>
      
      <div className="row maincard">
        <div className="row  healthcare">
          
          <div className="card col-md-5 carddiv">
            <div className="card-body">
              <h5 className="card-title" id="h5">
                <center> 
                  <div className="col-md-4" id="cardstyle">
                    <i className="fas fa-user-md fa-3x"
                    aria-hidden="true" id="iconstyle1"></i>
                  </div>
                </center>
                Healthcare Providers
              </h5>
              <hr></hr>
              <p className="card-text" id="ct1">
                We know you care about your patients and do everything you can to
                make your products and services available to those who struggle to
                afford it. Sliding scale payment, free medications, pro bono
                services – we know you do all of it.
              </p>
              <p id="ct1">
                We believe you should be recognized for the efforts you make to
                provide your services to those marginalized people.
              </p>{" "}
              <p id="ct1"  style={{paddingBottom:"20px"}}> Vitruvio was made for you.</p>
              <a href="#" className="btn" id="cb1">
                Sign up here
              </a>
            </div>
          </div>
  
          <div className="card col-md-5 carddiv1">
            <div className="card-body">
              <h5 className="card-title" id="h5"> 
              <center> 
                <div className="col-md-4" id="cardstyle">
                  <i className="fas fa-user-friends fa-3x"
                  aria-hidden="true" id="iconstyle1"></i>
                </div>
              </center>
                Patients
              </h5>
              <hr></hr>
              <p className="card-text" id="ct1"><b>
                Patients with benefits{" "}</b>
              </p>

              <p id="ct1">
                Did you know you could help someone get their medicine for free
                just by taking yours? If you knew you could help them get access
                to free physiotherapy by using your physiotherapy benefits on
                yourself, would you help?
              </p>
              <p id="ct1"><b>Patients without benefits</b></p>
              <p id="ct1">
                Are you in a job without benefits - an entrepreneur, a contract
                worker, etc? Have you lost your job and need help getting your medicine?
              </p>

              <p id="ct1" style={{paddingBottom:"20px"}}> Vitruvio was made for you.</p>

              <a href="#" className="btn" id="cb1">
                Sign up here
              </a>
            </div>
          </div>
        </div>
      </div>

      <section>
        <div className="row" id="divrow2">
          <div className="divrow3 col-xs-7 col-md-7">
            <h2 id="h2chat">Do You Believe Healthcare
            Services Should Be More Accessible?</h2>
            <h3 id="chath3" >
            Sign up below and spread the word.
            </h3>
            <div className="tabclass">
              <a href="#" className="btn fa fa-facebook" id="cb">&nbsp;&nbsp;Facebook</a>&nbsp;&nbsp;&nbsp;
              <a href="#" className="btn fa fa-twitter" id="cb">&nbsp;&nbsp;&nbsp;Twitter</a>&nbsp;&nbsp;&nbsp;
              <a href="#" className="btn fa fa-linkedin" id="cb">&nbsp;&nbsp;Linkedin</a>
            </div>
          </div>
          <div className="col-xs-1 col-md-1"></div>
          <div className="col-xs-4 col-md-4" id="chat">
            <img
            src={require('../images/chat.png')}
            width="800"
            height="639"
            sizes="(max-width:800px) 100vw,800px"
            className="img-fluid"
            alt="React Bootstrap logo"
            />
          </div>
        </div>
      </section>

      <div className="row signup">
        <div className="col-md-7">
          <h1 id="h1style1">SIGN UP NOW!</h1>
          <p id="parastyle5">
            Join our mailing list. Show healthcare providers how committed we
            are to making services accessible to people who cannot afford it and
            encourage them to join us.
          </p>
          <p id="parastyle6">
            *Remember to click on the verification link in your email after you
            sign up. (Check your “junk/spam” folder).
          </p>
        </div>
        <div className="col-md-5">
          <form className="subform">
            <div className="form-group" id="colorfield">
              <label>Email</label>
              <input
                type="email"
                className="form-control subfom_input"
                placeholder="Please enter your email address"
              />
            </div>
            <br />
            <div className="form-group " id="colorfield">
              <label>Name</label>
              <input
                type="password"
                className="form-control subfom_input"
                placeholder="Please enter your first name"
              />
            </div>
            <div id="textcolor">
              {" "}
              We Take your Privacy Seriously{" "}
              <span id="spanstyle">
                <strong>Terms of Use</strong>
              </span>{" "}
              and <span id="spanstyle">Privacy Policy.</span>{" "}
            </div>
            <center>  
              <button
                type="submit"
                className="btn btn-lg"
                id="b5"
              >
                Send 
              </button>
            </center>
            &nbsp;
          </form>
        </div>
      </div>
    </Segment>
  );
};
export default home;
