import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Redirect} from 'react-router-dom';
import queryString from 'query-string';
import Axios from 'axios';
class Profileviewpage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
      isLoading: true,
      check: false,
        file:null,
      errors: null
    };
    this.onChange = this.onChange.bind(this)
    this.uploadFile = this.uploadFile.bind(this)
  }
  onChange(e) {
    this.setState({file:e.target.files[0]})
  }

  // Upload File Function
  async uploadFile(file){
    const formData = new FormData();
    formData.append('avatar',file)
    formData.append('user',sessionStorage.getItem('userData'))
    formData.append('provider',queryString.parse(this.props.location.search).pval)
    formData.append('description',this.Description.value)
    return  await Axios.post('https://react.vitruvio.ca/phpreact/makeorder.php', formData,{
      headers: {
        'content-type': 'multipart/form-data'
      }
    });
  }

  // Delete Favourite Provider Function
  handleremove = () => {     
    Axios.post('https://react.vitruvio.ca/phpreact/providerfav.php',{
      id:sessionStorage.getItem('userData'),
      pid:queryString.parse(this.props.location.search).pval
    })
    .then(({data}) => {
      if(data.success === 1){
          alert(data.msg);
          window.location.reload(false);
      }else{
        alert(data.msg);
      }
    })
    .catch(error => {
      console.log(error);
    });
  }

  // Get Provider Data
  getPosts() {
    if(sessionStorage.length == 0){
    }else{
    Axios.post('https://react.vitruvio.ca/phpreact/providerdata.php',{
      id:sessionStorage.getItem('userData'),
        pid:queryString.parse(this.props.location.search).pval,
    })
    .then(response => {
      // alert(response.data.success);
      console.log(response);
      if(response.data.success == 2 ){
        this.setState({
          posts: response.data.users,
          isLoading: false,
          check:false
        });
      }else {
        this.setState({
          posts: response.data.users,
          isLoading: false,
          check:true
        });
      }
    }) 
    .catch(error => this.setState({ error, isLoading: false }));
  }
  }

  // Let's our app know we're ready to render the data
  componentDidMount() {
    this.getPosts();
  }

  render() {
    // Check User Login and Type
    if (sessionStorage.getItem('userData') >= 0 && sessionStorage.getItem('usertype') == 1  ){
    }else {
      return (<Redirect to={'/'}/>)
    }
    const {check, isLoading, posts } = this.state;
    if(check == false){
    return (
      <React.Fragment>
        <div className="container-fluid aboutclass conspadd">
          {!isLoading ? (
            posts.map(post => {
              // const { _id, title, content } = post;
              const { cid, name, email,address,city,postcode,phone,proxy,fax ,about,what,description,type,freelimit,logo} = post;   
              return (
                <form  onSubmit={this.makeorder} className="proview">  
                  <div className="row">
                    <div className="form-group procl">
                      <img src={logo} alt="profile image" className="img-fluid" width="220px" height="100px"/>
                    </div>
                  </div>  
                  <div class="card cardpad">
                    <div className="row">
                      <div className="col-4">
                        <div className="form-group">
                          <label><h5>Provider Name: {name }</h5></label>
                        </div>
                        <div className="form-group btnpad">
                          <input type="button" onClick={() => this.handleremove()} className="btn btn-primary btnsize" value="Add to Favourite" />
                        </div>
                        <div className="form-group">
                          <label>Address : {address}</label>
                        </div>
                        <div className="form-group">
                          <label>City : {city} </label>
                        </div>
                        <div className="form-group">
                          <label>Postcode : {postcode} </label>
                        </div>
                        <div className="form-group ">
                          <label>Phone : {phone} </label>
                        </div>  
                        <div className="form-group">
                          <label>Fax : {fax} </label>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="form-group">
                          <label>What does accessible healthcare mean to you? :  {about} </label>
                          <label>How will you commit to making your service accessible : {what}</label>
                        </div>
                        <div className="form-group">
                          <label>I will provide 1 free prescription for every :{freelimit}</label>
                        </div>
                      </div>               
                      <div className="col-4">
                        <div className="form-group">
                          <label>Upload prescription</label>
                          <input type="file" className="form-control" onChange={this.onChange} />
                          <label> Description</label>
                          <input type="text" className="form-control"  placeholder="Enter Description"  ref={(val)=>this.Description=val} />
                          <button type="submit" className="btn btn-primary orderbtn">Order</button>&nbsp;           
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              );
            })
          ):(
            <p>Loading...</p>
          )}
          <div class="footlog" style={{textAlign:"center"}}>
            <footer style={{color:"#0e4958"}}>&copy; COPYRIGHT 2020 VITRUVIO.ALL RIGHTS RESERVED.</footer>
          </div>
        </div>
      </React.Fragment>
    );
    }else {
      return (
        <React.Fragment>
          <div className="container-fluid aboutclass">
            {!isLoading ? (
              posts.map(post => {
                // const { _id, title, content } = post;
                const { cid, name, email,address,city,postcode,phone,proxy,fax ,about,what,description,type,freelimit,logo} = post;
                return (
                  <form  onSubmit={this.makeorder}>  
                    <div className="row">
                      <div className="form-group">  
                        <img src={logo} alt="profile image" className="img-fluid" width="220px" height="100px"/>
                      </div>                        
                    </div>  
                    <div class="card cardpad">
                      <div className="row">        
                        <div className="col-4">
                          <div className="form-group">
                            <label><h5>Provider Name: {name }</h5></label>
                          </div>  
                          <div className="form-group">
                            <label><h5> Favourite</h5></label>
                          </div>
                          <div className="form-group">
                            <label>Address : {address}</label>
                          </div>
                          <div className="form-group">
                            <label>City : {city} </label>
                          </div>
                          <div className="form-group">
                            <label>Postcode : {postcode} </label>
                          </div>
                          <div className="form-group ">
                            <label>Phone : {phone} </label>
                          </div>  
                          <div className="form-group">
                            <label>Fax : {fax} </label>
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="form-group">
                            <label>What does accessible healthcare mean to you? :  {about} </label>
                            <label>How will you commit to making your service accessible : {what}</label>
                          </div>
                          <div className="form-group">
                            <label>I will provide 1 free prescription for every :{freelimit}</label>
                          </div>
                        </div>                         
                      </div>
                    </div>
                  </form>
                );
              })
            ):(
              <p>Loading...</p>
            )}
            <div class="footlog" style={{textAlign:"center"}}>
              <footer style={{color:"#0e4958"}}>&copy; COPYRIGHT 2020 VITRUVIO.ALL RIGHTS RESERVED.</footer>
            </div>
          </div>
        </React.Fragment>
      );
    }
  }
}
export default Profileviewpage;