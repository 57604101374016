import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Redirect} from 'react-router-dom';
import Axios from 'axios';
import {  MDBDataTable ,CardBody,Card,Button} from 'mdbreact';
import queryString from 'query-string';

class Favourite extends Component {
  constructor(props) {
    super(props);
    this.state= {
      posts: [],
      isLoading:true,
      tableRows: [],
    };
  }

  // Add Provider to Favourite
 handleremove = (id) => {
    Axios.post('https://react.vitruvio.ca/phpreact/providerfav.php',{
        id:sessionStorage.getItem('userData'),
        pid:id
    })
    .then(({data}) => {
        if(data.success === 1){
          alert(data.msg);
          window.location.reload(false);
        }
        else{
          alert(data.msg);
        }
    })
    .catch(error => {
      console.log(error);
    });
  }

  // Remove Provider from Favourites
  handledelete = (id) => {       
    Axios.post('https://react.vitruvio.ca/phpreact/providerfavdelete.php',{
      id:sessionStorage.getItem('userData'),
      pid:id
    })
    .then(({data}) => {
      if(data.success === 1){
        alert(data.msg);
        window.location.reload(false);
      }
      else{
        alert(data.msg);
      }
    })
    .catch(error => {
      console.log(error);
    });
  }

  // Provider Favourite List
  componentWillMount=async() => {
    // await Axios.get(url)
    // Axios.post('https://react.vitruvio.ca/phpreact/Pharmacypatient.php',{
    Axios.post('https://react.vitruvio.ca/phpreact/pharmacyfavlist.php',{
      id:sessionStorage.getItem('userData'),
    })
    .then(response => response.data)
    .then(data => {
      console.log(data.data);
      // alert(data.name);
      // return false;
      // alert(data.name);
      // if (err) throw err;
      this.setState({ posts: data.data })
    })
    .then(async() => {
      this.setState({ tableRows:this.assemblePosts(), isLoading:false })
      // console.log(this.state.tableRows);
    });
  }

  // Favourite Data Pass Function
  assemblePosts= () => {
    let posts =this.state.posts.map((post) => {
      if (post.custid == sessionStorage.getItem('userData') ){
        var arra1 = "btn btn-danger";
        var arra2 = "btn btn-danger d-none";
        var arra = 'Unfavourite';
      }else{
        var arra1 = "btn btn-danger d-none";
        var arra2 = "btn btn-primary ";
        var arra = 'Unfavourite';      
      }
      return (
        {
          Pharmacyid:post.pid,
          phname: post.name,
          address: post.address,
          city: post.city,
          pin: post.postcode,
          freelimit: post.freelimit,
          Order:[( <Button style={{marginBottom:'20px'}} onClick={() => this.handledelete(post.pid)}className={arra1}>{arra}</Button>),
          (<Button  className={arra2}  onClick={() => this.handleremove(post.pid)}>Favourite</Button>)],
        }
      )
    });
    return posts;
  }

  // Page Data Render
  render() {
    let _this = this;
    console.log(this.state.posts);
    var namesList = this.state.posts.map(function(name,index){
      return( 
        <div class="col-md-6" >
          <div class="card">
            <div style={{display:"inline"}}>&nbsp;&nbsp;
              <button class="m-3 float-lg-right" style={{color:"black"}} onClick={() => _this.handledelete(name.pid)}>X</button>&nbsp;&nbsp;&nbsp;&nbsp;
              <div className="row favmobview">         
                <div className="col-md-6" style={{padding:32}}><img  src={name.logo} className="img-fluid" style={{height:"140px",width:"220px"}}/> &nbsp;&nbsp;</div>   
                <div className="col-md-6 p-4  favmobview">
                  <h3>{name.name}</h3>
                  <h5>{name.address}</h5>
                  <h5>{name.postcode}</h5>
                  <h5>{name.phone}</h5>
                </div>
              </div>       
            </div>
            <div className="text-center  pb-3">
              <a  href={'/Providerview/'+ name.pid}  defaultvalue={name.pid}>
                <button className="btn" id="btncolor" style={{backgroundColor:'#4d8a7c'}}>Upload Prescription</button> 
              </a>&nbsp;&nbsp;     
            </div>
          </div>
        </div>
      )
    }
    );
    // Data Table Data
    const data = {
      columns: [
        {
          label:'Id',
          field:'Pharmacyid',
        },
        {
          label: 'Pharmacy Name',
          field: 'phname',
        },
        {
          label: 'Address',
          field: 'address',
        },
        {
          label: 'City',
          field: 'city',
        }, 
        {
          label: 'Pincode',
          field: 'pin',
        },  
        {
          label: 'Free Limit',
          field: 'freelimit',
        }, 
        {
          label: 'Favourite',
          field: 'Order',
        },
      ],
      rows:this.state.tableRows,
    }
    //Check if the Session is active or not else Redirect
    if (sessionStorage.getItem('userData') >= 0 && sessionStorage.getItem('usertype') == 1  ){
    
    }else {
      return (<Redirect to={'/'}/>)
    }

    return (
      <div className="container-fluid appclass conspadd">
        <h1>Pharmacy Favourites </h1>
        <div className="row" >{namesList}</div>    
        <div class="col-md-9 card mt-3">
          <div class="card-block p-2">
            <h4 class="favmobviewtext">Don't See Your Pharmacy in Your Favourite?<span> <a href="/all-pharmacies" class="btn float-lg-right" id="btncolor" style={{backgroundColor:'#4d8a7c'}}>More Pharmacies</a></span></h4>
          </div>
        </div>
      </div>
    )
  }
}
export default Favourite;