import React ,{Component} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Axios from 'axios';
import {AppContext} from './Context';
import  { Redirect } from 'react-router-dom'
import { Helmet } from 'react-helmet';
  class Otpverify extends Component{
    static contextType=AppContext;
    
    // Add User 
    validateProvider=(event)=>{
      event.preventDefault();
      event.persist();
      Axios.post('https://react.vitruvio.ca/phpreact/add-user.php',{
        number:sessionStorage.getItem('CustomerNumber'),
        otp:this.Otp.value,
      })
      .then(function ({data}) {
        if(data.success == 1){
          // this.context.addNewUser(data.id,this.Name.value,
          //  this.Email.value,
          //  this.Password.value,
          //  this.Users.value);
          event.target.reset();
          alert(data.msg);
          sessionStorage.setItem('userData',data.id);
          sessionStorage.setItem('usertype',data.type);
          window.location.reload(false);
          this.props.history.push("Patientsredirect");
          sessionStorage.setItem('accesstoken',data.accesstoken);
        }
        else{
          alert(data.msg);
          return false;
        }
      }.bind(this))
      .catch(function (error) {
        // console.log(error);
      });
    }

  render(){
    // Check if User is Logged in eles redirect
    if (sessionStorage.getItem('userData') >= 0 && sessionStorage.getItem('usertype') == 1  ){
      return (<Redirect to={'/Patientsredirect'}/>)
    }else {}
    
    return (
      <div id="segmentstyle">
        <section className="container-fluid bg-overlay4">
          <div class="row text-center">
              <h1 id="h1style3">OTP VERIFICATION</h1>
          </div>
        </section>

        <Helmet>
          <title>Vitruvio - Otp Verify Login</title>
        </Helmet>
        <form class="formPatient" onSubmit={this.validateProvider}>
          <h2 class="formh2">VERIFICATION OF OTP</h2>
          <div class="forminput" style={{textAlign:"left"}}>
            <label>OTP</label><span class="required">*</span>
            <input type="number" className="form-control" placeholder="Enter OTP"  ref={(val)=>this.Otp=val} required  />
          </div> 
          <button type="submit" className="btn btn-success">Verify</button>&nbsp;       
        </form>
      </div> 
    );
  }
}
export default Otpverify;