import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Redirect} from 'react-router-dom';
class Supportpatient extends Component {
  render(){
    if (sessionStorage.getItem('userData') >= 0 && sessionStorage.getItem('usertype') == 1  ){
    }else {     
      return (<Redirect to={'/'}/>) 
    }
    return (
      <div class="container-fluid aboutclass">
        <form class="formPatient" onSubmit={this.insertUser}>
          <h2>Patient Support</h2>
          <div className="row">
            <div className="col-md-3"></div>
            <div className="form-group col-md-6 " style={{textAlign:"left"}}>
              <label>Patient Name</label><span class="required">*</span>
              <input type="text" className="form-control " placeholder="Enter Name" ref={(val)=>this.Name=val} required />
            </div>  
          </div>
          <div className="row">
            <div className="col-md-3"></div>
            <div className="form-group col-md-6 " style={{textAlign:"left"}}>
              <label>Phone</label><span class="required">*</span>
              <input type="number" className="form-control " placeholder="Enter Phone " ref={(val)=>this.Phone=val}  requierd/>
            </div>  
          </div>
          <div className="row">
            <div className="col-md-3"></div>
            <div className="form-group col-md-6 " style={{textAlign:"left"}}>
              <label>Email</label><span class="required">*</span>
              <input type="email" className="form-control " placeholder="Enter Email ID" ref={(val)=>this.Email=val} requierd/>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3"></div>
            <div className="form-group  col-md-6" style={{textAlign:"left"}}>
              <label>Order Number</label>
              <input type="text" className="form-control " placeholder="Enter Order Number" ref={(val)=>this.Password=val}  />
            </div>
          </div>
          <div className="row">
            <div className="col-md-3"></div>
            <div className="form-group col-md-6 " style={{textAlign:"left"}}>
              <label>Concern or Issue</label><span class="required">*</span>
              <textarea class="form-control" rows="5" id="comment1"  ref={(val)=>this.how1=val}  required/>
            </div>
          </div>
          <button type="submit" className="btn" id="btncolor">Submit to Vitruvio</button>&nbsp;
        </form>
      </div>
    );
  }
}

export default Supportpatient;