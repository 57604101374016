import React ,{Component} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Axios from 'axios';
import {AppContext} from './Context';
import  { Redirect } from 'react-router-dom'
class Passupdatepro extends Component{
  static contextType=AppContext;
  // Update Provider Password
  passwordUser=(event)=>{
    event.preventDefault();
    event.persist();
    Axios.post('https://react.vitruvio.ca/phpreact/updatepasspro.php',{      
      customNameData:this.Passwordaa.value,
      Password:this.Password.value,
      Passworda:this.Passworda.value,
    })
    .then(function ({data}) {
      if(data.success == 1){
        // this.context.addNewUser(data.id,this.Name.value,
        //  this.Email.value,
        //  this.Password.value,
        //  this.Users.value);
        alert(data.msg);
        this.props.history.push("providers");
      }else{
        alert("Invalid Credential!");
      }
    }.bind(this))
    .catch(function (error) {
      // console.log(error);
    });
  }

  render(){
    return (
      <div className="container-fluid aboutclass">
        <form class="formPatient"  onSubmit={this.passwordUser}>
          <h3 lass="formh2">Forget Password </h3>
      
          <div className="form-group " style={{textAlign:"left"}}>
            <label>New Password</label><span class="required">*</span>
            <input type="password" className="form-control " placeholder="Enter password" ref={(val)=>this.Password=val} required />
            <input type="hidden" className="form-control " placeholder="Enter password" defaultValue={this.props.location.customNameData} ref={(val)=>this.Passwordaa=val} required />
          </div>

          <div className="form-group " style={{textAlign:"left"}}>
            <label> Confirm Password</label><span class="required">*</span>
            <input type="text" className="form-control " placeholder="Enter password" ref={(val)=>this.Passworda=val} required />
          </div>
          <button type="submit" className="btn btn-success"> Reset </button>&nbsp;
        </form>
      </div>
    );
  }             
}
export default Passupdatepro;