import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Redirect} from 'react-router-dom';
import queryString from 'query-string';
import Axios from 'axios';
class PaymentSuccess extends React.Component{
// componentWillMount=async() => {
// componentDidMount: function() {
  
  // Payment Update in DB
  componentDidMount() {
    if(sessionStorage.length == 0){
  }else{
    Axios.post('https://react.vitruvio.ca/phpreact/paymentmethod.php',{
      id:sessionStorage.getItem('userData'),
      trans_status:queryString.parse(this.props.location.search).trnApproved,
      transaction_id:queryString.parse(this.props.location.search).trnId,
      transaction_amount:queryString.parse(this.props.location.search).trnAmount,
      transaction_date:queryString.parse(this.props.location.search).trnDate,
      provider_id:queryString.parse(this.props.location.search).trnOrderNumber,
    })
    .then(response => response.data)
  }
  }


  render(){
    if (sessionStorage.getItem('userData') >= 0 && sessionStorage.getItem('usertype') == 2  ){
    }else {
      return (<Redirect to={'/'}/>)
    }
    return (
      <div>
        <div className="container-fluid text-center payclass conspadd">
          <h3>Payment Successful : {queryString.parse(this.props.location.search).trnCustomerName}</h3>
          <p>Click ok button to get redirected to Dashboard page. </p>
          <a href="/Providerdashboard" className="btn btn-success">OK </a>
        </div>
      </div>
    );
  }
}

export default PaymentSuccess;