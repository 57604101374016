import React ,{Component} from 'react';
import Home from './Home';
import Axios from 'axios';
import { NavLink } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button ,Navbar ,Form ,Nav ,FormControl ,} from 'react-bootstrap';
import {Redirect} from 'react-router-dom';
import {  MDBDataTable ,CardBody,Card} from 'mdbreact';
import { black } from 'material-ui/styles/colors';
import '../Style.css';
class PatientsDashboard extends Component{
  constructor(props) {
    super(props);
    this.state= {
      posts: [],
      post:"",
      isLoading:true,
      tableRows: [],
    };
  }

  logout  = () => {
    sessionStorage.clear();
    window.location.reload(false);
    this.props.history.push('/');
  }
  //Patient Dashboard
  componentWillMount=() => {
    // await Axios.get(url)
    if(sessionStorage.length == 0){
    }else{
    Axios.post('https://react.vitruvio.ca/phpreact/patientdashboard.php',{
      id:sessionStorage.getItem('userData'),
      accesstoken:sessionStorage.getItem('accesstoken'),
    })
    .then(response => response.data)
    .then(data => {
      console.log(data.data);
      if(data.success == 3){
        this.props.history.push('/');
      }else if (data.success == 0) 
      {
        alert(data.data);
        this.logout();
      }
      this.setState({post:data.customername});
      this.setState({ posts: data.data })
    })
    .then(async() => {
      this.setState({ tableRows:this.assemblePosts(), isLoading:false })
      // console.log(this.state.tableRows);
    });
    }
  }

  // Patient Profile Details Data
  assemblePosts= () => {
    let posts =this.state.posts.map((post) => {
      return (
        {
          upload: (<img  src={post.logo}  alt="profile image" className="img-fluid" width="120px" height="70px" style={{marginTop:"25px",marginBottom:"25px"}}/>),
          name: post.name,
          address: post.address,
          phone: post.phone,
          Order:(<a href={'/Providerview/' + post.pid}  defaultvalue={post.pid}><button className="btn btn-primary" style={{backgroundColor:'#28A745'}}>Upload Prescription</button> </a>),
        }
      )
    });
    return posts;
  }

  render() {
    const data = {
      columns: [
        {
          label: 'Image',
          field: 'upload',
        },
        {
          label: 'Provider Name',
          field: 'name',
        }, 
        {
          label: 'Address',
          field: 'address',
        },
        {
          label: 'Phone',
          field: 'phone',
        },  
        {
          label: 'Order',
          field: 'Order',
        },
      ],
      rows:this.state.tableRows,
    }
    
    // Check if User is logged in 
    if (sessionStorage.getItem('userData') >= 0 && sessionStorage.getItem('usertype') == 1  ){
  
    }else {
      return (<Redirect to={'/'}/>)
    }

    return (
      <div className="container-fluid">
        <div className="conspadd">
          <a type="submit" className="btn  float-right" id="btncolor"
            onClick={() =>{ if(window.confirm('Do remember that these prescriptions are for those in dire need and cannot afford it due to their own personal reasons. We ask that you…'))window.onCancel()}} href="/Freeprespharmacy" >Request a Free Prescription</a>
          <h1 className="favclass"></h1>
          <h2 id="dashpad" style={{marginBottom: 32}}>Welcome {this.state.post}</h2>
          <div className="currentServices" style={{borderColor:black,borderRadius:20, borderStyle:'Solid',borderWidth:1, margin:5,padding:20,}}>
            <h3><strong>Current Services</strong> </h3>
            <div  className="flex-row" id="pharmacyservice" style={{borderColor:`#d3d3d3`,borderRadius:20, borderStyle:'Solid',borderWidth:1, margin:5,padding:20, backgroundColor:'lightGray'  }}>
              <h3 style={{ fontSize:'30'}}>Pharmacy</h3>
              <div className="Outer" style={{display:'inline-block',margin:40 }}>
                <a href="/pharmacy-favourites " className="btn" id="btncolor" style={{borderRadius:10}}>Pharmacy List</a> 
                <a href="/Customerorders " className="btn" id="btncolor" style={{margin:7,borderRadius:10}}>Current Orders</a> 
                <a href="/Customerorders " className="btn" id="btncolor1"style={{margin:-3,borderRadius:10}}>Upload Prescription</a>
              </div>
            </div>
          </div>
          <div className="comingSoon" style={{borderColor:black,borderRadius:20, borderStyle:'Solid',borderWidth:1, margin:15,padding:15,marginTop:55,marginBottom:34}}>
            <h5><strong>Services Coming Soon</strong> </h5>
            <div className="flex-row" id="inlineDiv1" style={{ display:'flex' }}  >
              
              <div  className="flex-col" id="physioService" style={{borderColor:`#d3d3d3`,borderStyle:'solid',borderRadius:20,borderWidth:'medium', margin:10,padding:10, backgroundColor:'lightGray',width:400}}>
                <h3 style={{ fontSize:'10',textAlign:'center',color:'black'}}>Physiotherapy</h3>
              </div>

              <div  className="flex-col" id="chiroService" style={{borderColor:`#d3d3d3`,borderStyle:'solid',borderRadius:20, borderStyle:'Solid',borderWidth:1, margin:10,padding:10, backgroundColor:'lightGray',width:400}}>
                <h3 style={{ fontSize:'10',textAlign:'center',color:'black'}}>Chiropactor</h3>
              </div>

              <div  className="flex-col" id="dentistService"style={{borderColor:`#d3d3d3`,borderStyle:'solid',borderRadius:20, borderStyle:'Solid',borderWidth:1, margin:10,padding:10, backgroundColor:'lightGray',width:400}}>
                <h3 style={{ fontSize:'10',textAlign:'center',color:'black'}}>Dentist</h3>
              </div>
              
            </div>
            <div className="flex-row" id="inlineDiv2" style={{ display:'flex' }} >
              
              <div  className="flex-col" id="counselling" style={{borderColor:`#d3d3d3`,borderStyle:'solid',borderRadius:20, borderStyle:'Solid',borderWidth:1, margin:10,padding:10, backgroundColor:'lightGray',width:400}}>
                <h3 style={{ fontSize:'10',textAlign:'center',color:'black'}}>Counselling</h3>
              </div>

              <div  className="flex-col" id="Chiropodist"style={{borderColor:`#d3d3d3`,borderStyle:'solid',borderRadius:20, borderStyle:'Solid',borderWidth:1, margin:10,padding:10, backgroundColor:'lightGray',width:400}}>
                <h3 style={{ fontSize:'10',textAlign:'center',color:'black'}}>Chiropodist</h3>
              </div>

              <div  className="flex-col" id="SLT"style={{borderColor:`#d3d3d3`,borderStyle:'solid',borderRadius:20, borderStyle:'Solid',borderWidth:1, margin:10,padding:10, backgroundColor:'lightgray',width:400}}>
                <h3 style={{ fontSize:'10',textAlign:'center',color:'black'}}>Speech Language Therapist</h3>
              </div>
            </div>
          </div>
        </div>
        <section>
          <div className="row" id="divrow2">
            <div className="divrow3 col-xs-7 col-md-7">
              <h2 id="h2chat">Do You Believe Healthcare
              Services Should Be More Accessible?</h2>
              <h3 id="chath3" >
                Spread the word.
              </h3>
              <div className="tabclass">
                <a href="#" className="btn fa fa-facebook" id="cb">&nbsp;&nbsp;Facebook</a>&nbsp;&nbsp;&nbsp;
                <a href="#" className="btn fa fa-twitter" id="cb">&nbsp;&nbsp;&nbsp;Twitter</a>&nbsp;&nbsp;&nbsp;
                <a href="#" className="btn fa fa-linkedin" id="cb">&nbsp;&nbsp;Linkedin</a>
              </div>
            </div>
            <div className="col-xs-1 col-md-1"></div>
            <div className="col-xs-4 col-md-4" id="chat">
              <img
                src={require('../images/chat.png')}
                width="800"
                height="639"
                sizes="(max-width:800px) 100vw,800px"
                className="img-fluid"
                alt="React Bootstrap logo"
              />
            </div>
          </div>
        </section>
      </div>
    );
  }    
}
export default PatientsDashboard;