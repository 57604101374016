import React from 'react';
import {Navbar ,Nav ,FormControl } from 'react-bootstrap';
import '../Style.css';
class Navigation extends React.Component{
  constructor(props) {
    super(props);
    this.state= {
      posts: [],
      isLoading:true,
      tableRows: [],
      show: false,
        disabled: true,
        navBackground: "transparent"
    };
  }
  // Logout
  logout  =() => {
    sessionStorage.clear();
    const success = 100;
    window.location.reload(false);
    this.props.history.push('/');
  }

  componentDidMount() {
    document.addEventListener("scroll", () => {
      const backgroundcolor = window.scrollY < 130 ? "transparent" : "white";
      // alert(backgroundcolor);
      this.setState({ navBackground: backgroundcolor });
    });
  }

  render(){
    if(sessionStorage.getItem('usertype') == 1){
    return (
      <div>
      <Navbar className="navbar1 navbar2" expand="lg"  scrolling fixed="top"  style={{backgroundColor:"white",paddingLeft:"95px"}}>
        <Navbar.Brand href="/PatientsDashboard">
          <img
            src={require('../images/logo.png')}
            width="100"
            height="100"
            className="d-inline-block align-top"
            alt="React Bootstrap logo"
      
          />
        </Navbar.Brand>  
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="justify-content-end" style={{ width: "100%" ,fontSize:"20px" }}>
            <Nav.Link href="/PatientsDashboard">Dashboard</Nav.Link>
            <Nav.Link href="/Patientsprofile">Customer</Nav.Link>
            <Nav.Link href="/Customerorders">Prescriptions</Nav.Link>
            <Nav.Link href="/all-pharmacies">Pharmacy List</Nav.Link>
            <Nav.Link onClick={this.logout}>Logout</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
  }else if(sessionStorage.getItem('userData') === null){
    return (
      <div>
        <Navbar  className="navbar1 navbar2"  expand="lg"  scrolling dark fixed="top"  style={{backgroundColor:this.state.navBackground,paddingLeft:"95px"}}>
          <Navbar.Brand href="/">
            <img
              src={require('../images/logo.png')}
              width="100"
              height="100"
              className="d-inline-block align-top imgsize"
              alt="React Bootstrap logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="justify-content-end" style={{ width: "100%" ,fontSize:"20px" }}>
              <Nav.Link href="/">Home</Nav.Link>
              <Nav.Link href="/about">About</Nav.Link>
              <Nav.Link href="/learn-more">Learn More</Nav.Link>
              <Nav.Link href="/contact">Contact</Nav.Link>
              <Nav.Link href="/patients">Customer</Nav.Link>
              <Nav.Link href="/providers">Providers</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    );
    }else if(sessionStorage.getItem('usertype') == 3){
      return (
        <div>
          <Navbar className="navbar1 navbar2"  expand="lg"  scrolling fixed="top"  style={{backgroundColor:this.state.navBackground,paddingLeft:"95px"}}>
            <Navbar.Brand href="/Adminview">
            <img
              src={require('../images/logo.png')}
              width="100"
              height="100"
              className="d-inline-block align-top imgsize"
              alt="React Bootstrap logo"
            />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="justify-content-end" style={{ width: "100%" ,fontSize:"20px" }}>
                <Nav.Link href="/Adminview">Dashboard</Nav.Link>
                <Nav.Link href="/Admincustview">AdminCustomerview</Nav.Link>
                <Nav.Link onClick={this.logout} >Logout</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </div>
      );
    }else if(sessionStorage.getItem('usertype') == 2){
      return (
        <div>
          <Navbar className="navbar1 navbar2"  expand="lg"  scrolling fixed="top"  style={{backgroundColor:"white",paddingLeft:"95px"}}>
            <Navbar.Brand href="/Providerdashboard">
              <img
                src={require('../images/logo.png')}
                width="100"
                height="100"
                className="d-inline-block align-top imgsize"
                alt="React Bootstrap logo"
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="justify-content-end" style={{ width: "100%" ,fontSize:"20px" }}>
                <Nav.Link href="/Providerdashboard">Dashboard</Nav.Link>
                <Nav.Link href="/Providerprofile">Profile</Nav.Link>
                <Nav.Link href="/Providerteam">Team</Nav.Link>
                <Nav.Link href="/Providerorders">Orders</Nav.Link>
                <Nav.Link href="/Providersubscription">Subscription</Nav.Link>
                <Nav.Link onClick={this.logout} >Logout</Nav.Link>
              </Nav>
            </Navbar.Collapse>
        </Navbar>
        </div>
      );
    }
  }
}
export default Navigation;