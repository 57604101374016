import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import {  MDBDataTable ,CardBody,Card,Button} from 'mdbreact';
import Modal from "react-bootstrap/Modal";
import Axios from 'axios'; 
import $ from "jquery";
import queryString from 'query-string';
// const url = 'http://jsonplaceholder.typicode.com/posts';

class Customerorders extends Component {
  constructor(props) {
    super(props);
    this.state= {
      modalpost:'',
      posts: [],
      isLoading:true,
      tableRows: [],
    };
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  //If Component Updates Highlight Table Data
  componentDidUpdate(){
    $(".btn-warning").ready(() =>{
      $('.btn-warning').parent('td').parent('tr').css('background-color','#73b1a3');
    });
  }

  handleClose() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }
  logout  = () => {
    sessionStorage.clear();
    window.location.reload(false);
    this.props.history.push('/');
  }
 
  //Display Patient Orders
  componentWillMount=async() => {
    if(sessionStorage.length == 0){
    }else{
    // await Axios.get(url)
     Axios.post('https://react.vitruvio.ca/phpreact/patientorder.php',{
        id:sessionStorage.getItem('userData'), 
        accesstoken:sessionStorage.getItem('accesstoken'),
      })
      .then(response => response.data)
      .then(data => {
        console.log(data.data);
        if (data.success == 0) 
        {
          alert(data.data);
          this.logout();
        }
        // alert(data.data);
        // if (err) throw err;
        this.setState({ posts: data.data })
      })
      .then(async() => {
        this.setState({ tableRows:this.assemblePosts(), isLoading:false })
        // console.log(this.state.tableRows);
      });
    }
  }

  handlereject1 = (id,action) => {
    this.setState({
      modalpost: id,
    });
    this.setState({ show: true });
  }  

  //Order Reject Function
  handlereject=(id,action)=>{
    if(this.Reason.value == ''){
      alert('Please provide reason before cancellation ');
      return false;
    }else{
     
    }

    if (window.confirm("Are you Sure You Want to Reject?")){
      Axios.post('https://react.vitruvio.ca/phpreact/order_reject.php',{
        // pid:sessionStorage.getItem('pid'),
        id:sessionStorage.getItem('userData'),
        oid:id,
        Reason:this.Reason.value,
        accesstoken:sessionStorage.getItem('accesstoken'),
      })
      .then(({data}) => {
        if (data.success == 3) 
        {
          alert(data.data);
          this.logout();
        }
        else if(data.success == 1){
          // alert("Success!");
          alert(data.data);
          window.location.reload(false);
        }
        else{
          alert("Order Rejected")
          window.location.reload(true);
        }
      })
      .catch(error => {
        console.log(error);
      });
    }else{
      return false;
    }
    
  }

  //Display Patient Orders
  handleUpdate = (status) => {
    // await Axios.get(url)
    Axios.post('https://react.vitruvio.ca/phpreact/patientorder.php',{
      id:sessionStorage.getItem('userData'),
      status:status,
      accesstoken:sessionStorage.getItem('accesstoken'),
    })
    .then(response => response.data)
    .then(data => {
        console.log(data.data);
        if (data.success == 0) 
        {
          alert(data.data);
          this.logout();
        }
        // alert(data.data);
        // if (err) throw err;
        this.setState({ posts: data.data })
    })
    .then(async() => {
        this.setState({ tableRows:this.assemblePosts(), isLoading:false })
        // console.log(this.state.tableRows);
    });
  }

  //Concern Submission Function
  handlealert=()=>{
    let ans=prompt("Submit to vitruvio");
    Axios.post('https://react.vitruvio.ca/phpreact/customerconcern.php',{
      // id:sessionStorage.getItem('userData'),
      cid:sessionStorage.getItem('userData'),
      concern:ans,
      accesstoken:sessionStorage.getItem('accesstoken'),
    })
   .then(response => {
      //alert(response.data.msg)
    })
    .then(data =>{
      if (data.success == 0) 
      {
        alert(data.data);
        this.logout();
      }
    })
  }

  //Order Cancel Fucntion
  assemblePosts= () => {
    let posts =this.state.posts.map((post) => {
      if(post.o_type == 1){
        if(post.status == 'Requested' || post.status == 'Accepted' ||post.status == 'Ready For Pick Up'){
          var arra = 'Cancel Order';
          // var arra1 = "this.props.shouldHide ? 'hidden' : ''";
          var arra1 = "btn btn-warning";
          var arra2 = "btn btn-danger";
          this.setState({ disabled: false });
          // disabled: false;
        }
      }else{
        if(post.status == 'Requested' || post.status == 'Accepted' ||post.status == 'Ready For Pick Up'){
        var arra = 'Cancel Order';
        // var arra1 = "this.props.shouldHide ? 'hidden' : ''";
        var arra1 = "btn btn-warning";
        var arra2 = "btn btn-danger";
        this.setState({ disabled: false });
        // disabled: false;
        }
      }

      return (
        {
          bid: post.bid,
          upload: (<a href={post.upload} target="_blank"  >View File</a>),
          description: post.description,
          name: post.name,
          address: post.address,
          phone: post.phone,
          date: post.date,
          time: post.time,
          note: post.note,
          reason: post.reason,
          status: post.status,
          action:[( <Button style={{marginBottom:'20px'}}onClick={() => this.handlereject1(post.bid,post.status)}   className={arra2}    disabled={this.state.disabled} >{arra}
          </Button>  )],
        }
      )
    });
    return posts;
  }


  render() {

    const data = {
      columns: [
        {
          label:'OrderID',
          field:'bid',
        },
        {
          label: 'Action',
          field: 'action',
        },
        {
          label: 'Status',
          field: 'status',
        },
        {
          label: 'Reason',
          field: 'reason',
        },
        {
         label: 'Prescription',
         field: 'upload',
        },
        {
          label: 'Description',
          field: 'description',
        },
        {
          label: 'Notes',
          field: 'note',
        }, 
        {
          label: 'Provider Name',
          field: 'name',
        }, 
        {
          label: 'Date',
          field: 'date',
        }, 
        {
          label: 'Time',
          field: 'time',
        },
        {
          label: 'Provider Phone',
          field: 'phone',
        }, 
        {
          label: 'Provider Address',
          field: 'address',
        },
      ],
      rows:this.state.tableRows,
    }

  //Check if the Session is active or not else Redirect
    if (sessionStorage.getItem('userData') >= 0 && sessionStorage.getItem('usertype') == 1  ){

    }else {
      return (<Redirect to={'/'}/>)
    }
    const { modalpost } = this.state;
    return (
      <div className="container-fluid cardclass conspadd">
        <h1 >Prescriptions</h1>  
        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Proper Reason For Cancellation </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group" >
              <label>Reason</label>
              <textarea class="form-control" rows="5" id="Reason"   ref={(val)=>this.Reason=val}  />
              <input type="hidden" class="form-control" id="mbid" defaultValue={modalpost}   ref={(val)=>this.mbid=val}  />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Close
            </Button>
            <button  className="btn" id="btncolor" onClick={() => this.handlereject(this.mbid.value,this.Reason.value)}    >Rejected</button>
          </Modal.Footer>
        </Modal>
        <div class="row providerbtn">
          <div class="col-md-8">
            <Button onClick={() => window.location.reload(false)} className="btn btn-size" id="btncolor">ALL </Button>  &nbsp;  &nbsp;
            <Button onClick={() => this.handleUpdate('Requested')}  className="btn btn-size" id="btncolor">Placed </Button>  &nbsp;  &nbsp; 
            <Button onClick={() => this.handleUpdate('Accepted')}  className="btn btn-size" id="btncolor">Accepted </Button>     &nbsp;  &nbsp; 
            <Button onClick={() => this.handleUpdate('Rejected')}  className="btn btn-size" id="btncolor">Rejected </Button> &nbsp;  &nbsp; 
            <Button onClick={() => this.handleUpdate('Delivered')}  className="btn btn-size" id="btncolor">Delivered </Button>   &nbsp;  &nbsp; 
            <a href="/pharmacy-favourites" class="btn" style={{backgroundColor:"#bebada"}} ><i class="fa fa-file-text" aria-hidden="true"></i>  &nbsp; Create Prescription</a>
          </div>
          <div className="col-md-4">
            <a href={'/Supportpatient?cval=' + sessionStorage.getItem('userData')} class="btn float-right" id="btncolor1">Tell us your concern or issue</a>      
          </div>
        </div>
        
        <div> 
          <h3>
            <mark>Note : Not to hesitate to call each other for inquiries or request for detail.</mark>
          </h3>
        </div>
        <MDBDataTable responsiveSm responsive striped bordered hover data={data} />       
   </div>
    )
  }
}
export default Customerorders;  