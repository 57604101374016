import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Redirect} from 'react-router-dom';
import queryString from 'query-string';
import Axios from 'axios';
import {BarChart,ResponsiveContainer,Bar,CartesianGrid,XAxis,YAxis,Tooltip,Legend,Pie,PieChart,Cell} from 'recharts';

import {  MDBDataTable, Row, Col, Card, CardBody  } from 'mdbreact';
class Profileviewself extends Component {
 constructor(props) {
    super(props);
    this.state = {
      posts: [],
      isLoading: true,
      check: false,
      file:null,
      errors: null
    };
  }
  logout  = () => {
    sessionStorage.clear();
    window.location.reload(false);
    this.props.history.push('/');
  }
  COLORS = ['#0e4958', '#4d8a7c'];

  CustomTooltip = ({ active, payload, label }) => {
    if (active) {
      return (
        <div className="custom-tooltip" style={{ backgroundColor: '#ffff', padding: '5px', border: '1px solid #cccc' }}>
          {/* <label>{`${payload[0].name} : ${payload[0].value}`}</label> */}
          <label>{`${payload[0].name}`}</label>
        </div>
      );
    }
    return null;
  };

  // Submit Concern Function
  handlealert=()=>{
    let ans=prompt("Submit to vitruvio");
    Axios.post('https://react.vitruvio.ca/phpreact/concern.php',{
      // id:sessionStorage.getItem('userData'),
      pid:sessionStorage.getItem('userData'),
      concern:ans
    })
    .then(response => {
      alert(response.data.msg)
    })
  }

  /// Provider Data Function
  getPosts() {
    if(sessionStorage.length == 0){
    }else{
    Axios.post('https://react.vitruvio.ca/phpreact/providerdata.php',{
      // id:sessionStorage.getItem('userData'),
      pid:sessionStorage.getItem('userData'),
      accesstoken:sessionStorage.getItem('accesstoken'),
    })
    .then(response => {
      console.log(response);
      console.log(response.data.users);
      if(response.data.success == 2 ){
        this.setState({
          posts: response.data.users,
          isLoading: false,
          check:false
        });
      }else {
        this.setState({
          posts: response.data.users,
          isLoading: false,
          check:true
        });
      }
    })
    .then(data => {
      if (data.success == 0) 
      {
        alert(data.data);
        this.logout();
      }
      // alert(data.data);
      // if (err) throw err;
    })
    .catch(error => this.setState({ error, isLoading: false }));
  }
  }

  // Let's our app know we're ready to render the data
 

  // Pharmacy Team List
  componentWillMount=async() => {
    if(sessionStorage.length == 0){
    }else{
    this.getPosts();
    // await Axios.get(url)
    Axios.post('https://react.vitruvio.ca/phpreact/pharmacyteam.php',{
      id:sessionStorage.getItem('userData'), 
      accesstoken:sessionStorage.getItem('accesstoken'),
    })
    .then(response => response.data)
    .then(data => {
      console.log(data.data);
      // if (err) throw err;
      this.setState({ posts: data.data })
    })
    .then(async() => {
      this.setState({ tableRows:this.assemblePosts(), isLoading:false })
      // console.log(this.state.tableRows);
    });
  }
  }

  assemblePosts= () => {
    let posts =this.state.posts.map((post) => {
      return (
        {
          name: post.name,
          title: post.title,
          image: (<img src={post.photo} class="img-fluid" width="100px" height="50px"/> ),
          // action:(<a href={'/Providerview?pval=' + post.tid}  defaultvalue={post.pid}>Delete member </a>),
        }
      )
    });
    return posts;
  }

  render() {
    const data = {
      columns: [
      // {

      //   label:'#',

      //   field:'number',

      // },
        {
          label: 'Name',
          field: 'name',
        },
        {
          label: 'Title',
          field: 'title',
        },
        {
          label: 'Image',
          field: 'image',
        },
      ],
      rows:this.state.tableRows,
    }
    if (sessionStorage.getItem('userData') >= 0 && sessionStorage.getItem('usertype') == 2  ){
    }else {return (<Redirect to={'/'}/>)}
    const {check, isLoading, posts } = this.state;
    if(check == false){
      return (
        <React.Fragment>
          <div className="container-fluid aboutclass conspadd">
            {!isLoading ? (
              posts.map(post => {
                // const { _id, title, content } = post;

                const { total_freeprescription,freeFulfilledOrder,totalMedsDelivered,freeLimitOrder,completeorder,freeorder,cid, name, email,address,city,postcode,phone,proxy,fax ,about,what,description,note,type,freelimit,logo,free_order,totalfreedelivered} = post;
                var freeLimit = parseInt(freeLimitOrder,10)
              var freeProvidedOrder = parseInt(freeFulfilledOrder,10);
                let remainingFreeOrders = totalMedsDelivered-(freeProvidedOrder*freeLimit);
              if(remainingFreeOrders >= freeLimit){
                remainingFreeOrders = freeLimit;
              }
              const remainigmeds = freeLimit - remainingFreeOrders;
              const  pieData = [
                {
                  "name": "Medication Completed: " + remainingFreeOrders,
                  "value": remainingFreeOrders,
                },
                {
                  "name": "Remaining Medication: " + remainigmeds,
                  "value": remainigmeds,
                },
                
            ];
                return (
                  <form  onSubmit={this.makeorder} className="proview">  
                    <div className="row">
                      <div className="form-group procl">
                        <h2  className="textc" style={{fontWeight:"bolder"}}> &nbsp;&nbsp;&nbsp;&nbsp;Upload Prescription to {name }'s </h2>
                      </div>
                    </div>  
                    <div class="card cardpad desktopview1">
                      <div className="row">
                        <div className="col-md-4 col-sm-6">
                          <div className="form-group">
                            <label><span class="required">*</span>Upload prescription :&nbsp;</label>
                            <input type="file" className="form-control" onChange={this.onChange}  disabled/>
                          </div>
                          <div class="form-group">
                            <label for="Notes">Notes :&nbsp;</label>
                            <textarea class="form-control" rows="5" id="note" placeholder="Additional Notes to Pharmacy"  ref={(val)=>this.note=val} disabled></textarea>
                          </div>
                          {free_order > 0 &&(
                            <div class="form-group">
                              <input disabled type="checkbox" id="freeprescription" checked={this.state.isChecked} onChange={this.handleChange} ref={(checked)=>this.freeprescription=checked}/>&nbsp;
                              <label for="Freeprespharmacy">Do you want to avail a Free Prescription?&nbsp;</label>
                            </div>
                          )}
                          <button type="submit" className="btn orderbtn" id="btncolor1" disabled>Submit Prescription</button>&nbsp;
                        </div>
                        <div className="col-md-4 col-sm-6">
                          <div className="form-group text-center">
                            <label><h4 style={{fontWeight:"bolder"}}>About &nbsp;{name } </h4></label>
                          </div>
                          <div className="form-group text-center">
                            <img src={logo} alt="profile image" className="img-fluid" width="250px" height="100px"/>
                          </div>
                          <div className="form-group text-center">
                            <label>&nbsp; {address} &nbsp; </label>
                            <br></br>
                            <label>&nbsp; {city} &nbsp; </label>
                            <label>&nbsp;  {postcode} &nbsp;  </label>
                            <br></br>
                            <label>&nbsp; {phone}&nbsp;  </label>
                            <label>&nbsp; {fax} </label>
                          </div>
                          <div className="form-group">
                            <label><b>About:</b></label>
                            <span style={{textAlign:"justify"}}>"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</span>
                          </div>
                          <div className="form-group btnpad text-center">
                            <input type="button" onClick={() => this.handleremove()} className="btn  btnsize" id="btncolor1" value="Add to Favourite" disabled />
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                        <div class="col-md-12">
                          <div class="p-6" >
                            <h4 className="text-center" style={{paddingTop:"10px"}}> Free Medication Progress</h4>
                            <ResponsiveContainer width="100%" height={300}>
                              <PieChart width={500} height={300}>
                                <Pie data={pieData} color="#000000" dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={120} fill="#8884d8" >
                                    {
                                        pieData.map((entry, index) => <Cell key={`cell-${index}`} fill={this.COLORS[index % this.COLORS.length]} />)
                                    }
                                </Pie>
                                <Tooltip content={<this.CustomTooltip />} />
                                <Legend />
                              </PieChart>
                            </ResponsiveContainer>
                            <p class="text-center"><strong>1 Free Medication for Every {freeLimitOrder} Medication</strong></p>
                          </div>
                        </div>
                          <div className="form-group">
                            <label>Medications needed to reach next goal :&nbsp;&nbsp;<b>{remainigmeds}</b></label>
                          </div>
                          <div className="form-group">
                            <label>We will provide 1 free medication for every :&nbsp;&nbsp;<b>{freelimit}</b></label>
                          </div>
                          <div className="form-group">
                            <label>Total free medications given :<b> {total_freeprescription}</b></label>
                          </div>
                          <div className="form-group">
                            <label>Total free medications available: :<b> {free_order}</b> </label>
                          </div>
                          <div className="form-group">
                            <label><b>What does accessible healthcare mean to you? </b>&nbsp;  {about} </label>
                            <label><b>How will you commit to making your service accessible :</b>&nbsp; {what}</label>
                          </div>
                        </div>              
                      </div>
                    </div>
                    <h1>Provider Team</h1>
                    <Card>
                      <center>  <h1> Team Member's </h1> </center>
                      <CardBody>
                        <MDBDataTable responsiveSm striped bordered hover data={data} />
                      </CardBody>
                    </Card>
                    {/* <Card style={{padding:"20px"}}>
                      <p>  Completed orders: {completeorder}</p>
                      <p>Free orders :  {freeorder}</p>
                    </Card> */}
                  </form>
                );
              })
            ) : (
              <p>Loading...</p>
            )}
          
          </div>
        </React.Fragment>
      );
    }else {
      return (
        <React.Fragment>
          <div className="container-fluid aboutclass conspadd">
            {!isLoading ? (
              posts.map(post => {
                // const { _id, title, content } = post;
                const { total_freeprescription,completeorder,freeorder, cid, name, email,address,city,postcode,phone,proxy,fax ,about,what,description,note,type,freelimit,freefulfilled,logo,free_order,totalfreedelivered} = post;
                return (
                  <form  onSubmit={this.makeorder}>  
                    <div className="row">
                      <div className="form-group">
                        <h2 className="textc"  style={{fontWeight:"bolder"}}> &nbsp;&nbsp;&nbsp;&nbsp;Upload Prescription to &nbsp;{name }</h2>
                      </div>  
                    </div>  
                    <div class="card cardpad">
                      <div className="row">
                        <div className="col-md-4 col-sm-6">
                          <div className="form-group">
                            <label><span class="required">*</span>Upload prescription :&nbsp;</label>
                            <input type="file" className="form-control" onChange={this.onChange}  required/>
                          </div>
                          <div class="form-group">
                            <label for="Notes">Notes :&nbsp;</label>
                            <textarea class="form-control" rows="5" id="note" placeholder="Additional Notes to Pharmacy" ref={(val)=>this.note=val}  ></textarea>
                          </div>
                          {free_order > 0 &&(
                            <div class="form-group">
                              <input type="checkbox" id="freeprescription" checked={this.state.isChecked} onChange={this.handleChange} ref={(checked)=>this.freeprescription=checked}/>&nbsp;
                              <label for="Freeprespharmacy">Do you want to avail a Free Prescription?&nbsp;</label>
                            </div>
                          )}

                          <button type="submit" className="btn orderbtn" id="btncolor1">Submit Prescription</button>&nbsp;
                        </div>
                        <div className="col-md-4 col-sm-6">
                          <div className="form-group text-center">
                            <label><h4 style={{fontWeight:"bolder"}}>About &nbsp;{name }'s </h4></label>
                          </div>
                          <div className="form-group text-center">
                            <img src={logo} alt="profile image" className="img-fluid" width="250px" height="100px"/>
                          </div>
                          <div className="form-group text-center">
                            <label>&nbsp; {address} &nbsp; </label>
                            <br></br>
                            <label>&nbsp; {city} &nbsp; </label>
                            <label>&nbsp;  {postcode} &nbsp;  </label>
                            <br></br>
                            <label>&nbsp; {phone}&nbsp;  </label>
                            <label>&nbsp; {fax} </label>
                          </div>
                          <div className="form-group">
                            <label><b>About:</b></label>
                            <span style={{textAlign:"justify"}}>"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</span>
                          </div>
                          <div className="form-group text-center">
                            <label><b style={{color:"green"}}> Marked as Favourite Pharmacy</b></label>
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                          <div className="form-group">
                            <label><b>What does accessible healthcare mean to you?  </b> {about} </label>
                            <label><b>How will you commit to making your service accessible :</b>&nbsp; {what}</label>
                          </div>
                          <div className="form-group">
                            <label>I will provide 1 free prescription for every :&nbsp;&nbsp;<b>{freelimit}</b></label>
                          </div>
                          <div className="form-group">
                            <label>Total free prescription given :<b> {total_freeprescription}</b></label>
                          </div>
                          <div className="form-group">
                            <label>Total free prescription available :<b> {free_order}</b> </label>
                          </div>
                        </div>
                      </div>        
                    </div>
                    <h1>Provider Team</h1>
                    <Card>
                      <center>  <h1> Team Member's </h1> </center>
                      <CardBody>
                        <MDBDataTable responsiveSm striped bordered hover data={data} />
                      </CardBody>
                    </Card>
                    {/* <Card style={{padding:"20px"}}>
                      <p>  Completed orders: {completeorder}</p>
                      <p>Free orders :  {freeorder}</p>
                    </Card> */}
                  </form>
                );
              })
            ) : (
              <p>Loading...</p>
            )}
          </div>
        </React.Fragment>
      );
    }
  }
}
export default Profileviewself;
