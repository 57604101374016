import React from 'react';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Redirect} from 'react-router-dom';
import Axios from 'axios';
import {  MDBDataTable ,CardBody,Card,Button} from 'mdbreact';
import queryString from 'query-string';
export default class PaymentPage extends React.Component {
  constructor(props) {
    super(props);
    this.state= {
      posts: [],
      isLoading:true,
      tableRows: [],
      cvc: '',
      expiry: '',
      focus: '',
      name: '',
      number: '',
    };
  }

  
  handleInputFocus = (e) => {
    this.setState({ focus: e.target.name });
  }
  
  // On Input Change Value Changes
  handleInputChange = (e) => {
    const { name, value } = e.target;  
    this.setState({ [name]: value });
  }

  render() {
    const Item = (props) => (
      <div className="item-container">
        <div className="item-image">
          <img src={props.img}/>
          <div className="item-details">
            <h3 className="item-name"> {props.name} </h3>
            <h2 className="item-price"> {props.price} </h2>
          </div>
        </div>
      </div>
    );
    const Input = (props) => (
      <div className="input">
        <label>{props.label}</label>
        <div className="input-field">
          <input type={props.type} name={props.name} />
          <img src={props.imgSrc}/>
        </div>
      </div>
    );
    const Button = (props) => (
      <button className="checkout-btn" type="button">{props.text}</button>
    );

    // Check User Login and User Type
    if (sessionStorage.getItem('userData') >= 0 && sessionStorage.getItem('usertype') == 2  ){
    }else {
      return (<Redirect to={'/'}/>)
    }
    return (
      <div className="aboutclass">
        <div className="app-container" id="PaymentForm">
          <div className="row">
            <div className="col cardspay">
              <Cards
                cvc={this.state.cvc}
                expiry={this.state.expiry}
                focused={this.state.focus}
                name={this.state.name}
                number={this.state.number}
              />
            </div>
            <div className="col no-gutters">
              <form>
                <div className="checkout">
                  <div className="checkout-container">
                    <h3 className="heading-3">Credit card checkout</h3>
                    <div className="form-group">
                      <label>Cardholder's Name </label>
                      <input label="Cardholder's Name" type="text" name="name" className="form-control"  onChange={this.handleInputChange}
                      onFocus={this.handleInputFocus} />
                    </div>
                    <div className="form-group">
                      <label>Card Number </label>
                      <input label="Card Number" type="number" name="number" className="form-control" imgSrc="https://seeklogo.com/images/V/visa-logo-6F4057663D-seeklogo.com.png"   onChange={this.handleInputChange}
                      onFocus={this.handleInputFocus} />
                    </div>
                    <div className="form-group">
                      <label>Expiration Date </label>
                      <input label="Expiration Date" type="month" name="expiry" className="form-control"   onChange={this.handleInputChange}
                      onFocus={this.handleInputFocus} />
                    </div>
                    <div className="form-group">
                      <label>CVV </label>
                      <input label="CVV" type="number" name="cvc"  className="form-control"    onChange={this.handleInputChange}
                      onFocus={this.handleInputFocus} />
                    </div>    
                    <button className="checkout-btn" type="button">Place Order</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>    
      </div>
      
    );
  }
}