import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Redirect} from 'react-router-dom';
import {  MDBDataTable  } from 'mdbreact';
import Axios from 'axios';
   
class Customerbookservice extends Component { 
  constructor(props) {
    super(props);
    this.state= {
      posts: [],
      isLoading:true,
      tableRows: [],
    };
  }
  
  logout  = () => {
    sessionStorage.clear();
    window.location.reload(false);
    this.props.history.push('/');
  }

  //Display all Pharmacy Providers List Who are Active
  componentWillMount=async() => {
    if(sessionStorage.length == 0){
    }else{
    // await Axios.get(url)
     Axios.post('https://react.vitruvio.ca/phpreact/Pharmacypatient.php',{
        id:sessionStorage.getItem('userData'),
        accesstoken:sessionStorage.getItem('accesstoken'),
      })
      
      .then(response => response.data)
      .then(data => {
        console.log(data.data);
        
        if (data.success == 2) 
          {
            alert(data.data);
            this.logout();
          }
         // alert(data.data);
         // if (err) throw err;
         this.setState({ posts: data.data })

      })
      .then(async() => {
         this.setState({ tableRows:this.assemblePosts(), isLoading:false })
         // console.log(this.state.tableRows);
      });
    }
  }

  //Pass Data to ComponentWillMount
  assemblePosts= () => {
    let posts =this.state.posts.map((post) => {
      return (
        {
          Pharmacyid:post.pid,
           phname: post.name,
           address: post.address,
           city: post.city,
          pin: post.postcode,
          freelimit: post.freelimit,
          Order:(<a href={'/Providerview/'+ post.pid} defaultvalue={post.pid}><button className="btn" id="btncolor1" style={{backgroundColor:'#4d8a7c'}}>Upload Prescription</button> </a>),
        }
      )
    });
    return posts;
  }

  //Render DataTable
  render() {
    const data = {
      columns: [
        {
          label:'Id',
          field:'Pharmacyid',
        },
        {
          label: 'Pharmacy Name',
          field: 'phname',
        },
        {
          label: 'Address',
          field: 'address',
        },
        {
          label: 'City',
          field: 'city',
        }, 
        {
          label: 'Pincode',
          field: 'pin',
        },
        {
          label: 'Free Limit',
          field: 'freelimit',
        }, 
        {
          label: 'Order',
          field: 'Order',
        },
      ],
      rows:this.state.tableRows,
    }

    //Check if User is Logged in eles redirect out
    if (sessionStorage.getItem('userData') >= 0 && sessionStorage.getItem('usertype') == 1  ){
    
    }else {
      return (<Redirect to={'/'}/>)
    }

    return (
      <div className="container-fluid appclass conspadd" style={{overflow:"hidden"}}>
        <h1>Pharmacy List </h1>
        <MDBDataTable responsiveSm striped bordered hover data={data} />
      </div>
    )
  }
}
export default Customerbookservice;