import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Style.css';
import { Helmet } from 'react-helmet';

class Contact extends Component {
  //Render Data
  render() {
    return (
      <div id="segmentstyle">
        <Helmet>
          <title>Vitruvio - Contact Us</title>
        </Helmet>
        <section className="container-fluid" style={{backgroundColor: "#0e4958"}}>
          <div class="row text-center bg-overlay5 signup1">
            <div class=" col-xs-1 col-md-1"></div>
            <div class="cont4 col-xs-4 col-md-4">
              <h1 id="h1style1">CONTACT US !</h1>
              <p id="cont3">
                Let us help you make real tangible change in your community
               </p>
              <p id="cont3">
                <i className="fa fa-envelope" aria-hidden="true"></i>  clinton@vitruvio.ca  </p>
            </div>
            <div className="col-xs-1 col-md-1"></div>
            <div className="col-xs-5 col-md-5">
              <form className="subform1">
                <div className="form-group " id="colorfield">
                  <input
                    type="text"
                    className="form-control subfom_input"
                    placeholder="Name"
                  />
                </div>
                <br />
                <div className="form-group" id="colorfield">
                  <input
                    type="email"
                    className="form-control subfom_input"
                    placeholder="Email"
                  />
                </div>
                <br />
                <div className="form-group" id="colorfield">
                  <textarea className="form-control subfom_input" name="form_fields[message]" id="form-field-message" rows="4" placeholder="Message" spellCheck="false"></textarea>
                </div>
                <br />
                <button
                  type="submit"
                  className="btn btn-block"
                  id="b5"
                >
                  Send
                </button>
              </form>
            </div>
          </div>
        </section>

        <section>
          <div className="row" id="divrow2">
            <div className="divrow3 col-xs-7 col-md-7">
              <h2 id="h2chat">Do You Believe Healthcare
              Services Should Be More Accessible?</h2>
              <h3 id="chath3" >
                Sign up below and spread the word.
              </h3>
              <div className="tabclass">
                <a href="#" className="btn fa fa-facebook" id="cb">&nbsp;&nbsp;Facebook</a>&nbsp;&nbsp;&nbsp;
                <a href="#" className="btn fa fa-twitter" id="cb">&nbsp;&nbsp;&nbsp;Twitter</a>&nbsp;&nbsp;&nbsp;
                <a href="#" className="btn fa fa-linkedin" id="cb">&nbsp;&nbsp;Linkedin</a>
              </div>
            </div>
            <div className="col-xs-1 col-md-1"></div>
            <div className="col-xs-4 col-md-4" id="chat">
              <img
                src={require('../images/chat.png')}
                width="800"
                height="639"
                sizes="(max-width:800px) 100vw,800px"
                className="img-fluid"
                alt="React Bootstrap logo"
              />
            </div>
          </div>
        </section>
      </div>
    );
  }
}
export default Contact;