import React ,{Component} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Axios from 'axios';
import {AppContext} from './Context';
import  { Redirect } from 'react-router-dom'
import { Helmet } from 'react-helmet';
class Pharmacyredirect extends Component{
  
  // Redirect to Provider Login Page
  redirectProvider(event){
    event.preventDefault();
    window.location.reload(false);
    this.props.history.push("providers");
  }
  render(){
    if (sessionStorage.getItem('userData') >= 0 && sessionStorage.getItem('usertype') == 2  ){
      return (<Redirect to={'/Providerorders'}/>)
    }else {}
    return (
      <div id="segmentstyle">
        <div className="container-fluid aboutclass">
          <div className="row">
            <div className="col-xs-2 col-md-2"></div>
            <div className="col-xs-8 col-md-8 text-center"> 
              <h2 id="learnh2">Thank you for joining Vitruvio and being part of the movement to bring social responsibility to healthcare.</h2>
            </div>
          </div>
          <div class="row learndiv">
            <div className="col-xs-2 col-md-2"></div>
            <div className="col-xs-8 col-md-8 text-center">
              <p id="p5">Please follow the steps below to complete your profile and be part of the solution of affordable healthcare.</p>
              <p id="p5"><span style={{fontWeight:"700"}}>Step 1:</span> Complete your profile in the Profile Section. It is important that your contact information and address are correct as Vitruvio will use this to contact you with any additional steps and for transparency between the customers.</p>
              <p id="p5"><span style={{fontWeight:"700"}}>Step 2:</span> With your address, Vitruvio will mail you a registration package which you will need to complete your profile. Inside the package will be a registration code. You will then need to enter this code in the Subscriptions tab, in the Basic Profile Plan, to fully register your profile. Without this code and a subscription plan activated, you will be <strong>unable to take any prescription orders from customers</strong>. </p>
              <p id="p5"><span style={{fontWeight:"700"}}>Step 3:</span> Complete your Team! In the Team Tab, add your pharmacy team members. Your team will be displayed to any customers viewing your Pharmacy profile, and allows for transparency, creating a more recognizable image and connection between your pharmacy and the customer.</p>
              <a href="/Providers" class="btn" id="btncolor">Proceed to Login</a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Pharmacyredirect;