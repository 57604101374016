import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Style.css';
import { Helmet } from 'react-helmet';
const Contact = () => {
    return (
      <div  id="segmentstyle">
        <section className="container-fluid bg-overlay1">
          <div class="row text-center">
            <h1 id="h1style2">LEARN MORE</h1>
          </div>
        </section>
        <div class="container-fluid aboutclass">
          <Helmet>
            <title>Vitruvio - Learn More</title>
          </Helmet>
          <div className="row">
            <div className="col-xs-12 col-md-12"> 
              <center><h2 id="learnh2">Vitruvio Is The Solution To The Healthcare Affordability Crisis</h2></center>
            </div>
          </div>
          <div className="row learndiv">
            <div className="col-xs-2 col-md-2"></div>
            <div className="col-xs-8 col-md-8">
              <p id="p5">The inequality gap is growing and people without insurance are suffering because they can’t afford their medication. They have to choose between their health and paying their rent, food, utilities. It’s a struggle I see all too often in my family practice.</p>
              <p id="p5">It’s easy to blame the costs on brand name drugs (e.g. Advil) but even generics (e.g. Ibuprofen) are unaffordable for some people. For some, even a few bucks will break their bank.</p>
              <p id="p5">It’s not just that medication has become more expensive,<i><strong> everything has become expensive!</strong></i></p>
            </div>
          </div>
          
          <section className="container-fluid bg-overlay2">
            <div className="row text-center">
              <div className="col-md-6" id="h1style3">
                <h3>The fact is that many honest, and hard working Canadians don’t have medical benefits. Vitruvio allows people with benefits to help those without at no cost.</h3>
              </div>
            </div>
          </section>

          <div className="row learndiv">
            <div className="col-xs-2 col-md-2"></div>
            <div className="col-xs-8 col-md-8">
              <p id="p5">As the inequality rises, so do the corporate giant pharmacies that push out the small independent pharmacies. Even when the government slashes what it will pay for drugs, it disproportionately hurts the community pharmacies.</p>
              <p id="p5">These small pharmacies have historically been the ones that knew the community, knew who was in need and gave them their medications for free or at cost price. They were the ones who took pride in serving their neighbours and friends and brought esteem to their profession.</p>
              <p id="p5">Corporate pharmacies are more focused on scripts per hour (source: CBC Marketplace), which compromises patient care. They don’t give medications away for free or at cost price as it hurts their bottom line. I have had patients who were turned away from such pharmacies simply because they were paying for the medications themselves and didn’t have an insurance plan.</p>
              <p id="p5">Many pharmacies, both corporate and independent, are starting to game the system to “maximize” benefit plans at the cost of the healthcare system and patient care. Patients are sent back to their family doctor to get a prescription for something available over the counter under the guise that “It will be covered for you” but in reality, the pharmacy makes more money from billing the insurance plan. Meanwhile the healthcare system has to pay for the cost of the additional visit, but more importantly, our time is taken away from helping other patients. Adding insult to injury, the pharmacy even sends the patient to the walk-in clinic attached to them – which they own!</p>
              <p id="p5">Vitruvio is a platform that allows communities to reward pharmacies for their commitment to their patients and makes healthcare more affordable for people in need.</p>
            </div>
          </div>

          <section>
            <div className="row" id="divrow2">
              <div className="divrow3 col-xs-7 col-md-7">
                <h2 id="h2chat">Do You Believe Healthcare
                Services Should Be More Accessible?</h2>
                <h3 id="chath3" >
                Sign up below and spread the word.
                </h3>
                <div className="tabclass">
                  <a href="#" className="btn fa fa-facebook" id="cb">&nbsp;&nbsp;Facebook</a>&nbsp;&nbsp;&nbsp;
                  <a href="#" className="btn fa fa-twitter" id="cb">&nbsp;&nbsp;&nbsp;Twitter</a>&nbsp;&nbsp;&nbsp;
                  <a href="#" className="btn fa fa-linkedin" id="cb">&nbsp;&nbsp;Linkedin</a>
                </div>
              </div>
              <div className="col-xs-1 col-md-1"></div>
              <div className="col-xs-4 col-md-4" id="chat">
                <img
                src={require('../images/chat.png')}
                width="800"
                height="639"
                sizes="(max-width:800px) 100vw,800px"
                className="img-fluid"
                alt="React Bootstrap logo"
                />
              </div>
            </div>
          </section>
      
          <div className="row signup">
            <div className="col-md-7">
              <h1 id="h1style1">SIGN UP NOW!</h1>
              <p id="parastyle5">
                Join our mailing list. Show healthcare providers how committed we
                are to making services accessible to people who cannot afford it and
                encourage them to join us.
              </p>
              <p id="parastyle6">
                *Remember to click on the verification link in your email after you
                sign up. (Check your “junk/spam” folder).
              </p>
            </div>
            <div className="col-md-5">
              <form className="subform">
                <div className="form-group" id="colorfield">
                  <label>Email</label>
                  <input
                    type="email"
                    className="form-control subfom_input"
                    placeholder="Please enter your email address"
                  />
                </div>
                <br />
                <div className="form-group " id="colorfield">
                  <label>Name</label>
                  <input
                    type="password"
                    className="form-control subfom_input"
                    placeholder="Please enter your first name"
                  />
                </div>
                <div id="textcolor">
                  {" "}
                  We Take your Privacy Seriously{" "}
                  <span id="spanstyle">
                    <strong>Terms of Use</strong>
                  </span>{" "}
                  and <span id="spanstyle">Privacy Policy.</span>{" "}
                </div>
                <center>  
                  <button
                  type="submit"
                  className="btn btn-lg"
                  id="b5"
                  >
                  Send
                  </button>
                </center>
                &nbsp;
              </form>
            </div>
          </div>
        </div>
      </div>
    );
}
export default Contact;