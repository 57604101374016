import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
const Footer = () => {
    return(
        <div id="divalign">
            <footer> 
                <div className="row">
                    <div className="col-md-6"> <a href="#" id="footcolor"><b>&copy; COPYRIGHT 2020 VITRUVIO. ALL RIGHTS RESERVED.</b></a> </div>
                    <div className="col-md-6 " style={{textAlign:"right",paddingRight:"150px"}}>
                    <i class="fa fa-facebook-square fa-2x" id="iconcolor" aria-hidden="true"></i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <i class="fa fa-linkedin-square fa-2x" id="iconcolor" aria-hidden="true"></i>
                    </div>
                </div>
            </footer>
        </div>
    );
}
export default Footer;