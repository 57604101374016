import React ,{Component} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Rating from './HoverRating';
import Axios from 'axios';
import { Helmet } from 'react-helmet';

class Feedback extends Component{
  
  // Feedback Submitting Event Handler
  feedbacksubmit=(event)=>{
    event.preventDefault();
    event.persist();
    alert("Thank you for your valuable feedback");
    Axios.post('https://react.vitruvio.ca/phpreact/feedback.php',{
      Message1:this.Message1.value,
      Message2:this.Message2.value,
    })
    .then(function ({data}) {
      if(data.success == 1){
        event.target.reset();
        sessionStorage.setItem('CustomerNumber',data.users);
        this.props.history.push("Otpverifycust");
      }else{
        alert(data.msg);
      }
    }.bind(this))
    .catch(function (error) {
       // console.log(error);
    });
   }

  //Render Component
  render(){
   return (
    <div class="container-fluid aboutclass">
      <Helmet>
        <title>Vitruvio - Order Feedback</title>
      </Helmet>
      <form class="formPatient" onSubmit={this.feedbacksubmit} >
        <h2 class="formh2">Order Feedback</h2>
        <div style={{textAlign:'left'}}>
          <label> How satisfied are you with the Order? *</label>
            <textarea class="form-control" rows="5" id="note" placeholder="" ref={(val)=>this.Message1=val} required ></textarea>
        </div>
        <div style={{textAlign:'left'}}>
          <label>  What would make you more satisfied with the Order? *</label>
          <textarea class="form-control" rows="5" id="note" placeholder="" ref={(val)=>this.Message1=val}  required ></textarea>
        </div>
        <h3 style={{textAlign:'left'}} > Rate us <Rating  /></h3>
        <button type="submit" className="btn btn-success">Submit</button>&nbsp;
      </form>
    </div>   
    );
  }
}
export default Feedback;