import React ,{Component} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Axios from 'axios';
import {AppContext} from './Context';
import  { Redirect } from 'react-router-dom'

import { Helmet } from 'react-helmet';
  class Adminlogin extends Component{
    static contextType=AppContext;
    // Login Validation
    validateProvider=(event)=>{
       event.preventDefault();
       event.persist();
       Axios.post('https://react.vitruvio.ca/phpreact/adminlogin.php',{
           email:this.Email.value,
           Password:this.Password.value,
       })
       .then(function ({data}) {
          if(data.success == 1){
            event.target.reset();
            alert(data.msg);
            sessionStorage.setItem('userData',data.id);
            // sessionStorage.setItem('userData',data.email);
            sessionStorage.setItem('usertype',data.type);
            sessionStorage.setItem('accesstoken',data.accesstoken);
            window.location.reload(false);
            this.props.history.push("Adminview");
          }
          else{
            alert(data.msg);
          }
       }.bind(this))
       .catch(function (error) {
       // console.log(error);
       });
    }

    //Render Components
    render(){
      //Check if User is Logged in eles redirect out
      if (sessionStorage.getItem('userData') >= 0 && sessionStorage.getItem('usertype') == 3  ){
        return (<Redirect to={'/Adminview'}/>)
      }else {}
      
      return (
        <div class="container-fluid aboutclass">
          <Helmet>
            <title>Vitruvio - Admin Login</title>
          </Helmet>
          <form class="formPatient" onSubmit={this.validateProvider}>
            <h2 class="formh2">Login into Your Admin Account</h2>
            <div class="forminput">
              <input type="email" className="form-control" placeholder="Username or Email Address"  ref={(val)=>this.Email=val}  />
            </div>
            <div class="forminput">
                <input type="password" className="form-control" placeholder="Password"  ref={(val)=>this.Password=val} />
            </div>
            <button type="submit" className="btn btn-success">Login</button>&nbsp;
          </form>
        </div>
      );
    }
  }
export default Adminlogin;