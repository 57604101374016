import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Redirect} from 'react-router-dom';
 import Axios from 'axios';
import {AppContext} from './Context';
class Patientsprofile extends Component {
   state = {
    posts: [],
    isLoading: true,
    errors: null
  };
  static contextType=AppContext;
  
  
  logout  = () => {
    sessionStorage.clear();
    window.location.reload(false);
    this.props.history.push('/');
  }

  // Update Customer User Profile
  updatetUser=(event)=>{
    event.preventDefault();
    event.persist();
    Axios.post('https://react.vitruvio.ca/phpreact/update-userprofile.php',{
      name:this.Name.value,
      email:this.Email.value,
      address:this.Address.value,
      city:this.City.value,
      state:this.State.value,
      postcode:this.Postcode.value,
      phone:this.Phone.value,
      doctor:this.Doctor.value,
      Pickupname:this.Pickupname.value,
      id:sessionStorage.getItem('userData'),
      accesstoken:sessionStorage.getItem('accesstoken'),
    })
    .then(function ({data}) {
      if (data.success == 3) 
      {
        alert(data.data);
        this.logout();
      }
      else if(data.success == 1){
        event.target.reset();
        alert(data.msg);
        this.props.history.push("patients");
      }
      else{
          alert(data.msg);
      }
    }.bind(this))
    .catch(function (error) {
      // console.log(error);
    });
  }

  // Delete Account from Customer
  handleDelete = (id) => {
    if (window.confirm("Are you Sure  you wish to Unsubscribe this will logout your session and you will not able to login ?")){
    
    Axios.post('https://react.vitruvio.ca/phpreact/prof-delete.php',{
      id:id,
      accesstoken:sessionStorage.getItem('accesstoken'),
    })
    .then(({data}) => {
      if (data.success == 0) 
      {
        alert(data.data);
        this.logout();
      }
      else if(data.success === 1){
        alert(data.msg);
        sessionStorage.clear();
        window.location.reload(false);
        this.props.history.push('/');       
      }else{
        alert(data.msg);
      }
    })
    .catch(error => {
      console.log(error);
    });
    }else{
      // return false;
    }
  }

  // Show User Account Data
  getPosts() {
    if(sessionStorage.length == 0){
    }else{
    Axios.post('https://react.vitruvio.ca/phpreact/all-users.php',{
      id:sessionStorage.getItem('userData'),
      accesstoken:sessionStorage.getItem('accesstoken'),
    })
    .then(response => {
      console.log(response);
      if(response.data.success == 3){
        alert(response.data.data);
        this.logout();
      }
      this.setState({
        posts: response.data.users,
        isLoading: false
      });
    })   
    .then(data => {
      if (data.success == 3) 
      {
        alert(data.data);
        this.logout();
      }
      // else if(data.success == 1){
      //   alert(data.data);
      // }
    })
    .catch(error => this.setState({ error, isLoading: false }));
    }
  }

  // Let's our app know we're ready to render the data
  componentDidMount() {
    this.getPosts();
  }
  // Putting that data to use
  render() {
    // Check User is logged in and User Type
    if (sessionStorage.getItem('userData') >= 0 && sessionStorage.getItem('usertype') == 1  ){
    
    }else {
      return (<Redirect to={'/'}/>)
    }
    const { isLoading, posts } = this.state;
    return (
      <React.Fragment>
        <div className="container-fluid cardclass conspadd">
          {!isLoading ? (
            posts.map(post => {
              // const { _id, title, content } = post;
              const { cid, name, email,state,address,city,postcode,phone,doctor,proxy,Pickupname } = post;   
              return (
                <div style={{paddingTop:"50px"}}>
                    <form class="formPatient" onSubmit={this.updatetUser} >
                      <h1 id="custh1">Patient Profile</h1>
                      <div className="form-group" style={{textAlign:"left"}} >
                        <label>Name</label>
                        <input type="text" className="form-control " id= "name1" defaultValue={name} placeholder="Enter Name"  ref={(val)=>this.Name=val}/>
                      </div>
                      <div className="form-group" style={{textAlign:"left"}} >
                          <label>Email</label>
                          <input type="email" className="form-control " id= "email1" defaultValue={email} placeholder="Enter Email ID" ref={(val)=>this.Email=val} />
                      </div>
                      <div className="form-group" style={{textAlign:"left"}} >
                        <label>Address</label>
                        <input type="text" className="form-control " placeholder="Enter Address" defaultValue={address} ref={(val)=>this.Address=val} />
                      </div>
                      <div className="form-group" style={{textAlign:"left"}} >
                        <label>City</label>
                        <input type="text" className="form-control " placeholder="Enter City"  defaultValue={city}  ref={(val)=>this.City=val} />
                      </div>
                      <div className="form-group" style={{textAlign:"left"}} >
                        <label>Province</label>
                        <input type="text" className="form-control " placeholder="Enter Province"  defaultValue={state}  ref={(val)=>this.State=val} />
                      </div>
                      <div className="form-group" style={{textAlign:"left"}} >
                        <label>Postcode</label>
                        <input type="text" className="form-control " placeholder="Enter Postcode" defaultValue={postcode} ref={(val)=>this.Postcode=val} />
                      </div>
                      <div className="form-group" style={{textAlign:"left"}} >
                        <label>Phone Number</label>
                        <input type="number" className="form-control " readOnly  placeholder="Enter Phone Number" defaultValue={phone}  ref={(val)=>this.Phone=val} />
                      </div>
                      <div className="form-group" style={{textAlign:"left"}} >
                        <label>Family Doctor (if applicable) </label>
                        <input type="text" className="form-control " placeholder="Enter Family Doctor's Name" defaultValue={doctor} ref={(val)=>this.Doctor=val} />
                      </div>
                      <div className="form-group" style={{textAlign:"left"}} >
                        <label>Who can pick up your medications for you</label>
                        <input type="text" className="form-control " placeholder="Enter Name" defaultValue={Pickupname} ref={(val)=>this.Pickupname=val} />
                      </div>
                      &nbsp;&nbsp;  
                      <button type="submit" className="btn float-left" id="btncolor">Update</button>&nbsp;    
                    </form>
                       <button type="submit"  className="btn btn-danger float-right" style={{marginTop:"-4%"}}   onClick={() => this.handleDelete(sessionStorage.getItem('userData'))} >Delete your Profile</button>
                  </div>
                );
            })
          ):(
            <p>Loading...</p> 
          )}
        </div>
      </React.Fragment>
    );
  }
}
export default Patientsprofile;