import React ,{Component} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Axios from 'axios';
import {AppContext} from './Context';
import  { Redirect } from 'react-router-dom'
class Register extends Component{
    static contextType=AppContext;

    // Provider Addition Fucntion
    insertUser=(event)=>{
        event.preventDefault();
        event.persist();
        Axios.post('https://react.vitruvio.ca/phpreact/add-provider.php',{
       //Axios.post('https://react.vitruvio.ca/twilio/verify.php',{
           name:this.Name.value,
           pname:this.pname.value,
           phone:this.Phone.value,
           email:this.Email.value,
           Password:this.Password.value,
           Users:this.Users.value,
           Authentication:this.Authentication.value
        })
        .then(function ({data}) {
            if(data.success == 1){
                event.target.reset();
                sessionStorage.setItem('ProviderNumber',data.users);
                this.props.history.push("Pharmacyredirect");
            }
           else{
            alert(data.msg);
            // console.log(data.msg);
            // console.log(data.msg);
           }
        }.bind(this))
        .catch(function (error) {
            // console.log(error);
        });
    }
    render(){
        if(sessionStorage.getItem('userData') >= 0 && sessionStorage.getItem('usertype') == 2 ){
            return (<Redirect to={'/Providerorders"'}/>)
        }else {}
        return (
            <div class="container-fluid aboutclass">
                <form class="formPatient" onSubmit={this.insertUser}>
                    <h3 class="formh2"> Provider Register</h3>
                    <div className="row">
                        <div className="col-md-3"></div>
                        <div className="form-group col-md-6 " style={{textAlign:"left"}}>
                            <label>Pharmacy Name</label><span class="required">*</span>
                            <input type="text" className="form-control " placeholder="Enter Name" ref={(val)=>this.Name=val} required />
                        </div>  
                    </div>
                    <div className="row">
                        <div className="col-md-3"></div>
                        <div className="form-group col-md-6" style={{textAlign:"left"}}>
                            <label>Parent Name</label>
                            <input type="text" className="form-control " placeholder="Parent  Name" ref={(val)=>this.pname=val}  />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3"></div>
                        <div className="form-group col-md-6 " style={{textAlign:"left"}}>
                            <label>Phone</label><span class="required">*</span>
                            <input type="number" className="form-control " placeholder="Enter Phone " ref={(val)=>this.Phone=val}  requierd/>
                        </div>  
                    </div>
                    <div className="row">
                        <div className="col-md-3"></div>
                        <div className="form-group col-md-6 " style={{textAlign:"left"}}>
                            <label>Email</label><span class="required">*</span>
                            <input type="email" className="form-control " placeholder="Enter Email ID" ref={(val)=>this.Email=val} requierd/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3"></div>
                        <div className="form-group  col-md-6" style={{textAlign:"left"}}>
                            <label>Password</label><span class="required">*</span>
                            <input type="password" className="form-control " placeholder="Enter password" ref={(val)=>this.Password=val}  required />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3"></div>
                        <div className="form-group col-md-6 " style={{textAlign:"left"}}>
                            <label>Type</label>
                            <select class="form-control" id="sel1" ref={(val)=>this.Users=val}>
                                <option>Pharmacy</option>
                            </select>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3"></div>
                        <div className="form-group col-md-6 " style={{textAlign:"left"}}>
                            <label>Accreditation Number</label><span class="required">*</span>
                            <input type="text" className="form-control " placeholder="Accreditation Number" ref={(val)=>this.Authentication=val} required/>
                        </div>
                    </div>
                    <button type="submit" className="btn" id="btncolor">Create New Account</button>&nbsp;       
                </form>
            </div>
        );
    }    
}
export default Register;