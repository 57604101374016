import {  MDBDataTable ,CardBody,Card} from 'mdbreact';
import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Redirect} from 'react-router-dom';
import queryString from 'query-string';
import Axios from 'axios';

class Freeprespharmacy extends Component {
  constructor(props) {
    super(props);
    this.state= {
      posts: [],
      isLoading:true,
      tableRows: [],
    };
  }
  logout  = () => {
    sessionStorage.clear();
    window.location.reload(false);
    this.props.history.push('/');
  }

  // Function to handle Free Order
  componentWillMount=async() => {
    if(sessionStorage.length == 0){
    }else{
    // await Axios.get(url)
      Axios.post('https://react.vitruvio.ca/phpreact/freepharmacy.php',{
        id:sessionStorage.getItem('userData'),
        accesstoken:sessionStorage.getItem('accesstoken'),
      })
      .then(response => response.data)
      .then(data => {
        console.log(data.data);
        if (data.success == 0) 
        {
          alert(data.data);
          this.logout();
        }
        // alert(data.data);
        // if (err) throw err;
        this.setState({ posts: data.data })
      })

      .then(async() => {
        this.setState({ tableRows:this.assemblePosts(), isLoading:false })
        // console.log(this.state.tableRows);
      });
    }
  }

  
  assemblePosts= () => {
    let posts =this.state.posts.map((post) => {
      return (
        {
          upload: (<img src={post.logo} alt="profile image" className="img-fluid" width="120px" height="70px" style={{marginTop:"25px",marginBottom:"25px"}}/>),
          name: post.name,
          address: post.address,
          phone: post.phone,
          Order:(<a href={'/Providerview/' + post.pid}  defaultvalue={post.pid}><button className="btn" id="btncolor1"  style={{backgroundColor:'#4d8a7c'}}>Upload Prescription</button> </a>),
        }
      )
    });
    return posts;
  }


  render() {
    const data = {
      columns: [
        {
          label: 'Image',
          field: 'upload',
        },
        {
          label: 'Provider Name',
          field: 'name',
        }, 
        {
          label: 'Address',
          field: 'address',
        },
        {
          label: 'Phone',
          field: 'phone',
        },  
        {
          label: 'Order',
          field: 'Order',
        },
      ],
      rows:this.state.tableRows,
    }

    return(
      <div class="container-fluid aboutclass conspadd">
        <h2> List of all the Pharmacies that are offering free prescriptions</h2>
        <MDBDataTable responsiveSm striped bordered hover data={data}/>     
      </div>
    );
  }

}
export default Freeprespharmacy;